<div>
  <div class="text-center">
    <img [src]="checkCircle">
  </div>
  <div class="widget-info-block">
    <div id="widget-restored-username" class="text-center widget-user-name">{{user?.name}}</div>
    <div localization-key="widget-login-message" id="widget-restored-description" class="text-center widget-description">{{isPhone? descriptionPhone: descriptionEmail}}</div>
  </div>
  <div id="widget-restored-data" class="text-center widget-email">{{isPhone? user?.phone : user?.email}}</div>
  <div class="widget-logout-block d-flex align-items-center justify-content-center">
    <div localization-key="widget-success-login" id="widget-restore-success-login" class="widget-strong-description">Приятных покупок</div>
    <button class="widget-btn-with-icon" (click)="logout()"><img class="widget-btn-icon" [src]="logoutIcon"></button>
  </div>
  <div localization-key="widget-login-link" id="widget-success-restore-info" class="text-center widget-secondary-description">
    <span>Подробная информация на сайте </span>
    <a href="https://digitaxfree.ru" target="_blank" class="widget-bold-link-10">TaxFree</a>
  </div>
</div>
