import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TaxFreeChequeCustomer } from '../models/TaxFreeChequeCustomer/TaxFreeChequeCustomer';
import { PaymentDetails } from '../models/PaymentDetails/PaymentDetails';
import { environment } from '@app/env';
import { AuthService } from '@core/services/auth.service';
/**
 * Сервис для распознавания данных с карт и паспорта
 */
@Injectable()
export class ScanningService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  /**
   * URL аккаунтинг сервиса
   */
  private baseUrl = environment.baseAccountingApiUrl;

  constructor(private authService: AuthService, private http: HttpClient) {}

  /**
   * Закрытие сессии сканирования
   *
   * @returns Наблюдателя за закрытием сессии
   */
  resetSession(): Observable<any> {
    return of();
  }

  /**
   * Распознавание данных паспорта с изображения
   *
   * @param base64 изображение в формате Base64
   * @returns Наблюдателя за распознаванием данных паспорта
   */
  public scanPassport(base64): Observable<TaxFreeChequeCustomer> {
    return this.http
      .post(this.baseUrl + '/scanner/passport', base64, this.httpOptions)
      .pipe(
        map((data: TaxFreeChequeCustomer) => {
          const model = new TaxFreeChequeCustomer();
          Object.assign(model, data);
          return data;
        }),
        catchError(err => {
          console.error('Error get passport data ' + err.message);
          return throwError(err);
        })
      );
  }

  /**
   * Распознавание данных карты с изображения
   *
   * @param base64 изображение в формате Base64
   * @returns Наблюдателя за распознаванием данных карты
   */
  public scanCard(base64): Observable<PaymentDetails> {
    return this.http
      .post(this.baseUrl + '/scanner/card', base64, this.httpOptions)
      .pipe(
        map((data: PaymentDetails) => {
          const model = new PaymentDetails();
          Object.assign(model, data);
          return model;
        }),
        catchError(err => {
          console.error('Error get card data ' + err.message);
          return throwError(err);
        })
      );
  }
}
