import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { INTERCEPTOR_SKIP_HEADER } from '../headers';

/**
 * Сервис Капчи
 */
@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  /**
   * Http Опции
   */
  httpOptions = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.getCaptchaHeadersByAuthState(
      this.authService.isLoggedIn()
    );
  }

  /**
   * Получение капчи
   *
   * @returns Наблюдателя за получением капчи
   */
  public getCaptcha(): Observable<any> {
    const salt = new Date().getTime();

    return this.http.get(`${this.getCaptchaPath()}?${salt}`, {
      responseType: 'blob',
      withCredentials: true
    });
  }

  /**
   * Генерация опций для запроса капчи с авторизацией, или без
   *
   * @param isLoggedIn признак залогинненного пользователя
   * @returns опции для запроса
   */
  private getCaptchaHeadersByAuthState(isLoggedIn: boolean = false) {
    const options = {
      withCredentials: true,
      responseType: 'blob',
      headers: INTERCEPTOR_SKIP_HEADER
    };
    if (isLoggedIn) {
      options.headers.append('Content-Type', 'application/json');
      options.headers.append(
        'Authorization',
        'Bearer ' + this.authService.getAccessToken()
      );
    }
    return options;
  }

  /**
   * Получение URL для запроса капчи
   *
   * @returns URL для запроса капчи
   */
  private getCaptchaPath(): string {
    return environment.basePortalApiUrl + api.shoppers.captcha;
  }
}
