/* eslint-disable id-denylist */
import { Directive } from '@angular/core';
import { AbstractPhoneMaskComponent } from '@app/core/classes';
import { ShopData } from '@app/core/models/ShopData';
import { isPhone } from 'src/app/config/validators/emailPhoneValidator';
import * as moment from 'moment';
import { validationOfSum } from '@app/core/utils';
import { ShopProduct } from '@app/core/models/ShopProduct';
declare global {
  interface Window {
    getShopData: () => void;
  }
}
/**
 * Абстрактный класс виджета
 */
@Directive()
export abstract class AbstractWidgetComponent extends AbstractPhoneMaskComponent {
  /**
   * Данные магазина
   *
   * @type {ShopData}
   */
  shopData: ShopData = new ShopData();

  constructor() {
    super();
  }

  /**
   * Проверка наличия данных
   *
   * @returns Признак наличия данных
   */
  hasData(): boolean {
    const dataFieldsId = [
      'data-taxfree-order-number-id',
      'data-taxfree-customer-name',
      'data-taxfree-customer-surname',
      'data-taxfree-customer-birthday',
      'data-taxfree-customer-email',
      'data-taxfree-customer-phonenumber',
      'data-taxfree-payment-type',
      'data-taxfree-order-sum',
      'data-taxfree-order-quantity',
      'data-taxfree-delivery-address-full',
      'data-taxfree-delivery-address-CountryName',
      'data-taxfree-delivery-address-Region',
      'data-taxfree-delivery-address-CityName',
      'data-taxfree-delivery-address-Street',
      'data-taxfree-delivery-address-House',
      'data-taxfree-delivery-address-office',
      'data-taxfree-delivery-address-apartment',
      'data-taxfree-order-product-id',
      'data-taxfree-order-product-id-name',
      'data-taxfree-order-product-id-value',
      'data-taxfree-order-product-id-description'
    ];
    let foundData = false;
    for (let i = 0; i < dataFieldsId.length; i++) {
      const iframe = document.querySelector('#iframe') as HTMLIFrameElement;
      const elements = iframe
        ? iframe.contentDocument.querySelectorAll(`[${dataFieldsId[i]}]`)
        : document.querySelectorAll(`[${dataFieldsId[i]}]`);
      if (elements && elements.length > 0) {
        i = dataFieldsId.length;
        foundData = true;
      }
    }
    return foundData;
  }

  /**
   * Сбор данных
   */
  getData() {
    const foundData = this.hasData();
    console.log('found templated data:', foundData);
    if (foundData) {
      this.getDataFromAttributes();
    } else {
      if (window.getShopData) {
        this.getDataFromCustomFunction();
      }
    }
  }
  /**
   * Сбор данных из атрибутов
   */
  getDataFromAttributes() {
    this.getOrderNumber();
    this.getPhoneNumber();
    this.getCustomerName();
    this.getCustomerSurname();
    this.getCustomerBirthday();
    this.getCustomerEmail();
    this.getPaymentType();
    this.getOrderSum();
    this.getOrderQuantity();
    this.getProducts();
    this.getFullDeliveryAddress();
    this.getDelieveryAddressByParts();
  }
  /**
   * Сбор данных из функции
   */
  getDataFromCustomFunction() {
    const data: any = window.getShopData();
    if (data.order) {
      this.shopData.order.commodities = data.order.commodities || [];
      this.shopData.order.number = data.order.number || '';
      this.shopData.order.sum = data.order.sum || 0;
      this.shopData.order.quantity = data.order.quantity || null;
      if (
        data.order.number === null &&
        data.order.number === undefined &&
        data.order.commodities.length > 0
      ) {
        localStorage.setItem(
          'WIDGET_CART_SUM',
          JSON.stringify({
            commodities: data.order.commodities,
            totalSum: this.shopData.order.sum
          })
        );
      }
    }
    if (data.paymentType) {
      this.shopData.paymentType = data.paymentType || null;
    }
    if (data.deliveryAddress) {
      this.shopData.deliveryAddress.full = data.deliveryAddress.full || '';
      this.shopData.deliveryAddress.country =
        data.deliveryAddress.country || '';
      this.shopData.deliveryAddress.city = data.deliveryAddress.city || '';
      this.shopData.deliveryAddress.street = data.deliveryAddress.street || '';
      this.shopData.deliveryAddress.house = data.deliveryAddress.house || '';
      this.shopData.deliveryAddress.appartment =
        data.deliveryAddress.appartment || '';
      this.shopData.deliveryAddress.office = data.deliveryAddress.office || '';
      this.shopData.deliveryAddress.region = data.deliveryAddress.region || '';
    }
    if (data.customer) {
      this.shopData.customer.birthday = data.customer.birthday || '';
      this.shopData.customer.name = data.customer.name || '';
      this.shopData.customer.surname = data.customer.surname || '';
      this.shopData.customer.birthday = data.customer.birthday || '';
      this.shopData.customer.email = data.customer.email || '';
    }
  }
  /**
   * Получение идентификатора заказа
   */
  getOrderNumber() {
    const orderNumber = document.querySelector(
      '[data-taxfree-order-number-id]'
    );
    if (!orderNumber) {
      return;
    }
    this.shopData.order.number = orderNumber.textContent;
    localStorage.removeItem('WIDGET_CART_REFUND_RUB');
  }
  /**
   * Получение номера телефона
   */
  getPhoneNumber() {
    const phoneNumber = document.querySelector(
      '[data-taxfree-customer-phonenumber]'
    );
    if (!phoneNumber) {
      return;
    }
    if (isPhone(phoneNumber.textContent.replace(/\s/g, ''))) {
      this.shopData.customer.phone = phoneNumber.textContent;
    }
  }

  /**
   * Получение имени покупателя
   */
  getCustomerName() {
    const name = document.querySelector('[data-taxfree-customer-name]');
    if (!name) {
      return;
    }
    this.shopData.customer.name = name.textContent;
  }
  /**
   * Получение фамилия покупателя
   */
  getCustomerSurname() {
    const surname = document.querySelector('[data-taxfree-customer-surname]');
    if (!surname) {
      return;
    }
    this.shopData.customer.surname = surname.textContent;
  }

  /**
   * Получение даты рождения покупателя
   */
  getCustomerBirthday() {
    const birthday = document.querySelector('[data-taxfree-customer-birthday]');
    if (!birthday) {
      return;
    }
    if (birthday.textContent === '') {
      return;
    }
    let format = birthday.getAttribute('data-date-format');
    if (!format) {
      return;
    }
    if (!(format.includes('h') || format.includes('H'))) {
      format = format.toUpperCase();
    }
    this.shopData.customer.birthday = moment(
      birthday.textContent,
      format
    ).format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Получение email покупателя
   */
  getCustomerEmail() {
    const email = document.querySelector('[data-taxfree-customer-email]');
    if (!email) {
      return;
    }
    this.shopData.customer.email = email.textContent;
  }

  /**
   * Получение платежной системы
   */
  getPaymentType() {
    const paymentType = document.querySelector('[data-taxfree-payment-type]');
    if (!paymentType) {
      return;
    }
    this.shopData.paymentType = paymentType.textContent;
  }

  /**
   * Получение суммы заказа
   */
  getOrderSum() {
    const orderSum = document.querySelector('[data-taxfree-order-sum]');
    if (!orderSum) {
      return;
    }
    if (!validationOfSum(orderSum.textContent)) {
      return;
    }
    this.shopData.order.sum = Number(orderSum.textContent.replace(/\s/g, ''));
  }

  /**
   * Получение количества позиций в заказе
   */
  getOrderQuantity() {
    const orderQuantity = document.querySelector(
      '[data-taxfree-order-quantity]'
    );
    if (!orderQuantity) {
      this.shopData.order.quantity = null;
      return;
    }
    if (orderQuantity.textContent === '') {
      this.shopData.order.quantity = null;
      return;
    }
    this.shopData.order.quantity = Number(orderQuantity.textContent);
  }

  /**
   * Получение полного адреса
   */
  getFullDeliveryAddress() {
    const delieveryAdressFull = document.querySelector(
      '[data-taxfree-delivery-address-full]'
    );
    if (!delieveryAdressFull) {
      return;
    }
    this.shopData.deliveryAddress.full = delieveryAdressFull.textContent;
  }

  /**
   * Получение адреса, если разбит на отдельные поля
   */
  getDelieveryAddressByParts() {
    const countryName = document.querySelector(
      '[data-taxfree-delivery-address-CountryName]'
    );
    const region = document.querySelector(
      '[data-taxfree-delivery-address-Region]'
    );
    const cityName = document.querySelector(
      '[data-taxfree-delivery-address-CityName]'
    );
    const street = document.querySelector(
      '[data-taxfree-delivery-address-Street]'
    );
    const house = document.querySelector(
      '[data-taxfree-delivery-address-House]'
    );
    const appartment = document.querySelector(
      '[data-taxfree-delivery-address-apartment]'
    );
    const office = document.querySelector(
      '[data-taxfree-delivery-address-office'
    );
    this.shopData.deliveryAddress.country = countryName
      ? countryName.textContent
      : '';
    this.shopData.deliveryAddress.region = region ? region.textContent : '';
    this.shopData.deliveryAddress.city = cityName ? cityName.textContent : '';
    this.shopData.deliveryAddress.street = street ? street.textContent : '';
    this.shopData.deliveryAddress.house = house ? house.textContent : '';
    this.shopData.deliveryAddress.appartment = appartment
      ? appartment.textContent
      : '';
    this.shopData.deliveryAddress.office = office ? office.textContent : '';
  }

  /**
   * Получение данных о товарах
   */
  getProducts() {
    const iframe = document.querySelector('#iframe') as HTMLIFrameElement;

    const productIds = iframe
      ? iframe.contentDocument.querySelectorAll(
          '[data-taxfree-order-product-id]'
      )
      : document.querySelectorAll('[data-taxfree-order-product-id]');
    const productNames = iframe
      ? iframe.contentDocument.querySelectorAll(
          '[data-taxfree-order-product-id-name]'
      )
      : document.querySelectorAll('[data-taxfree-order-product-id-name]');
    const productDescriptions = iframe
      ? iframe.contentDocument.querySelectorAll(
          '[data-taxfree-order-product-id-description]'
      )
      : document.querySelectorAll(
          '[data-taxfree-order-product-id-description]'
      );
    const productValues = iframe
      ? iframe.contentDocument.querySelectorAll(
          '[data-taxfree-order-product-id-value]'
      )
      : document.querySelectorAll('[data-taxfree-order-product-id-value]');
    const products: ShopProduct[] = [];
    if (productIds && productIds.length > 0) {
      productIds.forEach(id => {
        const product = new ShopProduct();
        product.id = id.getAttribute('data-taxfree-order-product-id');
        products.push(product);
      });
    }

    if (productNames && productNames.length > 0) {
      productNames.forEach((productName, index) => {
        products[index].name = productName.textContent;
      });
    }
    if (productDescriptions && productDescriptions.length > 0) {
      productDescriptions.forEach((productDescription, index) => {
        products[index].description = productDescription.textContent;
      });
    }
    if (productValues && productValues.length > 0) {
      productValues.forEach((productValue, index) => {
        if (!validationOfSum(productValue.textContent)) {
          products[index].price = 0;
        }
        products[index].price = Number(
          productValue.textContent.replace(/\s/g, '')
        );
      });
    }
    return products;
  }
}
