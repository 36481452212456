<div>
  <button *ngIf="!openState && isShownWidget" (click)="changeOpenState()"
    [ngClass]="{'taxfree-widget-button': !isMobile, 'taxfree-widget-button-mobile': isMobile}">
  </button>
  <div *ngIf="openState && isShownWidget"
    [ngClass]="{'taxfree-widget-popup': !isMobile, 'taxfree-widget-popup-mobile': isMobile}">
    <div class="position-relative">
      <button *ngIf="!isMobile" (click)="changeOpenState()" class="taxfree-close-button">
      </button>
      <div [ngClass]="{'taxfree-widget-main-block': !isMobile, 'taxfree-widget-main-mobile-block': isMobile}">
        <button *ngIf="isShownBackButton()" (click)="goBack()" class="widget-btn-with-icon taxfree-widget-back-btn">
          <img [src]="backBtnIcon">
        </button>
        <div class="widget-language-select-wrapper">
          <ng-select [clearable]="false" [searchable]="false" [items]="localizations" placeholder="RU"
            [(ngModel)]="selectedValue" [hideSelected]="true" (change)="changeLanguage()">
          </ng-select>
        </div>

        <button (click)="changeOpenState()" class="taxfree-widget-close-btn"></button>
        <div class="widget-wrapper">
          <div class="text-center" *ngIf="isMobile">
            <img [src]="mobileLogo">
          </div>
          <app-widget-login *ngIf="widgetState === states.INIT_STATE" [textConfig]="textConfig"
            (expressRegistration)="changeStateOfWidget($event)" (restoreClick)="changeStateOfWidget($event)"
            (successLogin)="changeStateOfWidget($event)"></app-widget-login>
          <app-widget-success-login *ngIf="widgetState === states.SUCCESS_LOGIN" [textConfig]="textConfig"
            [isPhone]="isPhone" [user]="user" (logoutEvent)="resetState()"></app-widget-success-login>
          <app-widget-restore-access *ngIf="widgetState === states.RESTORE_ACCESS"
            (continueRestore)="changeStateOfWidget($event)" (restoreError)="changeStateOfWidget($event)"
            [textConfig]="textConfig" [user]="user"></app-widget-restore-access>
          <app-widget-entry-code id="widget-entry-code" *ngIf="widgetState === states.RESTORE_CODE_CHECK"
            [isPhone]="isPhone" [phoneOrEmail]="restoredPhoneOrEmail" [textConfig]="textConfig"
            (restoreSuccess)="changeStateOfWidget($event)"
            (restoreError)="changeStateOfWidget($event)"></app-widget-entry-code>
          <app-widget-success-restore
            *ngIf="widgetState === states.SUCCESS_RESTORE || widgetState === states.SUCCESS_REGISTRATION"
            [textConfig]="textConfig" [isPhone]="isPhone" [user]="user"
            (logoutEvent)="resetState()"></app-widget-success-restore>
          <app-widget-registration *ngIf="widgetState === states.EXPRESS_REGISTRATION" [textConfig]="textConfig"
            (sendCode)="changeStateOfWidget($event)"></app-widget-registration>
          <app-widget-verify-registration *ngIf="widgetState === states.VERIFY_REGISTRATION" [isPhone]="isPhone"
            [textConfig]="textConfig" [phoneOrEmail]="registrationPhoneOrEmail"
            (registrationSuccess)="changeStateOfWidget($event)"></app-widget-verify-registration>
          <app-widget-init-calculator *ngIf="widgetState === states.CART_INIT_STATE" [totalSum]="totalSum"
            [textConfig]="textConfig" [currencyCode]="currencyCode" [comodities]="comodities" [isPhone]="isPhone"
            [user]="user" (expressRegistration)="changeStateOfWidget($event)"
            (restoreClick)="changeStateOfWidget($event)" (successLogin)="changeStateOfWidget($event)"
            (logoutEvent)="resetState()"></app-widget-init-calculator>
          <app-widget-error-message *ngIf="widgetState === states.RESTORE_ERROR" [isTotalBlocked]="isTotalBlocked"
            [seconds]="secondsToUnblock" [textConfig]="textConfig"
            (returnToMainPage)="changeStateOfWidget($event)"></app-widget-error-message>
        </div>
      </div>
    </div>
  </div>
</div>
