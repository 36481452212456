import {
  Component,
  EventEmitter,
  Output,
  LOCALE_ID,
  Inject,
  Input
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerService, TradingNetworkService } from 'src/app/core/services';
import { AbstractComponent } from '@app/core/classes';
import { WidgetConfig } from '@app/core/models/Widget/WidgetConfig';
import { TradingInternetShop } from '@app/core/models/TradingInternetShop';
@Component({
  selector: 'app-delete-shop-modal',
  templateUrl: './delete-shop-modal.component.html',
  styleUrls: ['./delete-shop-modal.component.scss']
})
export class DeleteShopModalComponent extends AbstractComponent {
  /**
   * Интернет магазин
   *
   * @type {WidgetConfig | TradingInternetShop}
   */
  @Input() shop: WidgetConfig | TradingInternetShop;
  /**
   * Признак администратора
   */
  @Input() isAdmin = false;
  /**
   * ID ОРТ
   */
  @Input() tradingNetworkId: string;
  /**
   * Эмиттер события удаления
   *
   * @type {EventEmitter<any>}
   */
  @Output() saved: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private partnerService: PartnerService,
    private tradingNetworkService: TradingNetworkService,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    super();
  }

  /**
   * Удаление магазина
   */
  onSubmit() {
    this.loading = true;
    if (this.isAdmin) {
      this.tradingNetworkService
        .removeShop(this.shop.id, this.tradingNetworkId)
        .subscribe(
          data => {
            this.emitSuccessDelete();
          },
          error => {
            this.handleError(error);
          }
        );
    } else {
      this.partnerService.removeShop(this.shop.id).subscribe(
        data => {
          this.emitSuccessDelete();
        },
        error => {
          this.handleError(error);
        }
      );
    }
  }

  /**
   * Триггер события успешного удаления
   */
  emitSuccessDelete() {
    this.loading = false;
    this.saved.emit(true);
    this.close();
  }

  /**
   * Обработчик ошибки
   *
   * @param error Ошибка
   */
  handleError(error: any) {
    this.error = 'Error!';
    if (error.statusText) {
      this.backendError = error.statusText;
    }
    this.loading = false;
  }
  /**
   * Закрытие модалки
   */
  close(): void {
    this.modalService.dismissAll();
  }
}
