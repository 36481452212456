import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { api } from 'src/app/config';
import { CashRegister, KktSort } from 'src/app/core/models';
import { Paginable } from 'src/app/core/classes/Paginable';
import { AuthService } from 'src/app/core/services/auth.service';

/**
 * Сервис по работе с данными Кассовой техники
 */
@Injectable({
  providedIn: 'root'
})
export class KKTService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Получение списка кассовой техники
   *
   * @param page текущая страница
   * @param sort сортировка
   * @returns Наблюдателя за получением списка кассовой техники
   */
  public obtainPaged(sort: KktSort, page: number = 1): Observable<Paginable> {
    const httpParams = {};
    const filter = Object.assign({ page: String(page) }, sort);
    Object.keys(filter).forEach(
      (key: string) => (httpParams[key] = filter[key])
    );
    const options = {
      headers: null,
      params: httpParams
    };
    Object.assign(options, this.authService.getSecuredHttpOptions());
    return this.http.get<Paginable>(this.getKktPagedPath(), options);
  }

  /**
   * Получение кассового аппарата по ID
   *
   * @param id id кассового аппарата
   * @returns Наблюдателя за получением кассового аппарата
   */
  public getById(id: string): Observable<CashRegister> {
    return this.http.get<CashRegister>(
      this.byIdPath(id),
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Создание кассового аппарата
   *
   * @param data данные кассового аппарата
   * @returns Наблюдателя за созданием кассового аппарата
   */
  public createKkt(data) {
    return this.http.post(
      this.getAddKktPath(),
      data,
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Обновление кассового аппарата
   *
   * @param id id кассового аппарата
   * @param data данные кассового аппарата
   * @returns Наблюдателя за обновлением кассового аппарата
   */
  public updateKkt(id: string, data): Observable<CashRegister> {
    return this.http.put<CashRegister>(
      this.byIdPath(id),
      data,
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Удаление кассового аппарата
   *
   * @param id id кассового аппарата
   * @returns Наблюдателя за удалением кассового аппарата
   */
  public remove(id: string) {
    return this.http.delete(
      this.byIdPath(id),
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Генерация URL для действий с кассовым аппаратом по ID
   *
   * @param id id кассового аппарата
   * @returns URL для действий с кассовым аппаратом по ID
   */
  private byIdPath(id: string): string {
    return environment.baseAccountingApiUrl + api.kkt.byId.replace('{id}', id);
  }

  /**
   * Генерация URL для получения списка кассовых аппаратов
   *
   * @returns URL для получения списка кассовых аппаратов
   */
  private getKktPagedPath(): string {
    return environment.baseAccountingApiUrl + api.kkt.paged;
  }
  /**
   * Генерация URL для создания кассового аппарата
   *
   * @returns URL для создания кассового аппарата
   */
  private getAddKktPath(): string {
    return environment.baseAccountingApiUrl + api.kkt.kkt;
  }
}
