/* eslint-disable max-len */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { States } from '../widget/widget.component';
import { hasTextConfigChanges, setTextInElement } from '@app/core/utils';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
import { Subscription } from 'rxjs';
import { isUndefined } from 'lodash';

const SECONDS_IN_MINUTES = 60;
export const errorIcon =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMCAxNkMxMCAxNS40NDc3IDEwLjQ0NzcgMTUgMTEgMTVDMTEuNTUyMyAxNSAxMiAxNS40NDc3IDEyIDE2QzEyIDE2LjU1MjMgMTEuNTUyMyAxNyAxMSAxN0MxMC40NDc3IDE3IDEwIDE2LjU1MjMgMTAgMTZaTTEwIDZDMTAgNS40NDc3MiAxMC40NDc3IDUgMTEgNUMxMS41NTIzIDUgMTIgNS40NDc3MiAxMiA2VjEyQzEyIDEyLjU1MjMgMTEuNTUyMyAxMyAxMSAxM0MxMC40NDc3IDEzIDEwIDEyLjU1MjMgMTAgMTJWNlpNMTAuOTg5IDBDNC45MTcgMCAwIDQuOTI4IDAgMTFDMCAxNy4wNzIgNC45MTcgMjIgMTAuOTg5IDIyQzE3LjA3MiAyMiAyMiAxNy4wNzIgMjIgMTFDMjIgNC45MjggMTcuMDcyIDAgMTAuOTg5IDBaTTExIDIwQzYuMTM4IDIwIDIgMTUuODYyIDIgMTFDMiA2LjEzOCA2LjEzOCAyIDExIDJDMTUuODYyIDIgMjAgNi4xMzggMjAgMTFDMjAgMTUuODYyIDE1Ljg2MiAyMCAxMSAyMFoiIGZpbGw9IiNFQjU3NTciLz4NCjwvc3ZnPg0K';
export const BLOCK_MESSAGE_TITLE = 'Ошибка ввода кода';
export const BLOCK_MESSAGE =
  'Вы использовали все попытки восстановления пароля. Аккаунт временно заблокирован.\r\n\r\nПожалуйста, запросите код снова через';
export const BLOCK_MESSAGE_EMAIL =
  'Вы использовали 3 попытки восстановления пароля через SMS.\r\n\r\nУкажите email из личного кабинета сервиса Digitax, чтобы восстановить пароль через электронную почту';
/**
 * Компонент отображения ошибки блокировки пользователя
 */
@Component({
  selector: 'app-widget-error-message',
  templateUrl: './widget-error-message.component.html',
  styleUrls: ['./widget-error-message.component.scss']
})
export class WidgetErrorMessageComponent
implements OnChanges, AfterViewInit, OnDestroy {
  /**
   * Эмиттер события возврата на главный экран
   *
   * @type {EventEmitter}
   */
  @Output() returnToMainPage = new EventEmitter();
  /**
   * Конфигурация Текстовок
   *
   * @type {any[]}
   */
  @Input() textConfig: any[];
  /**
   * Кол-во секунд до разблокировки
   *
   * @type {number}
   */
  @Input() seconds: number;

  /**
   * Признак исчерпания всех попыток
   *
   * @type {boolean}
   */
  @Input() isTotalBlocked: boolean;

  /**
   * Иконка ошибки
   *
   * @type {string}
   */
  errorIcon = errorIcon;
  /**
   * Колличество минут
   *
   * @type {number}
   */
  minutes: number;
  /**
   * Признак, что осталось меньше минуты
   *
   * @type {boolean}
   */
  isLessThanMinute: boolean;

  /**
   * Заголовок блокировки
   *
   * @type {string}
   */
  blockMessageTitle = BLOCK_MESSAGE_TITLE;
  /**
   * Сообщение о блокировке
   *
   * @type {string}
   */
  blockMessage = BLOCK_MESSAGE_EMAIL;
  /**
   * Сообщение о полной блокировке
   *
   * @type {string}
   */
  blockMessageTotal = BLOCK_MESSAGE;
  /**
   * Текущий язык
   *
   * @type {object}
   */
  currentLanguage: any;
  /**
   * Подписка на изменения языка
   */
  subscription: Subscription;

  constructor(private localizationService: WidgetLocalizationService) {
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(val => {
        this.currentLanguage = val;
        this.setTexts();
      });
  }

  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.hasSecondsChanges(changes)) {
      localStorage.setItem(
        'WIDGET_BLOCK_SECONDS',
        changes.seconds?.currentValue
      );
      this.setTexts();
    }

    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  hasSecondsChanges = (changes: SimpleChanges) =>
    changes.seconds?.previousValue !== changes.seconds?.currentValue &&
    changes.seconds?.currentValue !== undefined;

  /**
   * Простановка текстов
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage || isUndefined(this.isTotalBlocked)) {
      return;
    }

    setTextInElement(
      'widget-message-block-error-title', 
      this.localizationService.findByKey('widget-title-block-error', currentLanguage.localeId)
    )
    
    if (this.isTotalBlocked) {
      setTextInElement(
        'widget-block-message',
        this.localizationService.findByKey('widget-message-block-error', currentLanguage.localeId).replace(
          '{{ minuteCounter }}',
          this.amountOfTime()
        )
      )
    } else {
      setTextInElement(
        'widget-block-message', 
        this.localizationService.findByKey('widget-message-without-email ', currentLanguage.localeId)
      )
    }

  }

  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }

  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Получение текста с колличеством оставшихся минут до разблокировки
   *
   * @returns колличество оставшихся минут до разблокировки
   */
  amountOfTime() {
    const secondsString = localStorage.getItem('WIDGET_BLOCK_SECONDS');
    if (!secondsString || isNaN(Number(secondsString))) {
      return '0';
    }
    const seconds = Number(secondsString);
    this.minutes = Math.round(seconds / SECONDS_IN_MINUTES);
    this.isLessThanMinute = seconds <= SECONDS_IN_MINUTES;
    return `${this.minutes}`;
  }

  /**
   * Триггер события возврата на главный экран
   */
  returnToMain() {
    this.returnToMainPage.emit({
      newState: States.INIT_STATE
    });
  }
}
