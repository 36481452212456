import { ACTIVE } from '../constants';

export const partnerOfferStatuses = [
  {
    code: 'ALL_OFFERS',
    name: 'ALL_OFFERS',
    localizedNames: [
      { locale: 'ru', value: 'Все статусы' },
      { locale: 'uz', value: 'Все статусы' },
      { locale: 'en', value: 'All Offers' },
      { locale: 'zh', value: '所有状态' }
    ]
  },
  {
    code: ACTIVE,
    name: ACTIVE,
    localizedNames: [
      { locale: 'ru', value: 'Активные' },
      { locale: 'uz', value: 'Активные' },
      { locale: 'en', value: 'Active Only' },
      { locale: 'zh', value: '活跃' }
    ]
  }
];
