<div class="user-controls-inner">
  <html-comment comment="noindex"></html-comment>
  <div *ngIf="!isLoggedIn" class="login-link-wrapper link">
    <a routerLink="login" role="link" rel="nofollow" i18n="@@header-loginLink">Login</a>
    <span class="separator">/</span>
    <a routerLink="register" role="link" rel="nofollow" i18n="@@header-registerLink">Sign up</a>
  </div>
  <html-comment comment="/noindex"></html-comment>

  <div *ngIf="isLoggedIn" ngbDropdown class="d-inline-block position-static">
    <button
      class="user-profile-edit btn d-flex align-items-center"
      [ngClass]="obtaining ? 'hide-icon' : ''"
      id="dropdownBasic2"
      ngbDropdownToggle
      (click)="setRoleDropdown()"
    >
      <ngx-skeleton-loader
        count="2"
        *ngIf="obtaining"
        [theme]="{
          'border-radius': '0',
          width: '107px',
          height: '16px',
          'margin-bottom': '0px'
        }"
      >
      </ngx-skeleton-loader>
      <span *ngIf="!obtaining" class="text-ellipsis">{{ getMenuLabel() }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
      <button
        ngbDropdownItem
        (click)="removeAllPath(); forwardLint(profileUrl)"
        *ngIf="url !== '/profile' && !isCustomer"
      >
        Кабинет
      </button>
      <button
        ngbDropdownItem
        (click)="removeAllPath(); forwardLint('/reports')"
        *ngIf="isReportAdmin"
      >
        Отчеты
      </button>
      <button
        ngbDropdownItem
        (click)="removeAllPath(); forwardLint(profileUrl)"
        *ngIf="url !== '/profile' && isCustomer"
        i18n="@@header-user-control-profile-link"
      >
        Personal account
      </button>
      <button
        ngbDropdownItem
        *ngIf="url == '/profile'"
        [ngClass]="url == '/profile' ? 'disabled-link' : ''"
        i18n="@@header-user-control-profile-link"
      >
        Personal account
      </button>
      <button ngbDropdownItem class="sign-out" (click)="logout()">
        <ng-container
          *ngIf="isCustomer"
          i18n="@@header-user-controls-sign-out-link"
          >Sign out</ng-container
        >
        <ng-container *ngIf="!isCustomer">Выйти</ng-container>
      </button>
    </div>
  </div>
</div>
