import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetLocalizationService {
  localizationChanged = new BehaviorSubject({ route: '/ru', label: 'RU', localeId: 'ru' });
  /**
   * Данные для локализации
   */
  localizations: any[];
  /**
   * Текущий язык
   */
  currentLanguage: any = { route: '/ru', label: 'RU', localeId: 'ru' };

  constructor() {}

  /**
   * Получение текущего языка
   *
   * @returns Текущий язык
   */
  getCurrentLanguage() {
    return this.localizationChanged.value;
  }

  /**
   * Получение наблюдателя за изменением языка
   *
   * @returns Наблюдатель за изменением языка
   */
  getLocalizationObservable() {
    return this.localizationChanged.asObservable();
  }

  /**
   * Простановка локализаций
   *
   * @param localizations локализация
   */
  setLocalizations(localizations: any[]) {
    this.localizations = localizations;
    this.updateTexts();
  }

  /**
   * Изменение языка
   *
   * @param language язык
   */
  changeLanguage(language: any) {
    this.currentLanguage = language;
    this.localizationChanged.next(this.currentLanguage);
    this.updateTexts();
  }

  /**
   * Обновление текстовок
   */
  updateTexts() {
    if (!this.localizations) {
      return;
    }
    this.localizations.forEach(item => {
      if (!item.system) {
        return;
      }
      if (item.key.includes('placeholder')) {
        return;
      }

      if (item.key.includes('error')) {
        if (item.key !== 'widget-title-block-error') {
          return;
        }
        this.setCommonTexts(item);
      }
      this.setCommonTexts(item);
    });
  }
  /**
   * Простановка обычных текстов
   *
   * @param item элемент списка локализаций
   */
  setCommonTexts(item: any) {
    const widget = document.querySelector('#widget');
    const iframe = document.querySelector('#iframe') as HTMLIFrameElement;
    let localizedTags = widget
      ? widget.shadowRoot.querySelectorAll(`[localization-key=\"${item.key}\"]`)
      : iframe.contentDocument.querySelectorAll(
          `[localization-key=\"${item.key}\"]`
      );
    if (localizedTags.length === 0) {
      localizedTags = document.querySelectorAll(
        `[localization-key=\"${item.key}\"]`
      );
    }
    if (localizedTags.length > 0) {
      localizedTags.forEach(tag => {
        tag.innerHTML =
          item.locales[this.currentLanguage.localeId];
      });
    }
  }

  /**
   * Поиск по ключу
   *
   * @param key ключ
   * @param locale локализация
   * @returns значение текстовок для всех языков
   */
  findByKey(key: string, locale: string): string {
    const search = this.localizations?.find(item => item.key === key);
    return search ? search.locales[locale] : '';
  }
}
