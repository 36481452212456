import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  ActivationStart,
  ChildActivationStart,
  NavigationEnd,
  Router
} from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { USER } from '../constants';
import { AuthService } from './auth.service';

/**
 * Сервис хлебных крошек
 */
@Injectable({
  providedIn: 'root'
})
export class NavigationMobileService {
  /**
   * Текущий заголовок
   *
   * @type {string}
   */
  currentPathName = '';
  /**
   * Заголовок родительского роута
   *
   * @type {string}
   */
  currentParentName = '';
  /**
   * Дефолтный путь
   *
   * @type {string}
   */
  defaultPathName: string;
  /**
   * Временное хранилище для пути
   *
   * @type {string}
   */
  tempCurrentPathName: string;
  /**
   * Признак отображения табов
   *
   * @type {boolean}
   */
  showTabs = true;
  /**
   * Признак отображения контента
   *
   * @type {boolean}
   */
  showSecondTabs = false;
  /**
   * @type {boolean}
   */
  showModalForm = false;
  /**
   * Признак отображения модального окна
   *
   * @type {boolean}
   */
  showEditModalForm = false;
  /**
   * Признак отображения модального окна
   *
   * @type {boolean}
   */
  showNewModalForm = false;
  /**
   * Признак отображения табов пунктов пропуска
   *
   * @type {boolean}
   */
  showPointsTab = false;
  /**
   * Текущий URL
   *
   * @type {string}
   */
  currentUrl: string;
  /**
   * Подписчик на события роутера
   *
   * @type {Subscription}
   */
  routerEvents: Subscription;
  /**
   * Локализованые хлебные крошки
   *
   * @type {any}
   */
  localizedBreadcrumbs: any;
  /**
   * Родителский URL для оператора
   *
   * @type {string}
   */
  operatorCheckParentLink: string;
  /**
   * Предыдущий URL для перехода назад
   *
   * @type {string}
   */
  preventUrl: string;
  /**
   * Кастомный заголовок для оператора
   *
   * @type {string}
   */
  customOperatorPageName: string;
  /**
   * родительский URL
   *
   * @type {string}
   */
  parentPreviousUrl: string;
  /**
   * Предудущий URL
   *
   * @type {string}
   */
  previousUrl: string;
  /**
   * Признак страницы подтверждения email
   *
   * @type {boolean}
   */
  updateEmailTrigger: boolean;
  /**
   * Признак наличия списка доступных ролей у роута
   *
   * @type {boolean}
   */
  partnerRoleActived = false;

  constructor(
    private userRole: AuthService,
    private router: Router,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.routerEvents = this.router.events.subscribe(event => {
      if (event instanceof ActivationStart) {
        this.processStartEvent(event);
      }
      if (event instanceof NavigationEnd) {
        const indexOfCodeParam: number = event.url.indexOf('referral_code=');
        if (indexOfCodeParam !== -1) {
          const lastIndex: number = event.url.lastIndexOf('&') === -1 ? event.url.length : event.url.lastIndexOf('&');
          const code: string = event.url.substring(indexOfCodeParam + 14, lastIndex);
          localStorage.setItem('referral_code', JSON.stringify({ code, addingDate: new Date() }));
        }
        this.processEndEvent(event);
      }
    });

    this.userRole.userRoleChange.subscribe(user => {
      this.userChangeEvent(user);
    });
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.parentPreviousUrl = this.previousUrl;
        this.previousUrl = e.url;
        if (this.previousUrl.includes('updateEmail/confirm')) {
          this.updateEmailTrigger = true;
        }
      });
  }

  /**
   * Обработка события ActivationStart
   *
   * @description проставляет текущие и родительские заголовки
   * @param event событие начала перехода
   */
  processStartEvent(event: any) {
    if (event.snapshot && event.snapshot.data && event.snapshot.data.title) {
      if (
        event.snapshot.data &&
        event.snapshot.data.parentTitle === 'RefundTracker'
      ) {
        return;
      }
      this.currentParentName = event.snapshot.data.parentTitle
        ? event.snapshot.data.parentTitle
        : null;
      this.currentPathName = event.snapshot.data.title;
      this.currentPathName = event.snapshot.data.title[this.localeId]
        ? event.snapshot.data.title[this.localeId]
        : event.snapshot.data.title;
    }
  }

  /**
   * Обработка события NavigationEnd
   *
   * @description Проставляет в хранилище данные для оператора
   * @param event событие завершение перехода
   */
  processEndEvent(event: any) {
    const linkKey = 'operatorCheckParentLink';
    const pageNameKey = 'customOperatorPageName';
    const navigationStrategies = [
      {
        url: '/operator/info/checks',
        store: () => {
          localStorage.setItem(linkKey, event.url);
          localStorage.setItem(pageNameKey, 'Чеки Tax Free');
        }
      },
      {
        url: '/operator/info/check-fts',
        store: () => {
          localStorage.setItem(linkKey, event.url);
          localStorage.setItem(pageNameKey, 'Чеки Tax Free (статус ФТС)');
        }
      },
      {
        url: '/operator/info/validation',
        store: () => {
          localStorage.setItem(linkKey, event.url);
          localStorage.setItem(pageNameKey, 'Результаты валидации');
        }
      },
      {
        url: '/operator/info/money-orders',
        store: () => {
          localStorage.setItem(linkKey, event.url);
          localStorage.setItem(pageNameKey, 'Платежные поручения');
        }
      },
      {
        url: '/operator/checks-info',
        store: () => {
        }
      },
      {
        url: '/admin/internet-shops',
        store: () => {
          localStorage.setItem(linkKey, event.url);
          localStorage.setItem(pageNameKey, 'Управление ИМ');
        }
      },
      {
        url: '/admin/widget-settings',
        store: () => {
        }
      }
    ];
    const currentNavigation = navigationStrategies.find(navigationStrategy =>
      event.url.includes(navigationStrategy.url)
    );
    console.log(currentNavigation);
    if (!currentNavigation) {
      localStorage.removeItem(linkKey);
      localStorage.removeItem(pageNameKey);
      return;
    }
    currentNavigation.store();
    console.log(localStorage.getItem(linkKey));
  }

  /**
   * Обработчик события изменения пользователя
   *
   * @description подписывается на событие ChildActivationStart
   * если у роута есть параметр allowedRoles то проставляем
   * признак наличия списка доступных ролей у роута
   * @param user пользователь
   */
  userChangeEvent(user: any[]) {
    if (user.includes(USER)) {
      return;
    }
    this.router.events.subscribe(el => {
      if (el instanceof ChildActivationStart) {
        this.partnerRoleActived = !!el.snapshot.data?.allowedRoles;
      }
    });
  }
}
