import { Directive, HostListener, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
/**
 * Абстрактный класс
 */
@Directive()
export abstract class AbstractComponent implements OnDestroy {
  /**
   * Признак получения данных
   *
   * @type {boolean}
   */
  obtaining: boolean;
  /**
   * Признак отображения лейблов для моб устр-в
   *
   * @type {boolean}
   */
  isMobile: boolean;
  /**
   * Признак планшета
   *
   * @type {boolean}
   */
  isTablet: boolean;
  /**
   * Признак маленького по высоте экрана
   *
   * @type {boolean}
   */
  isSmallHeight = false;
  /**
   * Текст ошибки
   *
   * @type {string}
   */
  error = '';
  /**
   * Признак процесса загрузки
   *
   * @type {boolean}
   */
  loading = false;
  /**
   * Признак успешного сохранения
   *
   * @type {boolean}
   */
  success = false;
  /**
   * Текст ошибки с сервера
   *
   * @type {string}
   */
  backendError = '';

  /**
   * Subject для завершения всех подписок при дестрое компонента
   *
   * @type {Subject}
   */
  protected destroy$ = new Subject<void>();
  /**
   * Обработчик события изменения размеров окна
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 768;
    this.isTablet = window.innerWidth < 992;
    this.isSmallHeight = window.innerHeight <= 915;
  }
  /**
   * Хук финализации
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Создание ссылки и скачивание файла
   *
   * @param name имя файла
   * @param blob блоб с файлом
   */
  downloadFile(name: string, blob: Blob) {
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = name;
    link.click();
  }
}
