import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pickBy } from 'lodash';
import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { AuthService } from './auth.service';
import { Checkpoint } from '../models';
import { Paginable } from '../classes';

/**
 * Сервис для получения данных о пунктах пропуска
 */
@Injectable({
  providedIn: 'root'
})
export class CheckpointsListService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Добавление пункта пропуска
   *
   * @param data данные о пункте пропуска
   * @returns Наблюдатель за сохранением пункта пропуска
   */
  addCheckpoint(data: Checkpoint): Observable<any> {
    return this.http.post<Checkpoint>(
      this.getCheckpointsPath(),
      data,
      this.httpOptions
    );
  }

  /**
   * Получение пунктов пропуска
   *
   * @param filtering фильтр
   * @returns Наблюдатель за получением пунктов пропуска
   */
  getCheckpoints(filtering: any): Observable<Paginable> {
    const params = pickBy(filtering, val => val);
    const options = Object.assign(this.httpOptions, { params });
    return this.http.get<any>(this.getCheckpointsPath(), options);
  }

  /**
   * Получение Пункта пропуска по ID
   *
   * @param checkpointId id пункта пропуска
   * @returns Наблюдатель за получением Пункта пропуска
   */
  getCheckpointById(checkpointId: string): Observable<Checkpoint> {
    return this.http.get<any>(
      this.getCheckpointsbyIdPath(checkpointId),
      this.httpOptions
    );
  }

  /**
   * Обновление Пункта пропуска
   *
   * @param checkpoint пункт пропуска
   * @returns Наблюдатель за обновлением пунтка пропуска
   */
  updateCheckpoint(checkpoint: Checkpoint): Observable<any> {
    return this.http.put<any>(
      this.getCheckpointsbyIdPath(checkpoint.id),
      checkpoint,
      this.httpOptions
    );
  }

  /**
   * Удаление Пункта пропуска
   *
   * @param checkpointId id Пункта пропуска
   * @returns Наблюдатель за удалением Пункта пропуска
   */
  removeCheckpoint(checkpointId: string): Observable<any> {
    return this.http.delete<any>(
      this.getCheckpointsbyIdPath(checkpointId),
      this.httpOptions
    );
  }

  /**
   * Генерация URL для запроса списка Пунктов Пропуска
   *
   * @returns URL для запроса списка Пунктов Пропуска
   */
  private getCheckpointsPath(): string {
    return environment.baseAccountingApiUrl + api.checkpoints.main;
  }

  /**
   * Генерация URL для запросов по id Пункта Пропуска
   *
   * @param checkpointId id Пункта пропуска
   * @returns URL для запросов по id Пункта Пропуска
   */
  private getCheckpointsbyIdPath(checkpointId: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.checkpoints.main +
      `/${checkpointId}`
    );
  }
}
