import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { Offer, OfferPartnerFilter } from 'src/app/core/models/partnerOffer';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { tap } from 'rxjs/operators';

/**
 * Сервис для работы с предложениями у роли партнера
 */
@Injectable({
  providedIn: 'root'
})
export class PartnerOffersService {
  /**
   * Subject для хранения и оповещения об общем числе предложений
   *
   * @type {Subject<number>}
   */
  private currentOffersLength: Subject<number> = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Геттер наблюдателя за изменением текущего числа предложений
   */
  get offersLengthObservable() {
    return this.currentOffersLength.asObservable();
  }

  /**
   * Создание предложения
   *
   * @param offer данные предложения
   * @returns Наблюдателя за созданием предложения
   */
  add(offer: Offer): Observable<any> {
    return this.http.post<Offer>(
      this.getAddOfferPath(),
      offer,
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Обновление предложения
   *
   * @param offer данные предложения
   * @param offerId ID предложения
   * @returns Наблюдателя за обновлением предложения
   */
  update(offer: Offer, offerId: string): Observable<any> {
    return this.http.put<Offer>(
      this.getUpdateOfferPath(offerId),
      offer,
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Получение списка предложений
   *
   * @param offerFilter фильтр предложений
   * @returns Наблюдателя за получением списка предложений
   */
  obtainByFilter(offerFilter: OfferPartnerFilter): Observable<Offer[]> {
    if (offerFilter.active) {
      return this.obtainAllActive();
    }
    return this.obtainAll();
  }

  /**
   * Получение всех активных предложений
   *
   * @returns Наблюдателя за получением всех активных предложений
   */
  obtainAllActive(): Observable<Offer[]> {
    return this.http
      .get<Offer[]>(
        this.getActiveOffersPath(),
        this.authService.getSecuredHttpOptions()
    )
      .pipe(
        tap(offers => {
          this.currentOffersLength.next(offers.length);
        })
      );
  }

  /**
   * Получение предложения по ID
   *
   * @param offerId ID предложения
   * @returns Наблюдателя за Получением предложения по ID
   */
  obtainById(offerId: string): Observable<Offer> {
    return this.http.get<Offer>(
      this.getOfferByIdPath(offerId),
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Удаление предложения
   *
   * @param offerId id предложения
   * @returns Наблюдателя за удалением предложения
   */
  remove(offerId: string): Observable<any> {
    return this.http.delete<any>(
      this.getRemoveByIdPath(offerId),
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Выгрузка реестра чеков
   *
   * @param from Дата "от"
   * @param to Дата "до"
   * @param format формат файла
   * @returns Наблюдателя за выгрузкой реестра чеков
   */
  downloadProducts(from: string, to: string, format: string): Observable<any> {
    const path =
      format === 'XLS'
        ? this.getDownloadExcelProductsPath()
        : this.getDownloadProductsPath();
    return this.http.get(path, {
      ...this.authService.getSecuredHttpOptionsForFile(),
      params: { from, to, format }
    });
  }

  /**
   * Получение всех предложений
   *
   * @returns Наблюдателя за получением всех предложений
   */
  private obtainAll(): Observable<Offer[]> {
    return this.http
      .get<Offer[]>(
        this.getOffersPath(),
        this.authService.getSecuredHttpOptions()
    )
      .pipe(
        tap(offers => {
          this.currentOffersLength.next(offers.length);
        })
      );
  }
  /**
   * Генерация URL для добавления предложения
   *
   * @returns URL для добавления предложения
   */
  private getAddOfferPath(): string {
    return environment.basePortalApiUrl + api.offers.addOffer;
  }
  /**
   * Генерация URL для обновления предложения
   *
   * @param offerId id предложения
   * @returns URL для обновления предложения
   */
  private getUpdateOfferPath(offerId: string): string {
    return (
      environment.basePortalApiUrl +
      api.offers.updateOffer.replace('{offerId}', offerId)
    );
  }
  /**
   * Генерация URL для получения списка предложений
   *
   * @returns URL для получения списка предложений
   */
  private getOffersPath(): string {
    return environment.basePortalApiUrl + api.offers.partnerOffers;
  }
  /**
   * Генерация URL для получения списка активных предложений
   *
   * @returns URL для получения списка активных предложений
   */
  private getActiveOffersPath(): string {
    return environment.basePortalApiUrl + api.offers.active;
  }
  /**
   * Генерация URL для получения предложения по ID
   *
   * @param offerId id предложения
   * @returns URL для получения предложения по ID
   */
  private getOfferByIdPath(offerId: string): string {
    return (
      environment.basePortalApiUrl +
      api.offers.offerById.replace('{offerId}', offerId)
    );
  }
  /**
   * Генерация URL для удаления предложения
   *
   * @param offerId id предложения
   * @returns URL для удаления предложения
   */
  private getRemoveByIdPath(offerId: string): string {
    return (
      environment.basePortalApiUrl +
      api.offers.removeById.replace('{offerId}', offerId)
    );
  }

  /**
   * Генерация URL для выгрузки реестра чеков в xml
   *
   * @returns URL для выгрузки реестра чеков
   */
  private getDownloadProductsPath(): string {
    return environment.baseAccountingApiUrl + api.offers.products;
  }

  /**
   * Генерация URL для выгрузки реестра чеков в excel для партнёра
   *
   * @returns URL для выгрузки реестра чеков
   */
  private getDownloadExcelProductsPath(): string {
    return environment.baseReportingApiUrl + api.offers.productsExcel;
  }
}
