import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Сервис для загрузки документов
 */
@Injectable({ providedIn: 'root' })
export class DownloadDocumentService {
  /**
   * Http Опции
   */
  httpOptions = {
    'Content-Type': 'application/pdf',
    responseType: 'blob' as 'json',
    Accept: 'application/pdf'
  };
  /**
   * Http Опции с заголовком X-Skip-Interceptor
   */
  httpOptionsWithSkipCacheHeader = {};

  constructor(private http: HttpClient) {}

  /**
   * Загрузка pdf документа
   *
   * @param link ссылка на документ
   * @returns наблюдателя за выгрузкой pdf Документа
   */
  downloadPdf(link: string): Observable<any> {
    return this.http.get<Blob>(link, this.httpOptions).pipe(
      map((res: Blob) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        return window.URL.createObjectURL(blob);
      })
    );
  }
}
