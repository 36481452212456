<div class="cookie-content-wrapper" *ngIf="isShowing">
  <h4 class="cookie-title">Сookie Policy</h4>
  <p class="cookie-description">
    <span i18n="@@cookie-text-first-string">We use cookies to personalize content and improve user experience.</span><br />
    <span i18n="@@cookie-text-second-string"
      >By continuing to browse this web portal, you consent to the use of cookies.</span
    ><br />
    <span i18n="@@cookie-text-third-string">For more information, please see our </span>
    <a routerLink="/about/terms" i18n="@@cookie-text-link">Cookie Policy.</a>
  </p>
  <div class="submit-btn-wrapper">
    <button
      class="btn btn-primary submit"
      i18n="@@cookie-submit-btn"
      (click)="closePopup()"
    >
      Submit
    </button>
    <button (click)="closePopup()" class="btn close">X</button>
  </div>
</div>
