import { Serializable } from './Serializable';

export class Contacts extends Serializable {
  emails: string[];
  phoneNumbers: string[];

  constructor(json?: any) {
    super(json);
    if (!this.emails) {
      this.emails = [];
    }
    if (!this.phoneNumbers) {
      this.phoneNumbers = [];
    }
  }

  public toString(): string {
    return `Contact (emails: ${this.emails.toString()}, phoneNumbers: ${this.phoneNumbers.toString()})`;
}
}
