<div class="crumbs-mobile-menu" *ngIf="showChildren" (click)="hideChildren()">
  <div class="show-arrow">{{ crumbsTitle }}</div>
</div>
<div class="mobile-logo-wrapper" *ngIf="!showChildren" (click)="goHome()">
  <span class="logo">
    <img src='{{minioHost}}/media/assets/portal-header-logo.svg' />
  </span>
</div>
<ul class="navbar-nav mr-auto">
  <li class="nav-item" *ngFor="let route of links">
    <a
      *ngIf="!showChildren"
      (click)="
        route.children && route.children.length
          ? showChildrenTrigger(route)
          : hideMenu(route)
      "
      class="nav-link"
      routerLinkActive="active"
      [ngClass]="currParent === route.title.toLowerCase() ? 'active' : ''"
      >{{
        currPath.partnerRoleActived
          ? route.lang && route.lang['ru']
            ? route.lang['ru']
            : route.title
          : route.lang && route.lang[localeId]
          ? route.lang[localeId]
          : route.title
      }}</a
    >
    <ul
      class="nav-item-children"
      *ngIf="
        showChildren &&
        crumbsTitle ==
          (route.lang[localeId] ? route.lang[localeId] : route.title)
      "
    >
      <ng-container *ngIf="route.children">
        <li
          routerLinkActive="active"
          class="child-item"
          *ngFor="let child of route.children"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <a (click)="hideMenu(route)" routerLink="{{ child.routerLink }}">{{
            currPath.partnerRoleActived
              ? child.lang && child.lang['ru']
                ? child.lang['ru']
                : child.title
              : child.lang && child.lang[localeId]
              ? child.lang[localeId]
              : child.title
          }}</a>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>
<ng-container *ngIf="!showChildren">
  <div *ngIf="isLoggedIn"
       class="auth-link logout-link"
       (click)="logout()"
       i18n="@@mobile-menu-logout"
  >Sign Out</div>
  <div *ngIf="!isLoggedIn"
       class="btn auth-link login-link w-100"
       (click)="login()"
       i18n="@@mobile-menu-login"
  >Login</div>
</ng-container>
