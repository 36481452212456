/* eslint-disable id-denylist */
import { Directive } from '@angular/core';
import { AbstractFormComponent } from '@app/core/classes';
import { WidgetService } from '@app/core/services/widget.service';

/**
 * Абстрактный класс со счетчиком
 */
@Directive()
export abstract class AbstractCounterComponent extends AbstractFormComponent {
  /**
   * Интервал
   *
   * @type {any}
   */
  interval: any;
  /**
   * Признак ожидания повторной отправки кода
   *
   * @type {boolean}
   */
  loadingResend: boolean;
  constructor(protected widgetService: WidgetService) {
    super();
  }
  /**
   * Получение значения счетчика
   */
  get widgetCounter() {
    return this.widgetService.counter;
  }
  /**
   * Уменьшение счётчика
   *
   * @description Устанавливает интервал на уменьшение счётчика каждую секунду.
   */
  countdown(): void {
    if (this.widgetService.counter < 30) {
      return;
    }
    this.interval = setInterval(() => {
      this.widgetService.counter = this.widgetService.counter - 1;
      if (this.widgetService.counter <= 0) {
        clearInterval(this.interval);
        this.interval = null;
      }
    }, 1000);
  }

  /**
   * Очистка интервала
   */
  clearInterval() {
    clearInterval(this.interval);
    this.widgetService.counter = 30;
  }
  /**
   * Рестарт счетчика
   */
  restartCounter() {
    this.widgetService.counter = 30;
    this.countdown();
    this.loadingResend = false;
  }
}
