import { sortBy } from 'lodash';
/**
 * Абстрактный класс, сортирующий элементы в зависимости от приоритета
 */
abstract class AbstractPrioritizer<T> {
  /**
   * Массив элементов, который должен быть отсортирован в зависимости от приоритета
   *
   * @type {Array<T>}
   */
  private sourceElements: T[] = [];
  /**
   * Массив приоритетов, в котором порядок определяет приоритет от большего к меньшему
   *
   * @type {Array<T>}
   */
  protected abstract priorities: T[];

  constructor(elements: T[]) {
    this.sourceElements = elements.slice();
  }

  /**
   * Геттер, производящий сортировку элементов в зависимости от приоритета, и возвращающий результат
   * сортировки
   *
   * @type {Array<T>}
   */
  get elements(): T[] {
    return sortBy(this.sourceElements, [this.getPriority]);
  }

  /**
   * Получение приоритета элемента в зависимости от индекса в списке приоритетов this.priorities
   *
   * @param element элемент, для которого выполняется поиск приоритета
   * @returns индекс элемента в списке приоритетов, если он найден, или Infinity, если не найден
   */
  private getPriority = (element: T): number => {
    const lowestPriority = Infinity;
    const elementPriority = this.priorities.indexOf(element);
    const hasPriority = elementPriority !== -1;
    return hasPriority ? elementPriority : lowestPriority;
  };
}

/**
 * Абстрактный класс, сортирующий ключи объекта в зависимости от приоритета
 */
abstract class ObjectKeysPrioritizer extends AbstractPrioritizer<string> {
  constructor(object: { [key: string]: any }) {
    super(Object.keys(object));
  }
}

/**
 * Класс, сортирующий ошибки полей ввода пароля в зависимости от приоритета
 */
export class PasswordErrorsPrioritizer extends ObjectKeysPrioritizer {
  /**
   * Приоритет ошибок полей ввода пароля
   */
  protected priorities = [
    'forbiddenSymbols',
    'maxlength',
    'minlength',
    'required',
    'doNotMatch'
  ];
}

/**
 * Класс, сортирующий ошибки полей ввода e-mail в зависимости от приоритета
 */
export class EmailErrorsPrioritizer extends ObjectKeysPrioritizer {
  /**
   * Приоритет ошибок полей ввода e-mail
   */
  protected priorities = [
    'required',
    'email',
    'maxlength',
    'emailMaxLength',
    'emailIncorrect',
    'minlength',
    'notUnique',
    'badEmail'
  ];
}
