import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AbstractFormComponent } from '@app/core/classes';

/**
 * Компонент textarea с кнопкой копирования текста
 */
@Component({
  selector: 'app-copy-text-area',
  templateUrl: './copy-text-area.component.html',
  styleUrls: ['./copy-text-area.component.scss']
})
export class CopyTextAreaComponent extends AbstractFormComponent
  implements OnInit, OnChanges {
  /**
   * Заголовок
   *
   * @type {string}
   */
  @Input() title: string;
  /**
   * Текст сообщения
   *
   * @type {string}
   */
  @Input() text: string;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    this.form = this.formBuilder.group({
      note: [{ value: this.text, disabled: true }]
    });
  }
  /**
   * Хук получения изменений
   *
   * @param changes изменения полей
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.text.currentValue &&
      changes.text.currentValue !== changes.text.previousValue
    ) {
      if (!this.form) {
        return;
      }
      this.form.controls.note.setValue(changes.text.currentValue);
    }
  }

  /**
   * Копирование текста в буфер обмена
   */
  copyInputMessage() {
    navigator.clipboard.writeText(this.text);
  }
}
