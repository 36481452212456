import { Serializable } from '../Serializable';
import * as moment from 'moment';

export class FtsChequeFilter extends Serializable {
  status: string;
  page: number;
  chequeIdNumber: string;
  tradingNetworkId: string;
  startDateFtsValidationSentAt: Date;
  endDateFtsValidationSentAt: Date;
  startDateFtsValidationReceivedAt: Date;
  endDateFtsValidationReceivedAt: Date;
  startDateFtsResponseReceivedAt: Date;
  endDateFtsResponseReceivedAt: Date;

  toHttpParams(): any {
    const result = {};

    if (this.status) {
      result['status'] = this.status;
    }
    if (this.page) {
      result['page'] = this.page;
    }
    if (this.chequeIdNumber) {
      result['chequeIdNumber'] = this.chequeIdNumber;
    }
    if (this.tradingNetworkId) {
      result['id'] = this.tradingNetworkId;
    }
    if (this.startDateFtsValidationSentAt) {
      result['startDateFtsValidationSentAt'] =  moment(this.startDateFtsValidationSentAt).format('YYYY-MM-DD');
    }
    if (this.endDateFtsValidationSentAt) {
      result['endDateFtsValidationSentAt'] = moment(this.endDateFtsValidationSentAt).format('YYYY-MM-DD');
    }
    if (this.startDateFtsValidationReceivedAt) {
      result['startDateFtsValidationReceivedAt'] = moment(this.startDateFtsValidationReceivedAt).format('YYYY-MM-DD');
    }
    if (this.endDateFtsValidationReceivedAt) {
      result['endDateFtsValidationReceivedAt'] = moment(this.endDateFtsValidationReceivedAt).format('YYYY-MM-DD');
    }
    if (this.startDateFtsResponseReceivedAt) {
      result['startDateFtsResponseReceivedAt'] = moment(this.startDateFtsResponseReceivedAt).format('YYYY-MM-DD');
    }
    if (this.endDateFtsResponseReceivedAt) {
      result['endDateFtsResponseReceivedAt'] = moment(this.endDateFtsResponseReceivedAt).format('YYYY-MM-DD');
    }
    return result;
  }
}
