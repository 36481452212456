import { Serializable } from './Serializable';
import { KKT_SORTING_TYPES, sortOrder } from '../constants';

export class KktSort extends Serializable {
  sortBy = KKT_SORTING_TYPES.REG_NUM;
  sortDir = sortOrder.ASC.name;

  toHttpParams(): any {
    const result = {};

    if (this.sortBy) {
      result['sortBy'] = this.sortBy;
    }
    if (this.sortDir) {
      result['sortDir'] = this.sortDir;
    }
    return result;
  }
}
