<div class="description text-center">
  {{
    isAdmin? 'При необходимости поочередно скопируйте скрипты и параметры для направления Партнеру к 
    последующему размещению этих сведений в коде страницы ИМ' : 
    'Поочередно скопируйте скрипты и параметры для последующего размещения этих сведений в коде страницы ИМ'
  }}
</div>
<div class="script-block">
  <div class="script-item">
    <app-copy-text-area title="Скрипт инициализации виджета" [text]="script"></app-copy-text-area>
  </div>
  <div class="script-item">
    <app-copy-text-area class="script-item" title="Shop ID" [text]="shopId"></app-copy-text-area>
  </div>
  <div class="script-item">
    <app-copy-text-area class="script-item" title="SecretKey" [text]="secretKey"></app-copy-text-area>
  </div>
</div>