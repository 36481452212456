import { PAYMENT_SYSTEMS } from '../../core/constants';

export const shoppersRefund = {
  phone: {
    maxLength: 19,
    minLength: 7
  },
  captcha: {
    maxLength: 5
  },
  formsId: {
    minLength: 16
  },
  email: {
    maxLength: 50
  },
  cardNumber: {
    minLength: 16,
    maxLength: 16
  },
  cardPayNumber: {
    minLength: (paymentTypeVal: string): number =>
      paymentTypeVal === PAYMENT_SYSTEMS.WECHAT ? 18 : 16,
    maxLength: (paymentTypeVal: string): number =>
      paymentTypeVal === PAYMENT_SYSTEMS.WECHAT ? 18 : 16
  },
  cardHolderName: {
    maxLength: 60
  }
};
