import { ValidatorFn, AbstractControl } from '@angular/forms';
import { URL_REG_EXP } from '@app/core/constants';

/**
 * Валидатор url
 *
 * @description проверяет введенный url по регулярному выражению;
 * если не соответсвует, то проставляется ошибка в поле формы
 * @returns функцию валидации
 */
export const urlValidator = (): ValidatorFn => (
  control: AbstractControl
): { [key: string]: any } | null => {
  const urlRegExp = URL_REG_EXP;
  const value = control.value;

  if (value !== null && value !== '') {
    if (!urlRegExp.test(value)) {
      return { urlIncorrect: { value } };
    }
  }
};
