import { Component } from '@angular/core';
/**
 * Компонент Нет доступа к странице
 */
@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})
export class NotAllowedComponent {}
