import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { TradingNetworkOrg } from '../models/TradingNetworkOrg';

/**
 * Сервис для Торговой сети
 */
@Injectable()
export class OrganizationService {
  /**
   * Http Опции
   */
  httpOptions = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.authService.getHttpOptionsByAuthState();
  }

  /**
   * Получение Торговой сети по ID
   *
   * @param organizationId id Торговой сети
   * @returns Наблюдателя за получением торговой сети по ID
   */
  public getOrganization(
    organizationId: string
  ): Observable<TradingNetworkOrg> {
    return this.http
      .get<TradingNetworkOrg>(
        environment.baseAccountingApiUrl + `/organizations/${organizationId}`,
        this.httpOptions
    )
      .pipe(
        catchError(err => {
          console.error('Error get organization' + err.message);
          return throwError(err);
        })
      );
  }

  /**
   * Получение Торговых сетей для партнёра
   *
   * @returns Наблюдателя за получением торговых сетей
   */
  public getOrganizations(): Observable<TradingNetworkOrg[]> {
    return this.http
      .get<TradingNetworkOrg[]>(
        environment.basePortalApiUrl +
          '/trading-networks/organizations/by-partner',
        this.httpOptions
    )
      .pipe(
        catchError(err => {
          console.error('Error get organizations' + err.message);
          return throwError(err);
        })
      );
  }
}
