/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { AbstractFormComponent } from '@app/core/classes';
import { contactsEdit, emailValidator } from 'src/app/config/validators';
import { urlValidator } from 'src/app/config/validators/urlValidator';
import { takeUntil } from 'rxjs/operators';
import { WidgetConfig } from '@app/core/models/Widget/WidgetConfig';
import { periodItems } from 'src/app/modules/portal-admin/pages/admin-shops/internet-shops/internet-shops.component';
import { TradingInternetShop } from '@app/core/models/TradingInternetShop';
import { TradingNetworkOrg } from '@app/core/models/TradingNetworkOrg';
export const widgetLocations = [
  {
    label: 'На всех страницах ИМ',
    value: 'ALL'
  },
  {
    label: 'Страница Корзины ИМ',
    value: 'CART'
  }
];
/**
 * Компонент 1 шага добавления Интернет магазина
 */
@Component({
  selector: 'app-shop-information',
  templateUrl: './shop-information.component.html',
  styleUrls: ['./shop-information.component.scss']
})
export class ShopInformationComponent extends AbstractFormComponent
  implements OnInit {
  /**
   * Эмиттер события дизейбла перехода на следующий шаг
   *
   * @type {EventEmitter}
   */
  @Output() disableNextStep = new EventEmitter();
  /**
   * Процесс сохранения данных о магазине
   *
   * @type {boolean}
   */
  @Input() saveShopInfo = false;
  /**
   * Признак администратора
   *
   * @type {boolean}
   */
  @Input() isAdmin: boolean;
  /**
   * Список ОПОРТОВ
   *
   * @type {TradingNetworkOrg[]}
   */
  @Input() oports: TradingNetworkOrg[];
  /**
   * Максимальная длина e-mail
   *
   * @type {number}
   */
  emailMaxLength: number = contactsEdit.email.maxLength;
  /**
   * Список периодов запроса
   *
   * @type {label: string; value: number}
   */
  periods = periodItems;

  /**
   * Список возможных размещений виджета
   *
   * @type {label: string; value: number}
   */
  locations = widgetLocations;
  /**
   * Даные магазина при редактировании
   *
   * @type { TradingInternetShop | WidgetConfig}
   */
  private _internetShop: TradingInternetShop | WidgetConfig;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  /**
   * Геттер данных магазина
   */
  get internetShop(): TradingInternetShop | WidgetConfig {
    return this._internetShop;
  }

  /**
   * Сеттер данных
   */
  @Input() set internetShop(internetShop: TradingInternetShop | WidgetConfig) {
    if (this._internetShop !== internetShop) {
      this._internetShop = internetShop;
      this.setAdminDataToForm();
    }
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    this.form = this.formBuilder.group({
      email: [
        this.internetShop ? this.internetShop.email : null,
        [Validators.required, emailValidator()]
      ],
      shopName: [
        this.internetShop ? this.internetShop.name : null,
        Validators.required
      ],
      shopUrl: [
        this.internetShop ? this.internetShop.url : null,
        [Validators.required, urlValidator()]
      ],
      chequeUrl: [
        this.internetShop ? this.internetShop.chequeUrl : null,
        [urlValidator()]
      ],
      widgetLocation: [
        this.internetShop ? this.internetShop.widgetLocation : null,
        [Validators.required]
      ]
    });
    if (this.isAdmin) {
      this.form.addControl(
        'chequeRequestTime',
        new UntypedFormControl(
          this.internetShop && this.internetShop.chequeRequestTime
            ? this.internetShop.chequeRequestTime
            : 'MIN_10',
          Validators.required
        )
      );
      this.form.addControl(
        'widgetStatusTime',
        new UntypedFormControl(
          this.internetShop && this.internetShop.widgetStatusTime
            ? this.internetShop.widgetStatusTime
            : 'MIN_10',
          Validators.required
        )
      );
    } else {
      this.form.addControl(
        'oport',
        new UntypedFormControl(
          this.internetShop ? this.internetShop.parentId : null,
          Validators.required
        )
      );
    }

    this.checkAndEmitDisabled();

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.checkAndEmitDisabled();
    });
  }

  /**
   * Простановка даных в форму
   */
  setAdminDataToForm() {
    if (!this.internetShop || !this.form) {
      return;
    }
    this.f.email.setValue(this.internetShop?.email);
    this.f.shopUrl.setValue(this.internetShop?.url);
    this.f.shopName.setValue(this.internetShop?.name);
    this.f.widgetLocation.setValue(this.internetShop?.widgetLocation);
    this.f.chequeUrl.setValue(this.internetShop?.chequeUrl);
    if (!this.isAdmin) {
      return;
    }
    this.f.widgetStatusTime.setValue(this.internetShop?.widgetStatusTime);
    this.f.chequeRequestTime.setValue(this.internetShop?.chequeRequestTime);
  }
  /**
   * Обработчик события вставки в поле email
   *
   * @param event переменная, содержащая информацию о буфере обмена
   * @param fieldName имя контролла
   */
  onPaste(event: ClipboardEvent, fieldName: string): void {
    event.preventDefault();
    this.f[fieldName].setValue(event.clipboardData.getData('text/plain'));
    setTimeout(() => this.f[fieldName].updateValueAndValidity(), 0);
  }

  /**
   * Метод дизейбла перехода на след.шаг
   *
   * @description проверяет нужно ли заблокировать переход на след. шаг
   */
  checkAndEmitDisabled() {
    this.checkSubmit();
    this.disableNextStep.emit(this.disabledSubmit);
  }
}
