import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Окно успешного добавления виджета
 */
@Component({
  selector: 'app-widget-success-add',
  templateUrl: './widget-success-add.component.html',
  styleUrls: ['./widget-success-add.component.scss']
})
export class WidgetSuccessAddComponent {
  /**
   * Закрытие окна
   *
   * @type {EventEmitter}
   */
  @Output() closeEvent = new EventEmitter();

  /**
   * Закрытие окна с переходом в настройки
   *
   * @type {EventEmitter}
   */
  @Output() redirectEvent = new EventEmitter();
  /**
   * Признак админа
   *
   * @type {boolean}
   */
  @Input() isAdmin: boolean;
}
