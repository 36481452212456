import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
/**
 * Директива для валидации кода
 */
@Directive({
  selector: '[appConfirmCodeValidator]'
})
export class ConfirmCodeValidatorDirective {
  /**
   * Массив полей ввода проверочного кода
   *
   * @type {FormArray}
   */
  @Input() codeForm: UntypedFormArray;
  /**
   * Индекс контрола проверочного кода
   *
   * @type {number}
   */
  @Input() digitIndex: number;
  /**
   * Регулярное выражение для цифр, точки и дефиса
   *
   * @type {RegExp}
   */
  private regex = new RegExp(/[\D\.\-/]/);
  /**
   * Спец клавиши
   *
   * @type {Array<string>}
   */
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowRight',
    'ArrowLeft',
    'Del',
    'Delete',
    'Shift'
  ];

  constructor(private el: ElementRef) {}

  /**
   * Обработчик события нажатия клавиши на клавиатуре
   *
   * @description проставляет в элемент значения, только если они соответсвуют реугулярному выражению
   * В случае наличия формы и digitIndex, проставляет в форму валидное значение
   * @param event событие нажатия клавиши на клавиатуре
   */
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (this.specialKeys.includes(event.key)) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    event.preventDefault();
    if (this.regex.test(next)) {
      return;
    }
    this.el.nativeElement.value = event.key;
    if (!this.codeForm || typeof this.digitIndex !== 'number') {
      return;
    }
    const currentCodeVal = this.codeForm.value;
    currentCodeVal[this.digitIndex] = parseInt(event.key, 10);
    this.codeForm.setValue(currentCodeVal);
  }

  /**
   * Обработчик события отжатия клавиши
   *
   * @description если значение в элементе проставлено, снимает фокус с текущего элемента
   * и переставляет на следующий элемент ввода кода
   * @param event событие отжатия клавиши
   */
  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (
      this.specialKeys.includes(event.key) ||
      !this.el.nativeElement.value.length
    ) {
      return;
    }
    const currentInput = this.el.nativeElement;
    const nextInput = this.el.nativeElement.parentNode.nextSibling.childNodes[0];

    if (nextInput) {
      currentInput.blur();
      nextInput.focus();
    }
  }
}
