import { Directive, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * Директива добавления html-комментариев.
 */
@Directive({
  selector: 'html-comment'
})
export class HtmlCommentDirective implements OnInit {
  /**
   * Комментарий, который будет выведен.
   */
  @Input() comment: string;

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private elementRef: ElementRef
  ) {}

  /**
   * Хук инициализации
   *
   * @description добавляет комментарий в Html страницу перед элементом
   */
  ngOnInit(): void {
    const comment = this._doc.createComment(this.comment);
    this.elementRef.nativeElement.after(comment);
    this.elementRef.nativeElement.remove();
  }
}
