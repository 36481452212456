import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

/**
 * Модуль счетчиков веб-аналитики.
 *
 * @class
 * @classdesc Модуль, необходимый для обеспечения работы
 * счетчиков веб-аналитики. При необходимости функционал данного модуля
 * будет дополнен сервисом/генератором счетчиков/передачей конфигов.
 */
@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class CountersModule {
  private yaCounter;

  constructor(private router: Router) {
    this.yaCounter = (window as any).ym;

    if (!this.yaCounter) {
      return;
    }

    // После перехода на новую страницу сообщаем, что был осуществлен переход
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.yaCounter(89521800, 'hit', location.href);
      });
  }
}
