import { Serializable } from 'src/app/core/models';

export class TaxFreeChequesSort extends Serializable {
  sortOrder: string;
  sortType: string;

  toHttpParams(): any {
    const result = {};

    if (this.sortType) {
      result['sortOrder'] = this.sortOrder;
    }
    if (this.sortType) {
      result['sortType'] = this.sortType;
    }

    return result;
  }
}
