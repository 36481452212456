import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { api } from 'src/app/config/api';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';
import { ConfigurationFile, IOrganizationFeatures } from '@core/models';
import { FeatureSetting } from '@core/models/FeatureSetting';

/**
 * Сервис получения конфигурационных настроек
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Получение конфигурации
   *
   * @returns Наблюдатель за получением конфигурации
   */
  public getConfig(): Observable<any> {
    return this.http.get<any>(this.getConfigPath(), this.httpOptions);
  }

  /**
   * Получение информации об организации
   *
   * @returns Наблюдатель за получением информации об организации
   */
  public getOrganizationFeatures(): Observable<IOrganizationFeatures> {
    return this.http.get<IOrganizationFeatures>(
      this.getOrganizationFeaturesPath()
    );
  }

  /**
   * Обновление конфигурации
   *
   * @param payload параметры конфигурации
   * @returns Наблюдателя за обновлением конфигурации
   */
  updateConfig = (payload: ConfigurationFile): Observable<any> =>
    this.http.put<ConfigurationFile>(
      this.getConfigPath(),
      payload,
      this.httpOptions
    );

  /**
   * Получение Конфига создания чека
   *
   * @returns Наблюдатель за получением конфига создания чека
   */
  public getCreationChequeSetting(): Observable<FeatureSetting> {
    return this.http.get<any>(
      this.getCreationChequeConfigPath(),
      this.httpOptions
    );
  }
  /**
   * Генерация URL для получения конфига
   *
   * @returns URL для получения конфига
   */
  private getConfigPath(): string {
    return environment.baseAccountingApiUrl + api.configuration.config;
  }

  /**
   * Генерация URL для получения конфига создания чека
   *
   * @returns URL для получения конфига создания чека
   */
  private getCreationChequeConfigPath(): string {
    return (
      environment.basePortalApiUrl + api.configuration.creationChequeSetting
    );
  }

  /**
   * Генерация URL для получения информации об организации
   *
   * @returns URL для получения информации об организации
   */
  private getOrganizationFeaturesPath(): string {
    return (
      environment.baseAccountingApiUrl + api.configuration.organizationFeatures
    );
  }
}
