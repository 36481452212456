<div *ngIf="loading" class="widget-loader">
  <span class="inner"></span>
</div>
<div>
  <div class="text-center">
    <img [src]="checkCircle">
  </div>
  <div class="widget-info-block">
    <div localization-key="widget-restore-info" class="text-center widget-description" id="reg-restore-title">{{isPhone? descriptionPhone : descriptionEmail}}
    <span id="registration-data" class="widget-description-bold">{{phoneOrEmail}}</span></div>
  </div>
  <div localization-key="widget-code-description-info" class="text-center widget-centered-decription">Код будет действовать в течении 5 минут. Если Вы не получили код в течении 30 секунд нажмите кнопку «Отправить снова»</div>

  <form [formGroup]="form">
    <div class="form-group phone-code">
      <div
        formArrayName="code"
        *ngFor="let code of formCodeControls?.controls; let i = index">
        <input
          #codeInput
          formControlName="{{ i }}"
          type="number"
          min="0"
          autocomplete="nope"
          appConfirmCodeValidator="true"
          [codeForm]="form.controls.code"
          [digitIndex]="i"
          class="widget-form-control confirm-code"
          [ngClass]="{ 'is-invalid': submitted && f.code.errors }" />
      </div>
      <div *ngIf="submitted && f.code.errors" class="widget-invalid-feedback">
        <div localization-key="widget-incorrect-code-error" *ngIf="f.code.errors.invalid_code">
          {{ getErrorText('widget-incorrect-code-error') }}
        </div>
      </div>
    </div>
    <div class="form-group password-group">
      <input
        #passw
        autocomplete="new-password"
        placeholder="Введите пароль"
        [attr.type]="!showPassword ? 'password' : 'text'"
        formControlName="password"
        id="password-reg"
        class="widget-form-control input-pswd-field"
        [ngClass]="{
          'is-invalid': isDisplayFieldErrors('password') && f?.password.errors
        }"
        (blur)="changeField('password')"
        (focus)="focusField('password')"
        (input)="patternTextField('password')"
        appMobileLabelStyle
        (paste)="onPaste($event, 'password')"
        (keydown.space)="$event.preventDefault()"
        />
      <button
        type="button"
        class="show-pass"
        (click)="toggleShowPassword()">
        <img [src]="
          !showPassword
            ? eyeCrossIcon
            : eyeIcon
        ">
      </button>
      <div
        *ngIf="!f.password.errors"
        class="password-stength-wrapper"
        [ngSwitch]="passwordStrength(f.password)">
        <div *ngSwitchCase="'WEAK'" class="weak-password">
          {{ getErrorText('widget-password-weak') }}
        </div>
        <div *ngSwitchCase="'NORMAL'" class="average-password">
          {{ getErrorText('widget-password-average') }}
        </div>
        <div *ngSwitchCase="'GOOD'" class="strong-password">
          {{ getErrorText('widget-password-strong') }}
        </div>
      </div>
      <div
        *ngIf="isDisplayFieldErrors('password') && f.password.errors"
        class="widget-invalid-feedback">
        <div localization-key="widget-password-error-required" *ngIf="f.password.errors.required">
          {{ getErrorText('widget-enter-password-placeholder') }}
        </div>
        <div localization-key="widget-password-error-minlength" *ngIf="
          f.password.errors.minlength && !f.password.errors.required">
          {{ getPasswordErrorText('widget-password-error-minlength', false) }}
        </div>
        <div localization-key="widget-password-error-maxlength" *ngIf="f.password.errors.maxlength
        && !f.password.errors.required && !f.password.errors.minlength">
          {{ getPasswordErrorText('widget-password-error-maxlength', true) }}
        </div>
        <div localization-key="widget-password-error-forbidden" *ngIf="f.password.errors.forbiddenSymbols
          && !f.password.errors.required && !f.password.errors.maxlength && !f.password.errors.minlength">
          {{ getErrorText('widget-password-error-forbidden') }}
        </div>
      </div>
    </div>
    <div class="form-group password-group">
      <input
        #confirm
        autocomplete="new-password"
        placeholder="Подтвердить пароль"
        type="password"
        [attr.type]="!showConfirmPassword ? 'password' : 'text'"
        formControlName="confirm"
        id="confirm-reg"
        class="widget-form-control input-pswd-field"
        (input)="patternTextField('confirm')"
        [ngClass]="{
            'is-invalid':
              (f.confirm.touched && f.confirm.errors) ||
              (confirm.value > 0 && confirm.value !== passw.value)
          }"
        (blur)="changeField('confirm')"
        (focus)="focusField('confirm')"
        appMobileLabelStyle
        (paste)="onPaste($event, 'confirm')"
        (keydown.space)="$event.preventDefault()"
      />
      <button
        type="button"
        class="show-pass"
        (click)="toggleShowConfirmPassword()">
        <img [src]="
          !showConfirmPassword
            ? eyeCrossIcon
            : eyeIcon
        ">
      </button>
      <div
        *ngIf="!f.password.errors"
        class="password-stength-wrapper"
        [ngSwitch]="passwordStrength(f.password)">
        <div *ngSwitchCase="'WEAK'" class="weak-password">
          {{ getErrorText('widget-password-weak') }}
        </div>
        <div *ngSwitchCase="'NORMAL'" class="average-password">
          {{ getErrorText('widget-password-average') }}
        </div>
        <div *ngSwitchCase="'GOOD'" class="strong-password">
          {{ getErrorText('widget-password-strong') }}
        </div>
      </div>
      <div
        *ngIf="isDisplayFieldErrors('confirm') && f.confirm.errors"
        class="widget-invalid-feedback">
        <div *ngIf="f.confirm.errors.required">
          {{ getErrorText('common-required-error') }}
        </div>
        <div *ngIf="f.confirm.errors.minlength && !f.confirm.errors.required">
          {{ getPasswordErrorText('widget-password-error-minlength', false) }}
        </div>
        <div *ngIf="f.confirm.errors.maxlength && !f.confirm.errors.required && !f.confirm.errors.minlength">
          {{ getPasswordErrorText('widget-password-error-maxlength', true) }}
        </div>
        <div *ngIf="f.confirm.errors.passwordDiff && !f.confirm.errors.required
        && !f.confirm.errors.minlength && !f.confirm.errors.maxlength">
          {{ getErrorText('widget-passwords-not-equal-error') }}
        </div>
        <div *ngIf="f.confirm.errors.forbiddenSymbols && !f.confirm.errors.required
        && !f.confirm.errors.minlength && !f.confirm.errors.maxlength && !f.confirm.errors.passwordDiff">
          {{ getErrorText('widget-password-error-forbidden') }}
        </div>
      </div>
    </div>
    <button
        [disabled]="loading || disabledSubmit"
        [ngClass]="loading || disabledSubmit ? 'disabled' : ''"
        class="widget-btn widget-btn-primary full-width form-group"
        (click)="onSubmit()"
        localization-key="widget-sumbit-btn"
    >
        Подтвердить
    </button>
    <button
      class="widget-btn widget-btn-secondary full-width"
      (click)="sendAgain()"
      [hidden]="widgetCounter > 0"
      localization-key="widget-send-again-btn"
      >
      Отправить код повторно
    </button>
  </form>
  <div class="text-center widget-counter" *ngIf="widgetCounter > 0">{{ widgetCounter }} sec</div>
  <div localization-key="widget-login-link" id="widget-verify-info" class="text-center widget-secondary-description">
    <span>Подробная информация на сайте </span>
    <a href="https://digitaxfree.ru" target="_blank" class="widget-bold-link-10">TaxFree</a>
  </div>
</div>
