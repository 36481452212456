import { Directive } from '@angular/core';
import { AbstractCaptchaComponent } from './AbstractCaptchaComponent';
/**
 * Абстрактный класс с полем ввода для телефона
 */
@Directive()
export abstract class AbstractPhoneMaskComponent extends AbstractCaptchaComponent {
  /**
   * Маска для телефона
   *
   * @type {string}
   */
  mask: string = null;

  constructor() {
    super();
  }

  /**
   * Получение маски и простановка ошибки
   *
   * @param value введенное значение
   * @param fieldName наименование поля
   */
  checkMaskAndSetError(value: string, fieldName: string) {
    const masks = this.getPhoneNumberMasks(value);

    this.mask = masks.length === 1 ? masks[0] : '+0*';
    if (this.mask === '+0*') {
      this.f[fieldName].setErrors({ mask: true });
    }
  }

  /**
   * Простановка ошибки если номер начинается не с "+"
   *
   * @param fieldName наименование поля
   */
  setInvalidPhoneStart(fieldName: string) {
    setTimeout(() => {
      this.f[fieldName].setErrors({ plusError: true });
    }, 1);
    this.mask = '+0*';
  }
}
