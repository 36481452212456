import { Serializable } from 'src/app/core/models/Serializable';

export class TaxFreeChequesStat extends Serializable {
  costSum: number;
  taxRefundSum: number;
  paidTaxRefundSum: number;

  constructor(json?: any) {
    super(json);
  }
}
