export class Country {
  id: string;
  active: boolean;
  code: string;
  name?: string;
  shortName: string;
  fullName?: string;
  codeAlpha2: string;
  codeAlpha3: string;
  localizedNames?: any[];
  eeu: boolean;

  constructor(rawData?: Country) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
