import { ValidatorFn, AbstractControl } from '@angular/forms';
import { isNil } from 'lodash';
import { auth } from './auth';
import { isPhone } from './isPhone';


/**
 * Валидатор поля ввода e-mail / номера телефона
 *
 * @returns функцию, которая принимает валидируемый контрол и возвращает ошибки валидации
 * или null, если ошибок нет
 */
export function emailAndPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emailRegExp = new RegExp(
      '^(([^«»‹›‘’\'♦№<>(){}\\[\\]\\\\.,;:%$!#=?\\s\\/\\|+*&\\^@"\u0080-\u2009\u2011-\u2211\u2213-\uFFFF`]+' +
        '(\\.[^«»‹›‘’\'♦№<>()\\[\\]\\\\.,;:%$!#=\\s\\/\\|+*&\\^@"\u0080-\u2010\u2011-\u2211\u2213-\uFFFF`]+)*)' +
        '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    );
    const value = control.value;
    if (isNil(value)) {
      return;
    }
    // Если в логине нет букв, то предположим, что это номер телефона и попросим ввести +
    const phoneRegExp = /\D/;
    if (!phoneRegExp.test(value) && value.length > 0) {
      return { phoneStart: { value } };
    }
    if (isPhone(value.replace(/\s/g, ''))) {
      const valueWithoutSpaces = value.replace(/\s/g, '');
      if (valueWithoutSpaces.length > auth.phoneMaxLength) {
        return { phoneMaxLength: { value: control.value } };
      }
      if (valueWithoutSpaces.length < auth.phoneMinLength) {
        return { phoneMinLength: { value: control.value } };
      }
      return control.errors && control.errors.mask ? control.errors : null;
    }
    if (!value) {
      return { required: { value } };
    }
    if (value.length > auth.emailMaxLength) {
      return { emailMaxLength: { value } };
    }
    if (!emailRegExp.test(value)) {
      return { emailIncorrect: { value } };
    }
  };
}
