export class TradingOrganization {
  id?: string;
  logo?: string;
  name: string;
  inn: string;
  address: {
    index: string;
    region: string;
    locality: string;
    street: string;
    facilityName: string;
  };
  isVisibleOnPortal: boolean;
  paymentAddress: string[];
  url: string;
  formattedUrl;

  constructor(rawData?: TradingOrganization) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
