import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { assign } from 'lodash';

import { environment } from '../../../environments/environment';
import { api } from '../../config/api';
import { TaxFreeRules } from '../models';
import { AuthService } from './auth.service';
import { Paginable } from '../classes';
import { TaxFreeRulesFilter, TaxFreeRulesSort } from '../models/TaxFreeRules';

/**
 * Сервис для работы с правилами TaxFree
 */
@Injectable({
  providedIn: 'root'
})
export class TaxFreeRulesService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Получение правил TaxFree
   *
   * @returns Наблюдателя за получением правил TaxFree
   */
  public obtainRules(): Observable<TaxFreeRules> {
    return this.http.get<TaxFreeRules>(
      this.getObtainRulesPath(),
      this.authService.getHttpOptionsByAuthState()
    );
  }

  /**
   * Получение всех правил TaxFree в ЛК администратора
   *
   * @param sort сортировка
   * @param filter фильтр
   * @returns Наблюдателя за получением всех правил TaxFree в ЛК администратора
   */
  public obtainAllRules(
    sort: TaxFreeRulesSort,
    filter: TaxFreeRulesFilter
  ): Observable<Paginable> {
    const params = assign(sort.toHttpParams(), filter.toHttpParams());
    const opts = Object.assign(this.authService.getHttpOptionsByAuthState(), {
      params
    });
    return this.http.get<Paginable>(this.getObtainPagedRulesPath(), opts).pipe(
      map((response: Paginable) => {
        const result = [];
        for (const i in response.elements) {
          if (response.elements.hasOwnProperty(i)) {
            result.push(new TaxFreeRules(response.elements[i]));
          }
        }
        response.elements = result;
        return response;
      })
    );
  }

  /**
   * Создание правила
   *
   * @param payload данные правила
   * @returns наблюдателя за созданием правила
   */
  public createRules(payload: TaxFreeRules): Observable<TaxFreeRules> {
    return this.http.post<TaxFreeRules>(
      this.getObtainRulesPath(),
      payload,
      this.authService.getHttpOptionsByAuthState()
    );
  }

  /**
   * Обновление правила TaxFree
   *
   * @param ruleId id правила
   * @param payload данные правила
   * @returns наблюдателя за обновлением правила TaxFree
   */
  public updateRule(
    ruleId: string,
    payload: TaxFreeRules
  ): Observable<TaxFreeRules> {
    return this.http.put<TaxFreeRules>(
      this.getRuleByIdPath(ruleId),
      payload,
      this.authService.getHttpOptionsByAuthState()
    );
  }

  /**
   * Удаление правила TaxFree
   *
   * @param ruleId id правила
   * @param payload данные правила
   * @returns наблюдателя за удалением правила TaxFree
   */
  public deleteRule(ruleId: string): Observable<any> {
    return this.http.delete(
      this.getRuleByIdPath(ruleId),
      this.authService.getHttpOptionsByAuthState()
    );
  }
  /**
   * Генерация URL для получения правила
   *
   * @returns URL для получения правила
   */
  private getObtainRulesPath(): string {
    return environment.baseAccountingApiUrl + api.taxFreeRules.taxFreeRules;
  }
  /**
   * Генерация URL для работы с правилом по ID
   *
   * @param ruleId id правила
   * @returns URL для работы с правилом по ID
   */
  private getRuleByIdPath(ruleId: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.taxFreeRules.taxFreeRulesById.replace('{id}', ruleId)
    );
  }
  /**
   * Генерация URL для получения списка правил для админа
   *
   * @returns URL для получения списка правил для админа
   */
  private getObtainPagedRulesPath(): string {
    return (
      environment.baseAccountingApiUrl + api.taxFreeRules.pagedTaxFreeRules
    );
  }
}
