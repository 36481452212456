export const feedBackForm = {
  email: {
    maxLength: 256
  },
  name: {
    maxLength: 255
  },
  description: {
    maxLength: 3000
  }
};
