<div *ngIf="loading" class="widget-loader">
  <span class="inner"></span>
</div>
<div>
  <form [formGroup]="form">
    <div localization-key="widget-title" id="widget-login-header" class="widget-title">Приступите к покупкам уже сейчас!</div>
    <div class="widget-checkbox-wrapper">
      <div class="form-group widget-checkbox-group">
        <input class="widget-checkbox-input" id="confirm" type="checkbox" formControlName="confirm" />
        <label localization-key="widget-checkbox-label-use-service" for="confirm"
          class="widget-checkbox-label">Воспользоваться сервисом <strong>Tax Free</strong></label>
      </div>
      <span class="flare"></span>
    </div>
    <div class="widget-centered-decription">
      <span id="widget-login-prompt" localization-key="widget-login-prompt" style="width: 100%;">
        Заполните поля ниже для получения максимальной выгоды с сервисом TaxFree от Digitax!
      </span>
    </div>
    <div class="form-group">
      <input
        #username
        type="text"
        formControlName="username"
        id="username"
        autocomplete="off"
        class="widget-form-control"
        placeholder="Email \ Номер телефона"
        [ngClass]="{
          'is-invalid': (isDisplayFieldErrors('username') && f.username.errors)
          || (form?.errors && form?.errors['invalid_creds'])
        }"
        (blur)="changeField('username')"
        (focus)="focusField('username')"
        (keydown.space)="$event.preventDefault()"
        (input)="onKey()"
        (paste)="onPaste($event, 'username')"
        mask="{{ mask ? mask : '' }}"
        [dropSpecialCharacters]="false"
        appMobileLabelStyle
        />
      <div
        *ngIf="isDisplayFieldErrors('username') && f.username.errors"
        class="widget-invalid-feedback">
        <div localization-key="widget-username-error-required" *ngIf="f.username.errors.required">
          {{ getErrorText('widget-username-error-required') }}
        </div>
        <div localization-key="widget-username-error-email-format" *ngIf="f.username.errors.emailMaxLength">
          {{ getEmailErrorText('widget-username-error-email-format') }}
        </div>
        <div localization-key="widget-username-error-format" *ngIf="f.username.errors.phoneMaxLength || f.username.errors.phoneMinLength || f.username.errors['mask']">
          {{ getErrorText('widget-username-error-format') }}
        </div>
        <div localization-key="widget-username-error-emailIncorrect" *ngIf="f.username.errors.emailIncorrect">
          {{ getErrorText('widget-username-error-emailIncorrect') }}
        </div>
        <div
          localization-key="widget-username-error-phoneStart"
          *ngIf="f.username.errors.phoneStart"
        >
        {{ getErrorText('widget-username-error-phoneStart') }}
        </div>
      </div>
    </div>
    <div class="form-group password-group">
      <input
        #passw
        autocomplete="new-password"
        placeholder="Пароль"
        [attr.type]="!showPassword ? 'password' : 'text'"
        formControlName="password"
        id="password"
        class="widget-form-control input-pswd-field"
        [ngClass]="{
          'is-invalid': (isDisplayFieldErrors('password') && f?.password.errors)
          || (form?.errors && form?.errors['invalid_creds'])
        }"
        (blur)="changeField('password')"
        (focus)="focusField('password')"
        (input)="patternTextField('password')"
        appMobileLabelStyle
        (keydown.space)="$event.preventDefault()"
        (paste)="onPaste($event, 'password')"
        />
      <button
        type="button"
        class="show-pass"
        (click)="toggleShowPassword()">
        <img [src]="
          !showPassword
            ? eyeCrossIcon
            : eyeIcon
        ">
      </button>
      <div
        *ngIf="isDisplayFieldErrors('password') && f.password.errors"
        class="widget-invalid-feedback">
        <div localization-key="widget-password-error-required" *ngIf="f.password.errors.required">
          {{ getErrorText('widget-password-error-required') }}
        </div>
        <div localization-key="widget-password-error-minlength" *ngIf="f.password.errors.minlength">
          {{getPasswordErrorText('widget-password-error-minlength', false)}}
        </div>
        <div localization-key="widget-password-error-maxlength" *ngIf="f.password.errors.maxlength">
          {{getPasswordErrorText('widget-password-error-maxlength', true)}}
        </div>
        <div localization-key="widget-password-error-forbidden" *ngIf="f.password.errors.disableCyrillicInput">
          {{ getErrorText('widget-password-error-forbidden') }}
        </div>
      </div>
    </div>
    <div localization-key="widget-password-error-invalid-creds" class="widget-invalid-feedback-form" *ngIf="form?.errors && form?.errors['invalid_creds']">
      {{ getErrorText('widget-password-error-invalid-creds') }}
    </div>
    <div class="form-group text-right">
      <a localization-key="widget-reset-link" (click)="triggerRetoreEvent()" class="reset_pass">Забыли пароль?</a>
    </div>
    <button
        [disabled]="loading || disabledSubmit"
        [ngClass]="loading || disabledSubmit ? 'disabled' : ''"
        class="widget-btn widget-btn-primary full-width"
        (click)="onSubmit()"
        localization-key="widget-login-btn"
        >
        Войти
    </button>

  </form>
  <div class="widget-register-link">
    <a localization-key="widget-reg-btn" (click)="triggerExpressRegistration()">Не зарегистрированы?</a>
  </div>
  <div localization-key="widget-login-footer" id="widget-login-footer" class="widget-footer-text">* Возврат доступен только для нерезидентов РФ</div>
</div>
