import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { isNil } from 'lodash';

import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { Paginable } from '../classes';
import { OfferFilter, OfferSort } from '../models/offer';
import { AuthService } from './auth.service';

/**
 * Сервис для работы с предложениями
 */
@Injectable({
  providedIn: 'root'
})
export class OffersService {
  /**
   * Http Опции
   */
  httpOptions = this.authService.isLoggedIn()
    ? {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Skip-Interceptor': '',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      })
    }
    : {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Skip-Interceptor': ''
      })
    };

  constructor(
    private http: HttpClient, // @Inject('ORIGIN_URL') public baseUrl: string,
    private authService: AuthService
  ) {}

  /**
   * Получение предложений
   *
   * @param page данные пагинаци
   * @param sort сортировка
   * @param filter фильтр
   * @param isOperator признак оператора
   * @returns Наблюдателя за получением списка предложений
   */
  obtainOffers(
    sort: OfferSort,
    filter: OfferFilter,
    isOperator: boolean,
    page: number = 1
  ): Observable<Paginable> {
    const tradeNetworkIds =
      filter && filter.tradeNetworkIds
        ? filter.tradeNetworkIds.map(tn => tn.id)
        : null;
    let queryString = '?';
    queryString += `page=${page.toString()}`;

    if (sort.sortBy) {
      queryString += `&sortBy=${sort.sortBy}`;
    }
    if (sort.sortDir) {
      queryString += `&sortDir=${sort.sortDir}`;
    }
    if (!isNil(tradeNetworkIds) && tradeNetworkIds.length) {
      tradeNetworkIds.forEach(id => {
        queryString += `&tradeNetworkIds=${id}`;
      });
    }
    if (!isNil(filter) && filter.active) {
      queryString += `&active=${filter.active}`;
    }
    return this.http.get<Paginable>(
      this.getOffersPath(isOperator) + queryString,
      this.httpOptions
    );
  }

  /**
   * Генерация URL для загрузки предложений в зависимости от роли
   *
   * @param isOperator Признак роли оператора
   * @returns URL для загрузки предложений
   */
  private getOffersPath(isOperator = false): string {
    const offersPath = isOperator ? api.operator.offers : api.offers.offers;
    return environment.basePortalApiUrl + offersPath;
  }
}
