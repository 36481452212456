import { Injectable } from '@angular/core';
import { TaxFreeChequesFilter } from 'src/app/core/models/TaxFreeCheque';
import {
  ALL_STATUSES,
  TAX_FREE_CHEQUES_FILTER_ID
} from 'src/app/core/constants';
import { getDataStartByPeriod, getDataEndByPeriod } from 'src/app/utility/date';

/**
 * Сервис хранения значения фильтра для списка чеков в лк покупателя
 */
@Injectable({
  providedIn: 'root'
})
export class TaxFreeChequesFilterService {
  /**
   * Сохранение значения статуса в хранилище
   *
   * @param status статус чека
   */
  public addFilteringByStatus(status: string): void {
    const filter = this.getStoredFilter();
    filter.status = status;
    this.storeFilter(filter);
  }

  /**
   * Сохранение значения интервала в хранилище
   *
   * @param period интервал
   */
  public addFilteringByPeriod(period: string): void {
    const filter = this.getStoredFilter();
    filter.period = period;
    this.storeFilter(filter);
  }

  /**
   * Получение значения фильтра из хранилища
   *
   * @description если в хранилище содержится фильтр, генерирует объект фильтра по значениям из хранилища,
   * иначе возврвщает пустой объект
   * @returns значение фильтра из хранилища
   */
  public getStoredFilter(): TaxFreeChequesFilter {
    const storedFilter = localStorage.getItem(this.getFilterIdentifier());

    if (storedFilter) {
      const issuedSinceDate = new Date();
      const issuedTillDate = new Date(+issuedSinceDate);
      const parsedStoredFilter = JSON.parse(storedFilter);

      const issuedSince = getDataStartByPeriod(
        parsedStoredFilter.period,
        issuedSinceDate
      );
      const issuedTill = getDataEndByPeriod(
        parsedStoredFilter.period,
        issuedTillDate
      );

      return new TaxFreeChequesFilter({
        status: parsedStoredFilter.status ? parsedStoredFilter.status : null,
        issuedSince: issuedSince ? issuedSince : null,
        issuedTill: issuedTill ? issuedTill : null,
        period: parsedStoredFilter.period ? parsedStoredFilter.period : null
      });
    }
    return new TaxFreeChequesFilter();
  }

  /**
   * Простановка и сохранение фильтра по умолчанию
   */
  public setDefaultFiltering() {
    const filter = this.getStoredFilter();
    if (!filter.period) {
      filter.period = this.getAllTimePeriodValue();
    }
    if (!filter.status) {
      filter.status = this.getAllStatusesValue();
    }
    this.storeFilter(filter);
  }

  /**
   * Очистка фильтра в хранилище
   */
  public cleanAllFiltering() {
    localStorage.removeItem(this.getFilterIdentifier());
  }

  /**
   * Сохранение фильтра в хранилище
   *
   * @param taxFreeChequesFilter фильтр чеков
   */
  private storeFilter(taxFreeChequesFilter: TaxFreeChequesFilter): void {
    localStorage.setItem(
      this.getFilterIdentifier(),
      JSON.stringify({
        status: taxFreeChequesFilter.status,
        period: taxFreeChequesFilter.period
      })
    );
  }

  /**
   * Получение идентификатора фильтра в хранилище
   *
   * @returns идентификатор фильтра в хранилище
   */
  private getFilterIdentifier(): string {
    return TAX_FREE_CHEQUES_FILTER_ID;
  }

  /**
   * Значение периода по умолчанию
   *
   * @returns Значение периода по умолчанию
   */
  private getAllTimePeriodValue(): string {
    return 'FOR_ALL_TIME';
  }

  /**
   * Значение статуса по умолчанию
   *
   * @returns Значение статуса по умолчанию
   */
  private getAllStatusesValue(): string {
    return ALL_STATUSES;
  }
}
