import { Serializable } from './Serializable';

export class CashRegister extends Serializable {
  endDate: string;
  startDate: string;
  fiscalStorageNumber: string;
  id: string;
  manufacturer: string;
  model: string;
  registrationNumber: string;
  organizationId: string;
}
