import { Passport } from './Passport';
import { Contacts } from './Contacts';
import { PaymentOption } from './PaymentOption';
import { AccountSettings } from './AccountSettings';

export class Customer {
  countryCode?: string;
  passport?: Passport;
  contact?: Contacts;
  paymentDetails?: PaymentOption[];
  accountSettings?: AccountSettings;
  referralCode?: boolean;
  name?: string;
  surname?: string;
  userId?: string;
  id?: string;
  toDelete?: boolean;

  constructor(rawData?: Customer) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
