import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { assign } from 'lodash';

import { environment } from '../../../environments/environment';
import { api } from '../../config/api';
import { Paginable } from '../classes';
import { AuthService } from './auth.service';
import { PaymentOrder } from '../models';
import { PaymentFilter } from '../models/PaymentOrders/PaymentFilter';
import { PaymentSort } from '../models/PaymentOrders/PaymentSort';
import { map } from 'rxjs/operators';

/**
 * Сервис для работы с платежными поручениями
 */
@Injectable({
  providedIn: 'root'
})
export class MoneyOrdersService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Скачивание платежных поручений по дате
   *
   * @param filter фильтр с датой
   * @returns Наблюдателя за скачиванием платежных поручений
   */
  getPaymentOrdersByDate(filter: string): Observable<any> {
    return this.http
      .post<any>(
        this.getDownloadOrderReport(),
        { filter },
        this.getBlobHttpOptions()
    )
      .pipe(
        map(res => {
          let data = null;
          if (res) {
            data = res;
          }
          return data;
        })
      );
  }

  /**
   * Получение списка платежных поручений
   *
   * @param filter фильтр
   * @param sort сортировка
   * @returns Наблюдателя за получением списка платежных поручением
   */
  public getPaymentOrders(
    filter: PaymentFilter,
    sort: PaymentSort
  ): Observable<Paginable> {
    const params = assign(filter.toHttpParams(), sort.toHttpParams());
    const options = {
      headers: null,
      params
    };
    Object.assign(options, this.httpOptions);
    return new Observable(observer => {
      this.http.get<Paginable>(this.getOrdersPath(), options).subscribe(res => {
        if (res) {
          res.elements = res.elements.map(item => new PaymentOrder(item));
        }
        observer.next(res);
      });
    });
  }

  /**
   * Отправка запроса на повторение платежного поручения
   *
   * @param id id поручения
   * @returns Наблюдателя за отправкой запроса
   */
  public retryPay(id: string): Observable<Paginable> {
    return this.http.get<Paginable>(this.getRetryPath(id), this.httpOptions);
  }

  /**
   * Получение Http Опций для запроса Blob
   *
   * @returns http опции для запроса Blob
   */
  private getBlobHttpOptions() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }),
      responseType: 'blob' as 'json'
    };
  }

  /**
   * Генерация URL для скачивания платежных поручений
   *
   * @returns URL для скачивания платежных поручений
   */
  private getDownloadOrderReport() {
    return environment.baseReportingApiUrl + api.report.paymentOrders;
  }

  /**
   * Генерация URL для получения списка платежных поручений
   *
   * @returns URL для получения списка платежных поручений
   */
  private getOrdersPath(): string {
    return environment.baseAccountingApiUrl + api.paymentOrders.getOrders;
  }

  /**
   * Генерация URL для повторной отправки запроса на повторение платежного поручения
   *
   * @param id id поручения
   * @returns URL для повторной отправки запроса на повторение платежного поручения
   */
  private getRetryPath(id: string): string {
    return environment.baseAccountingApiUrl + api.paymentOrders.retry + id;
  }
}
