<form class="script-block" [formGroup]="form">
  <div _ngcontent-serverapp-c326="" class="title-area">Скрипт для сбора не размеченных данных</div>
  <div class="input-group">
    <textarea 
      placeholder="Введите функцию для сбора не размеченных данных"
      class="md-textarea"
      rows="5"
      formControlName="code"
    ></textarea>
    
  </div>
  <div class="description">
    Скрипт должен содержать функцию с названием getShopData и пустым списком параметров
  </div>
  <div class="description">
    Вы можете не заполнять текущее поле, чтобы не применять скрипт для сбора не размеченных данных
  </div>
</form>
