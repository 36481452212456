import { Passport } from '../Passport';
import { Contacts } from '../Contacts';
import { PaymentOption } from '@core/models';

/**
 * Модель покупателя
 */
export class Buyer {
  /**
   * Паспорт
   *
   * @type {Passport}
   */
  passport?: Passport;
  /**
   * Контакты
   *
   * @type {Contacts}
   */
  contact?: Contacts;
  /**
   * Платёжные данные
   *
   * @type {PaymentOption[]}
   */
  paymentDetails?: PaymentOption[];
  /**
   * Полное имя
   *
   * @type {string}
   */
  fullName?: string = '';
  /**
   * Комментарий
   *
   * @type {string}
   */
  comment?: string = '';

  constructor(rawData?: Buyer) {
    if (rawData) {
      Object.assign(this, rawData);
      this.setFullName();
      this.setContacts();
      this.setDob();
      this.setPaymentDetails();
    }
  }

  /**
   * Установка полного имени
   */
  private setFullName(): void {
    if (
      !this.passport?.name &&
      !this.passport?.patronymic &&
      !this.passport?.surname
    ) {
      this.fullName = 'Нет данных';
      return;
    }
    const { surname, name, patronymic } = this.passport;
    this.fullName = [surname, name, patronymic].filter(x => x).join(' ');
  }

  /**
   * Установка даты рождения
   */
  private setDob(): void {
    if (!this.passport) {
      this.passport = new Passport();
    }
    if (!this.passport.dateOfBirth) {
      this.passport.dateOfBirth = null;
    }
  }

  /**
   * Установка платёжных данных
   */
  private setPaymentDetails(): void {
    if (!this.paymentDetails) {
      this.paymentDetails = [];
    }
  }

  /**
   * Установка контактов
   */
  private setContacts(): void {
    if (!this.contact) {
      this.contact = new Contacts();
    }
  }
}
