<div class="container">
  <div class="row">
    <div class="col-12 confirmation-wrapper">
      <div *ngIf="error" class="alert alert-danger noty-message">
        {{ backendError }}
      </div>
      <div *ngIf="success" class="success-message-wrapper">
        <div class="updated-message">
          <h4 class="not-found-texts">Email successfully updated!</h4>
          <p class="uptitle">
            Please, follow <a routerLink="/profile">your profile</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
