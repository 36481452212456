import {
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Directive,
  OnDestroy,
  OnInit
} from '@angular/core';
import { WebcamUtil } from 'ngx-webcam';
import { interval, Subscription } from 'rxjs';
import { AbstractComponent } from './AbstractComponent';
const source = interval(500);
const resolvedPromise = Promise.resolve();
/**
 * Абстрактный класс с использованием камеры
 */
@Directive()
export abstract class AbstractDeviceComponent extends AbstractComponent
  implements OnInit, OnDestroy {
  /**
   * Эмиттер события обнаружения камер на устр-ве
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() hasDevicesEmit = new EventEmitter<boolean>();
  /**
   * Эмиттер события отсутсвия камер на устр-ве
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() hasNoDevicesEmit = new EventEmitter<boolean>();
  /**
   * Признак наличия камер на устр-ве
   *
   * @type {boolean}
   */
  hasDevices = false;
  /**
   * Признак, что на устр-ве есть несколько камер
   *
   * @type {boolean}
   */
  hasMultipleDevices: boolean;
  /**
   * Текущая активная камера
   *
   * @type {MediaDeviceInfo | null}
   */
  currentDevice: MediaDeviceInfo | null = null;
  /**
   * Список камер
   *
   * @type {MediaDeviceInfo[]}
   */
  devices: MediaDeviceInfo[];
  /**
   * Основная камера
   *
   * @type {MediaDeviceInfo | null}
   */
  backDevice: MediaDeviceInfo | null;
  /**
   * Фронтальная камера
   *
   * @type {MediaDeviceInfo | null}
   */
  frontDevice: MediaDeviceInfo | null;
  /**
   * Признак отсутствия камер на устр-ве
   *
   * @type {boolean}
   */
  hasNotDevices = false;
  /**
   * Признак первой инициализации
   *
   * @type {boolean}
   */
  firstInit = true;
  /**
   * Функция для переключения на следующую камеру
   *
   * @type {Function}
   */
  showNextWebcam: (deviceId: string) => void;
  /**
   * Подписка на интервал проверки разрешения на использование камеры
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Признак загрузки
   *
   * @type {boolean}
   */
  loading: boolean;
  /**
   * Активные видео потоки
   *
   * @type {any[]}
   */
  tracks: any[];

  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    // периодически проверяем не появилось ли разрешение на использование камеры
    this.subscription = source.subscribe(() => {
      if (this.hasNotDevices) {
        this.notFoundPermissionHandler();
        return;
      }
      this.checkAvailableCameras();
    });
  }

  /**
   * Проверка разрешений когда флаг отсутсвия камер проставлен в true
   *
   * @description Пытаемся достать доступные устройства, если разрешение появится
   * и устр-ва будут получены, запускает инициализацию камеры,
   * иначе при получении ошибки сбрасывает признак загрузки, проставляет признак отсутствия камеры
   * и отправляет событие, что камера не найдена
   */
  notFoundPermissionHandler() {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(res => {
        this.unsubscribe();
        if (this.firstInit) {
          this.firstInit = false;
          WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
              this.cameraInit(mediaDevices);
              this.hasDevicesEmit.emit(this.hasDevices);
            }
          );
        }
        this.tracks = res.getVideoTracks();
      })
      .catch(() => {
        this.hasNotDevices = true;
        this.loading = false;
        this.hasNoDevicesEmit.emit(this.hasNotDevices);
      });
  }

  /**
   * Проверка наличия камер
   *
   * @description Пока не проставлен флаг наличия камер, получаем доступные устройства
   * Если список устройств пуст или не заполнен label с именем устройства,
   * то проставляем флаг, что устройства не найдены,
   * Иначе инициализируем камеру.
   * Если при следующем опросе уже проставлен флаг наличия камер,
   * то отписываемся от периодического опроса устройств
   */
  checkAvailableCameras() {
    if (this.hasDevices) {
      this.unsubscribe();
      return;
    }
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        if (!mediaDevices || mediaDevices[0].label === '') {
          this.loading = false;
          this.hasNotDevices = true;
          this.hasNoDevicesEmit.emit(this.hasNotDevices);
          return;
        }
        this.cameraInit(mediaDevices);
        this.hasDevicesEmit.emit(this.hasDevices);
        this.unsubscribe();
      }
    );
  }

  /**
   * Хук финализации
   */
  ngOnDestroy() {
    this.tracks.forEach(track => {
      track.stop();
    });
    this.unsubscribe();
  }

  /**
   * Отписка от периодического запроса доступных устройств
   */
  unsubscribe(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Инициализация камер
   *
   * @param mediaDevices Медиа устройства
   */
  cameraInit(mediaDevices: MediaDeviceInfo[]) {
    if (!mediaDevices.length) {
      this.setHasNotDevices();
      return;
    }
    this.hasDevices = true;
    this.hasNotDevices = false;
    this.hasMultipleDevices = mediaDevices && mediaDevices.length > 1;
    this.devices = mediaDevices;
    this.searchBackDevice(mediaDevices);
    // если устройствам не дано разрешений
    this.checkPermissions();
    // триггерим простановку устройства
    if (this.showNextWebcam) {
      this.showNextWebcam(this.currentDevice.deviceId);
    }
    // ищем фонтальную камеры
    this.frontDevice = mediaDevices.find(device =>
      /front|user|Камера на передней панели/gi.test(device.label)
    );
    this.cdr.detectChanges();
  }

  /**
   * Поиск основной камеры
   *
   * @description Выполняет поиск основного устройства по наличию признака в названии
   * При наличии нескольких камер на задней панели телефона, ищет устройство с индексом 0.
   * Проставляет основную камеру в параметр и устанавливает её текущей
   * @param mediaDevices медиа устройства
   */
  searchBackDevice(mediaDevices: MediaDeviceInfo[]) {
    const backDevices = mediaDevices.filter(device =>
      /back|rear|environment|Камера на задней панели/gi.test(device.label)
    );
    if (!backDevices.length) {
      this.currentDevice = mediaDevices[0];
      return;
    }
    const backDeviceMain = backDevices.find(device => /0/gi.test(device.label));
    this.backDevice = backDeviceMain || backDevices[0];
    this.currentDevice = this.backDevice;
  }

  /**
   * Дополнительная проверка разрешений в текущем устройстве
   *
   * @description если лейбл не заполнен, пуст или содержит текст "no permission",
   * то проставляем флаги отсутствия камер и триггерим событие
   */
  checkPermissions() {
    if (
      !this.currentDevice.label ||
      this.currentDevice.label === '' ||
      this.currentDevice.label.includes('no permission')
    ) {
      this.setHasNotDevices();
    }
  }

  /**
   * Проставление флагов отсутствия камер и триггер события
   */
  setHasNotDevices() {
    this.hasNotDevices = true;
    this.hasNoDevicesEmit.emit(this.hasNotDevices);
    this.hasDevices = false;
  }

  /**
   * Переключение камеры
   *
   * @description если определены основная и фронтальная камеры, то переключение выполняется только между ними
   * Если нет, то включается следующая за текущим устройством из массива устройств
   */
  nextCamera() {
    if (this.backDevice && this.frontDevice) {
      this.switchBackOrFront();
      this.cdr.detectChanges();
      return;
    }
    const index = this.devices.findIndex(item => item === this.currentDevice);
    if (index !== -1) {
      this.currentDevice = this.devices[
        index < this.devices.length - 1 ? index + 1 : 0
      ];
      if (this.showNextWebcam) {
        this.showNextWebcam(this.currentDevice.deviceId);
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Переключение между фронтальной и основной камерой
   *
   * @description если текущей устр-во совпадает с основной камерой, то переключает на фронтальную и наоборот
   */
  switchBackOrFront() {
    this.hasDevices = false;
    this.currentDevice =
      this.currentDevice?.deviceId === this.backDevice.deviceId
        ? this.frontDevice
        : this.backDevice;
    if (this.showNextWebcam) {
      this.showNextWebcam(this.currentDevice.deviceId);
    }
    resolvedPromise.then(() => {
      this.hasDevices = true;
    });
  }
}
