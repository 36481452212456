<div *ngIf="saveShopInfo" class="loader" [class.mobile]="isTablet">
  <span class="inner"></span>
</div>
<div *ngIf="!saveShopInfo">
  <form
    [formGroup]="form">
    <div>
      <div *ngIf="!isAdmin" class="form-group">
        <label for="oport">Выберите ОПОРТ*</label>
        <div class="input-group">
          <ng-select
            appMobileLabelStyle
            class="w-100"
            id="oport"
            [clearable]="true"
            [searchable]="false"
            bindLabel="name"
            bindValue="id"
            placeholder="Выберите ОПОРТ"
            formControlName="oport"
            (focus)="focusInternalField('oport')"
            (close)="changeField('oport')"
            [items]="oports"
            [ngClass]="{
              'is-invalid':
                f.oport?.errors &&
                isDisplayFieldErrors('oport')
            }"
          >
          </ng-select>
          <div
            *ngIf="isDisplayFieldErrors('oport') && f.oport?.errors"
            class="invalid-feedback">
            <div *ngIf="f.oport?.errors.required">
              Выберите ОПОРТ
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="shopName"><ng-container><br/></ng-container>Наименование*</label>
          <input
            #shopName
            type="text"
            formControlName="shopName"
            id="shopName"
            autocomplete="off"
            class="form-control"
            placeholder="Введите наименование"
            [ngClass]="{
              'is-invalid': (isDisplayFieldErrors('shopName') && f.shopName.errors)
              || (form?.errors && form?.errors['invalid_creds'])
            }"
            (blur)="changeField('shopName')"
            (focus)="focusField('shopName')"
            appMobileLabelStyle
            />
          <div
            *ngIf="isDisplayFieldErrors('shopName') && f.shopName.errors"
            class="invalid-feedback">
            <div *ngIf="f.shopName.errors.required">
              Введите наименование ИМ
            </div>
          </div>
        </div>
        <div class="form-group p-top-9">
          <label for="shopUrl"><ng-container><br/></ng-container>Адрес сайта*</label>
          <input
            #shopUrl
            type="text"
            formControlName="shopUrl"
            id="shopUrl"
            autocomplete="off"
            class="form-control"
            placeholder="Введите адрес сайта"
            [ngClass]="{
              'is-invalid': (isDisplayFieldErrors('shopUrl') && f.shopUrl?.errors)
              || (form?.errors && form?.errors['invalid_creds'])
            }"
            (blur)="changeField('shopUrl')"
            (focus)="focusField('shopUrl')"
            (paste)="onPaste($event, 'shopUrl')"
            appMobileLabelStyle
            />
            <div class="description">Пример: https://www.bum.ru/</div>
          <div
            *ngIf="isDisplayFieldErrors('shopUrl') && f.shopUrl?.errors"
            class="invalid-feedback">
            <div *ngIf="f.shopUrl?.errors.required">
              Введите Адрес сайта
            </div>
            <div *ngIf="f.shopUrl?.errors.urlIncorrect">
                Проверьте адрес сайта интернет-магазина
            </div>
          </div>
        </div>

      </div>
      <div class="col-6">
        <div class="form-group p-top-14">
          <label for="email">E-mail*</label>
          <div class="input-group">
            <input
              #email
              type="text"
              formControlName="email"
              id="email"
              autocomplete="off"
              class="form-control"
              placeholder="Введите E-mail"
              [ngClass]="{
                'is-invalid': (isDisplayFieldErrors('email') && f.email?.errors)
                || (form?.errors && form?.errors['invalid_creds'])
              }"
              (blur)="changeField('email')"
              (focus)="focusField('email')"
              (paste)="onPaste($event, 'email')"
              maxLength="{{emailMaxLength}}"
              appMobileLabelStyle
              />
              <span ngbTooltip="Вы можете оставить текущий E-mail или заменить на персональный для виджета" class="info-holder">
                <img src="assets/icons/info.svg"/>
              </span>
            </div>
            <div class="description">
              Пример: test@bum.ru
            </div>
          <div
            *ngIf="isDisplayFieldErrors('email') && f.email?.errors"
            class="invalid-feedback">
            <div *ngIf="f.email?.errors.required">
              Введите E-mail
            </div>
            <div *ngIf="f.email?.errors.emailMaxLength">
              Поле не должно превышать {{ emailMaxLength }} символов
            </div>
            <div *ngIf="f.email?.errors.emailIncorrect">
              Неверный E-mail
            </div>
          </div>

        </div>


        <div class="form-group">
          <label for="widgetLocation">Размещение виджета*</label>
          <div class="input-group">
            <ng-select
              dropdownPosition="top"
              appMobileLabelStyle
              class="w-100"
              id="widgetLocation"
              [clearable]="true"
              [searchable]="false"
              bindLabel="label"
              bindValue="value"
              placeholder="Выберите размещение"
              formControlName="widgetLocation"
              (focus)="focusInternalField('widgetLocation')"
              (blur)="changeField('widgetLocation')"
              (close)="changeField('widgetLocation')"
              [items]="locations"
              [ngClass]="{
                'is-invalid':
                  f.widgetLocation?.errors &&
                  isDisplayFieldErrors('widgetLocation')
              }"
            >
            </ng-select>
            <div
              *ngIf="isDisplayFieldErrors('widgetLocation') && f.widgetLocation?.errors"
              class="invalid-feedback">
              <div *ngIf="f.widgetLocation.errors.required">
                Выберите размещение
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin" class="row">
      <div class="col-6 form-group">
        <label for="chequeRequestTime">Установленная периодичность отправки запросов<br/>на забор фискальных чеков из ИМ*</label>
        <div class="input-group">
          <ng-select
            appMobileLabelStyle
            class="w-100"
            id="chequeRequestTime"
            [clearable]="true"
            [searchable]="false"
            bindLabel="label"
            bindValue="value"
            placeholder="Выберите периодичность"
            formControlName="chequeRequestTime"
            [items]="periods"
            (focus)="focusInternalField('chequeRequestTime')"
            (blur)="changeField('chequeRequestTime')"
            (close)="changeField('chequeRequestTime')"
            [ngClass]="{
              'is-invalid':
                f.chequeRequestTime?.errors &&
                isDisplayFieldErrors('chequeRequestTime')
            }"
          >
          </ng-select>
          <div
            *ngIf="isDisplayFieldErrors('chequeRequestTime') && f.chequeRequestTime?.errors"
            class="invalid-feedback">
            <div *ngIf="f.chequeRequestTime?.errors.required">
              Выберите периодичность
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 form-group">
        <label for="widgetStatusTime">Установленная периодичность запроса статуса<br/> состояния Виджета на стороне ИМ*</label>
        <div class="input-group">
          <ng-select
            appMobileLabelStyle
            class="w-100"
            id="widgetStatusTime"
            [clearable]="true"
            [searchable]="false"
            bindLabel="label"
            bindValue="value"
            placeholder="Выберите периодичность"
            formControlName="widgetStatusTime"
            (focus)="focusInternalField('widgetStatusTime')"
            (blur)="changeField('widgetStatusTime')"
            (close)="changeField('widgetStatusTime')"
            [items]="periods"
            [ngClass]="{
              'is-invalid':
                f.widgetStatusTime?.errors &&
                isDisplayFieldErrors('widgetStatusTime')
            }"
          >
          </ng-select>
          <div
            *ngIf="isDisplayFieldErrors('widgetStatusTime') && f.widgetStatusTime?.errors"
            class="invalid-feedback">
            <div *ngIf="f.widgetStatusTime?.errors.required">
              Выберите периодичность
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="form-group">
        <label for="chequeUrl">URL для запроса чеков</label>
        <input
          #chequeUrl
          type="text"
          formControlName="chequeUrl"
          id="chequeUrl"
          autocomplete="off"
          class="form-control"
          placeholder="Введите URL для чеков"
          [ngClass]="{
            'is-invalid': (isDisplayFieldErrors('chequeUrl') && f.chequeUrl?.errors)
          }"
          (blur)="changeField('chequeUrl')"
          (focus)="focusField('chequeUrl')"
          (paste)="onPaste($event, 'chequeUrl')"
          appMobileLabelStyle
          />
          <div class="description">Пример: https://www.bum.ru/cheques</div>
        <div
          *ngIf="isDisplayFieldErrors('chequeUrl') && f.chequeUrl?.errors"
          class="input-errors invalid-feedback">
          <div *ngIf="f.chequeUrl?.errors.required">
            Введите URL для запроса чеков
          </div>
          <div *ngIf="f.chequeUrl?.errors.urlIncorrect">
            Неверный URL
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
