import { Directive, ElementRef, HostListener } from '@angular/core';
/**
 * Директива для добавления класса focused
 */
@Directive({
  selector: '[appMobileLabelStyle]'
})
export class MobileLabelStyleDirective {
  constructor(public el: ElementRef) {}
  /**
   * Обработчик события фокуса
   *
   * @description Добавляет класс focused для элемента, который находится перед элементом,
   * на который установлена директива.
   */
  @HostListener('focus')
  onFocus() {
    this.el.nativeElement.parentNode.previousSibling?.classList?.add('focused');
  }

  /**
   * Обработчик события блура
   *
   * @description Удаляет класс focused для элемента, который находится перед элементом,
   * на который установлена директива.
   */
  @HostListener('blur')
  onBlur() {
    this.el.nativeElement.parentNode.previousSibling?.classList?.remove('focused');
  }
}
