/* eslint-disable max-len */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { takeUntil, switchMap, filter, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { States } from '../widget/widget.component';
import { AbstractWidgetComponent } from '../../AbstractWidgetComponent';
import {
  emailAndPhoneValidator,
  isPhone
} from 'src/app/config/validators/emailPhoneValidator';
import { WidgetService } from '@app/core/services/widget.service';
import {
  hasTextConfigChanges,
  setPlaceholder,
  setTextInElement
} from '@app/core/utils';
import { forkJoin, Subscription } from 'rxjs';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
import { WIDGET_RESTORE_DATA } from '../widget-restore-access/widget-restore-access.component';
import { WIDGET_REGISTRATION_DATA } from '../widget-registration/widget-registration.component';
import { accountSettingsForm } from 'src/app/config/validators';
import { isNil } from 'lodash';
export const contactsEdit = {
  phone: {
    maxLength: 19,
    minLength: 7
  },
  email: {
    maxLength: 50
  }
};

export const passportEdit = {
  firstName: {
    maxLength: 35
  },
  lastName: {
    maxLength: 35
  },
}
export const eyeCrossIcon =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xLjMzMzE3IDEuNTEzNjZMMi4xODY1IDAuNjY2OTkyTDEzLjMzMzIgMTEuODEzN0wxMi40ODY1IDEyLjY2N0wxMC40MzMyIDEwLjYxMzdDOS42NjY1IDEwLjg2NyA4Ljg1MzE3IDExLjAwMDMgNy45OTk4NCAxMS4wMDAzQzQuNjY2NSAxMS4wMDAzIDEuODE5ODQgOC45MjY5OSAwLjY2NjUwNCA2LjAwMDMzQzEuMTI2NSA0LjgyNjk5IDEuODU5ODQgMy43OTM2NiAyLjc5MzE3IDIuOTczNjZMMS4zMzMxNyAxLjUxMzY2Wk03Ljk5OTg0IDQuMDAwMzNDOC41MzAyNyA0LjAwMDMzIDkuMDM4OTggNC4yMTEwNCA5LjQxNDA1IDQuNTg2MTFDOS43ODkxMiA0Ljk2MTE4IDkuOTk5ODQgNS40Njk4OSA5Ljk5OTg0IDYuMDAwMzNDOS45OTk4NCA2LjIzMzY2IDkuOTU5ODQgNi40NjAzMyA5Ljg4NjUgNi42NjY5OUw3LjMzMzE3IDQuMTEzNjZDNy41Mzk4NCA0LjA0MDMzIDcuNzY2NSA0LjAwMDMzIDcuOTk5ODQgNC4wMDAzM1pNNy45OTk4NCAxLjAwMDMzQzExLjMzMzIgMS4wMDAzMyAxNC4xNzk4IDMuMDczNjYgMTUuMzMzMiA2LjAwMDMzQzE0Ljc4NjUgNy4zODY5OSAxMy44NTk4IDguNTg2OTkgMTIuNjY2NSA5LjQ2MDMzTDExLjcxOTggOC41MDY5OUMxMi42MjY1IDcuODgwMzMgMTMuMzczMiA3LjAyNjk5IDEzLjg3OTggNi4wMDAzM0MxMi43Nzk4IDMuNzYwMzMgMTAuNTA2NSAyLjMzMzY2IDcuOTk5ODQgMi4zMzM2NkM3LjI3MzE3IDIuMzMzNjYgNi41NTk4NCAyLjQ1MzY2IDUuODkzMTcgMi42NjY5OUw0Ljg2NjUgMS42NDY5OUM1LjgyNjUgMS4yMzM2NiA2Ljg4NjUgMS4wMDAzMyA3Ljk5OTg0IDEuMDAwMzNaTTIuMTE5ODQgNi4wMDAzM0MzLjIxOTg0IDguMjQwMzMgNS40OTMxNyA5LjY2Njk5IDcuOTk5ODQgOS42NjY5OUM4LjQ1OTg0IDkuNjY2OTkgOC45MTMxNyA5LjYyMDMzIDkuMzMzMTcgOS41MjY5OUw3LjgxMzE3IDguMDAwMzNDNi44NTk4NCA3LjkwMDMzIDYuMDk5ODQgNy4xNDAzMyA1Ljk5OTg0IDYuMTg2OTlMMy43MzMxNyAzLjkxMzY2QzMuMDczMTcgNC40ODAzMyAyLjUxOTg0IDUuMTg2OTkgMi4xMTk4NCA2LjAwMDMzWiIgZmlsbD0iI0MyQzJDMiIvPg0KPC9zdmc+DQo=';
export const eyeIcon =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik03Ljk5OTg0IDNDOC41MzAyNyAzIDkuMDM4OTggMy4yMTA3MSA5LjQxNDA1IDMuNTg1NzlDOS43ODkxMiAzLjk2MDg2IDkuOTk5ODQgNC40Njk1NyA5Ljk5OTg0IDVDOS45OTk4NCA1LjUzMDQzIDkuNzg5MTIgNi4wMzkxNCA5LjQxNDA1IDYuNDE0MjFDOS4wMzg5OCA2Ljc4OTI5IDguNTMwMjcgNyA3Ljk5OTg0IDdDNy40Njk0IDcgNi45NjA3IDYuNzg5MjkgNi41ODU2MiA2LjQxNDIxQzYuMjEwNTUgNi4wMzkxNCA1Ljk5OTg0IDUuNTMwNDMgNS45OTk4NCA1QzUuOTk5ODQgNC40Njk1NyA2LjIxMDU1IDMuOTYwODYgNi41ODU2MiAzLjU4NTc5QzYuOTYwNyAzLjIxMDcxIDcuNDY5NCAzIDcuOTk5ODQgM1pNNy45OTk4NCAwQzExLjMzMzIgMCAxNC4xNzk4IDIuMDczMzMgMTUuMzMzMiA1QzE0LjE3OTggNy45MjY2NyAxMS4zMzMyIDEwIDcuOTk5ODQgMTBDNC42NjY1IDEwIDEuODE5ODQgNy45MjY2NyAwLjY2NjUwNCA1QzEuODE5ODQgMi4wNzMzMyA0LjY2NjUgMCA3Ljk5OTg0IDBaTTIuMTE5ODQgNUMzLjIxOTg0IDcuMjQgNS40OTMxNyA4LjY2NjY3IDcuOTk5ODQgOC42NjY2N0MxMC41MDY1IDguNjY2NjcgMTIuNzc5OCA3LjI0IDEzLjg3OTggNUMxMi43Nzk4IDIuNzYgMTAuNTA2NSAxLjMzMzMzIDcuOTk5ODQgMS4zMzMzM0M1LjQ5MzE3IDEuMzMzMzMgMy4yMTk4NCAyLjc2IDIuMTE5ODQgNVoiIGZpbGw9IiNDMkMyQzIiLz4NCjwvc3ZnPg0K';
/**
 * Компонент логина
 */
@Component({
  selector: 'app-widget-login',
  templateUrl: './widget-login.component.html',
  styleUrls: ['./widget-login.component.scss']
})
export class WidgetLoginComponent extends AbstractWidgetComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Событие логина
   *
   * @type {EventEmitter}
   */
  @Output() successLogin = new EventEmitter<any>();
  /**
   * Событие клика на восстановление пароля
   *
   * @type {EventEmitter}
   */
  @Output() restoreClick = new EventEmitter<any>();
  /**
   * Событие клика на экспресс регистрацию
   *
   * @type {EventEmitter}
   */
  @Output() expressRegistration = new EventEmitter<any>();
  /**
   * Конфигурация Текстовок
   *
   * @type {any[]}
   */
  @Input() textConfig: any[];
  /**
   * Подписка на изменения языка
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Максимальная длина e-mail
   *
   * @type {number}
   */
  emailMaxLength: number = contactsEdit.email.maxLength;
  /**
   * Иконка при отображении пароля
   *
   * @type {string}
   */
  eyeIcon = eyeIcon;
  /**
   * Иконка при скрытом пароле
   *
   * @type {string}
   */
  eyeCrossIcon = eyeCrossIcon;
  /**
   * Максимальная длина номера телефона
   *
   * @type {number}
   */
  phoneMaxLength: number = contactsEdit.phone.maxLength;
  /**
   * Признак отображения пароля
   *
   * @type {boolean}
   */
  showPassword = false;
  /**
   * Признак отображения подтверждения пароля
   *
   * @type {boolean}
   */
  confirmShowPassword = false;
  /**
   * Текущий язык
   *
   * @type {any}
   */
  currentLanguage: any;
  /**
   * Максимальная длина пароля
   *
   * @type {string}
   */
  passwordMaxLength = accountSettingsForm.password.maxLength;
  /**
   * Минимальная длина пароля
   *
   * @type {string}
   */
  passwordMinLength = accountSettingsForm.password.minLength;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private widgetService: WidgetService,
    private localizationService: WidgetLocalizationService
  ) {
    super();
  }

  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук инициализации компонента
   *
   * @description Инициализирует форму логина и подписывается на события формы и изменения
   * параметров роута.
   */
  ngOnInit(): void {
    const checkboxState = localStorage.getItem('CHECKBOX_STATE');
    this.form = this.formBuilder.group({
      username: [null, emailAndPhoneValidator()],
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(this.passwordMinLength),
          Validators.maxLength(this.passwordMaxLength),
          this.disableCyrillicInput()
        ]
      ],
      confirm: [checkboxState ? true : null]
    });
    localStorage.removeItem(WIDGET_RESTORE_DATA);
    localStorage.removeItem(WIDGET_REGISTRATION_DATA);
    this.mask = null;
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkSubmit();
    });
    this.form.controls.confirm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (!val) {
          localStorage.removeItem('CHECKBOX_STATE');
          return;
        }
        localStorage.setItem('CHECKBOX_STATE', 'true');
        this.getData();
        this.sendData();
      });
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(val => {
        this.currentLanguage = val;
        this.setTexts();
      });
  }

  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    if (!this.currentLanguage) {
      return;
    }
    const map = new Map([
      ['widget-login-footer', 'widget-login-footer'],
      ['widget-login-header', 'widget-title'],
      ['widget-login-prompt', 'widget-login-prompt']
    ]);
    map.forEach((field, key) => {
      setTextInElement(
        key,
        this.localizationService.findByKey(field, this.currentLanguage.localeId)
      );
    });
    const mapPlaceholders = new Map([
      ['username', 'widget-login-placeholder'],
      ['password', 'widget-password-placeholder']
    ]);
    mapPlaceholders.forEach((field, key) => {
      setPlaceholder(
        key,
        this.localizationService.findByKey(field, this.currentLanguage.localeId)
      );
    });
  }

  /**
   * Обработчик события вставки в поле логина
   *
   * @param event переменная, содержащая информацию о буфере обмена
   * @param fieldName имя поля
   */
  onPaste(event: ClipboardEvent, fieldName: string): void {
    this.mask = undefined;
    event.preventDefault();
    const rawValue = event.clipboardData.getData('text/plain');
    const val = rawValue.startsWith('+')
      ? rawValue.replace(/[^\d+]/g, '')
      : rawValue.replace(/\s/g, '');
    if (fieldName === 'username' && val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
    this.f[fieldName].setValue(val);
    setTimeout(() => this.f[fieldName].updateValueAndValidity(), 0);
  }
  /**
   * Обработчик события ввода в поле логина
   */
  onKey(): void {
    const val = this.f.username.value;
    this.patternTextField('username');
    this.mask = undefined;
    if (val === '') {
      return;
    }
    if (val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
  }
  /**
   * Смена признака отображения пароля
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Сабмит формы
   */
  onSubmit(): void {
    this.loading = true;
    this.widgetService
      .authorize(
        this.f.username.value.replace(/\s/g, ''),
        this.f.password.value
      )
      .pipe(
        switchMap(res => {
          if (!res || !res['access_token']) {
            this.loading = false;
            return of(null);
          }
          return this.widgetService.saveToken(res);
        }),
        filter(v => v !== null),
        switchMap(() =>
          forkJoin([
            this.widgetService.getCustomer(),
            this.widgetService.obtainRegAuthUser()
          ])
        )
      )
      .subscribe(
        ([customer, user]) => {
          const isPhoneFlag = isPhone(this.f.username.value.replace(/\s/g, ''));
          this.loading = false;
          this.successLogin.emit(
            this.createObjectWithUserData(user, customer, isPhoneFlag)
          );
        },
        err => {
          this.loading = false;
          if (err?.error?.error && err.error.error === 'invalid_grant') {
            this.form.setErrors({ invalid_creds: true });
          }
        }
      );
  }

  /**
   * Создание объекта с данными пользователя для отправки в эмиттер
   *
   * @param user объект пользователя
   * @param customer объект покупателя
   * @param isPhoneFlag признак, если пользователь залогинен через телефон
   * @returns объект с данными авторизации пользователя
   */
  createObjectWithUserData(user: any, customer: any, isPhoneFlag: boolean) {
    if (user && user.expressRegistrationData) {
      return {
        user: {
          passport: {
            name: user.expressRegistrationData.name,
            surname: user.expressRegistrationData.surname
          },
          contact: {
            emails: isPhoneFlag ? [] : [this.f.username.value],
            phoneNumbers: isPhoneFlag ? [this.f.username.value] : []
          }
        },
        newState: States.SUCCESS_LOGIN,
        isPhone: isPhone(this.f.username.value.replace(/\s/g, ''))
      };
    }
    return {
      user: customer,
      newState: States.SUCCESS_LOGIN,
      isPhone: isPhone(this.f.username.value.replace(/\s/g, ''))
    };
  }

  /**
   * Триггер события перехода на страницу восстановления пароля
   */
  triggerRetoreEvent() {
    this.restoreClick.emit({
      newState: States.RESTORE_ACCESS
    });
  }

  /**
   * Триггер события перехода на экспресс регистрацию
   */
  triggerExpressRegistration() {
    this.expressRegistration.emit({
      newState: States.EXPRESS_REGISTRATION
    });
  }

  /**
   * Получение локализованной ошибки по длине email
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getEmailErrorText(fieldName: string) {
    const textNode = this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
    const parts = textNode.split('{{ emailMaxLength }}');
    return parts[0] + this.emailMaxLength + parts[1];
  }

  /**
   * Получение локализованной ошибки
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getErrorText(fieldName: string) {
    return this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
  }

  /**
   * Получение локализованной ошибки по длине пароля
   *
   * @param fieldName имя поля
   * @param isMax признак ограничения максимальной длины
   * @returns локализованная ошибка
   */
  getPasswordErrorText(fieldName: string, isMax: boolean) {
    const textNode = this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
    if (isMax) {
      return textNode.replace(
        '{{ passwordMaxLength }}',
        this.passwordMaxLength.toString()
      );
    }
    return textNode.replace(
      '{{ passwordMinLength }}',
      this.passwordMinLength.toString()
    );
  }
  /**
   * Валидатор запрета ввода кириллицы
   *
   * @description Возвращает ошибку для поля формы, если введен символ кириллицы
   * @returns Ошибку для поля формы, если введен символ кириллицы
   */
  disableCyrillicInput(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const reg = new RegExp(
        '^([a-zA-Z0-9«!»#\\$%&()\\*\\+,\\-\\./:;<=>?@\\[\\]\\^_`{\\|}~])*$'
      );
      if (!isNil(value)) {
        return reg.test(value) ? null : { disableCyrillicInput: true };
      }
    };
  }
  /**
   * Отсылка данных на сервер
   */
  private sendData() {
    const shopId = localStorage.getItem('WIDGET_SHOP_ID');
    if (!shopId) {
      return;
    }
    this.shopData.shopId = shopId;
    this.loading = true;
    this.widgetService
      .sendShopData(this.shopData)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        localStorage.setItem('DATA_IS_SENDED', 'true');
      });
  }
}
