<div class="success-added-wrapper">
  <svg-icon class="success-icon" src="assets/img/submited.svg"></svg-icon>
  <h5>
    Виджет успешно добавлен
  </h5>
  <div class="btn-items">
    <button (click)="redirectEvent.emit()" class="btn btn-primary">Настроить вариант отображения</button>
    <button (click)="closeEvent.emit()" class="btn btn-link">Настроить позже</button>
  </div>
</div>
