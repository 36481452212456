<div [formGroup]="form" class="position-relative text-container">
  <div class="d-flex justify-content-between align-items-center title-block">
    <div class="title-area">{{title}}</div>
    <button class="btn btn-copy" (click)="copyInputMessage()">Копировать</button>
  </div>

  <div class="message-box">
    <div class="text-script">
      <pre class="text-paragraph">{{text}}</pre>
    </div>
  </div>
</div>