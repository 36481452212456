import { Injectable } from '@angular/core';
import { Receipt } from '../models/Receipt';

/**
 * Сервис для парсинга QR кода
 */
@Injectable()
export class QrParserService {
  /**
   * Парсер QR кода в объект чека
   *
   * @description пытается преобразовать распознанные данные QR кода в объект чека
   * В случае ошибки выбрасывает сообщение, что распознан не чек
   * @param qrCode данные распознанного QR кода
   * @returns объект чека или выбрасывает ошибку
   */
  public parseReceiptQRCode(qrCode: string): Receipt {
    try {
      const receipt = new Receipt();
      receipt.codeQR = qrCode;
      receipt.chequeDateTime = qrCode.match(/t=(\d+T?\d*)/)[1];
      receipt.sum = parseFloat(qrCode.match(/s=(\d*.\d*)/)[1]);
      receipt.fiscalStorageNumber = qrCode.match(/fn=(\d+)/)[1];
      receipt.fiscalReceiptNumber = parseInt(qrCode.match(/&i=(\d+)/)[1], 10);
      receipt.fiscalAttribute = qrCode.match(/fp=(\d+)/)[1];
      receipt.paymentIndication = parseInt(qrCode.match(/&n=(\d+)/)[1], 10);
      return receipt;
    } catch (e) {
      throw new Error('This qr is not cheque');
    }
  }
}
