import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';

import {
  AuthService,
  LocalizationService,
  NavigationMobileService
} from '@app/core/services';
import { AbstractFormComponent } from '@app/core/classes';
import { ABOUT_PAGE } from '@app/core/constants';
import { ILocalization } from '@app/core/models';
import { environment } from 'src/environments/environment';
/**
 * Компонент мобильного меню навигации
 */
@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent extends AbstractFormComponent
  implements OnInit {
  /**
   * Список роутов и заголовков для отображения
   *
   * @type {any[]}
   */
  @Input() links: any[];
  /**
   * Текущий URL
   *
   * @type {string}
   */
  @Input() currentUrl: string;
  /**
   * Эмиттер события изменения состояния меню
   *
   * @type {EventEmitter}
   */
  @Output() togglerMenu = new EventEmitter();
  /**
   * Эмиттер события скрытия кнопки закрытия
   *
   * @type {EventEmitter}
   */
  @Output() hideCloser = new EventEmitter();
  /**
   * Признак отображения дочерних элементов
   *
   * @type {boolean}
   */
  showChildren: boolean;
  /**
   * Хлебные крошки для мобильного меню
   *
   * @type {any}
   */
  crumbsTitle: any;
  /**
   * Признак залогинненного пользователя
   *
   * @type {boolean}
   */
  isLoggedIn = false;
  /**
   * Выбранная локализация
   *
   * @type {ILocalization}
   */
  selectedLocalization: ILocalization;
  /**
   * Заголовок родителя текущего роута
   *
   * @type {string}
   */
  currParent: string;
  /**
   * хост сервиса хранения статических ресурсов
   */
  minioHost: string;

  constructor(
    private authService: AuthService,
    protected formBuilder: UntypedFormBuilder,
    public currPath: NavigationMobileService,
    private router: Router,
    private localizationService: LocalizationService,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    super();
    this.minioHost = environment.minioHost;
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.isLoggedIn = true;
    }
    this.showChildren = false;
    this.selectedLocalization = this.localizationService.getLocalization();
    this.form = this.formBuilder.group({
      selectedLocaleId: [this.selectedLocalization.localeId]
    });
    this.setCurrentParent();
    this.onResize();
  }

  /**
   * Простановка родительского заголовка по текущему url
   */
  setCurrentParent() {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      const currUrl = event.url;
      ['traveller', 'business', ABOUT_PAGE].forEach(route => {
        if (currUrl.includes(route)) {
          this.currParent = route;
        }
      });
    });
  }

  /**
   * Скрытие меню
   */
  hideMenu() {
    setTimeout(() => {
      this.togglerMenu.emit();
    }, 1);
    document.body.classList.remove('body-overflow-mobile');
  }

  /**
   * Отображение дочерних элементов роута
   *
   * @param route родительский роут
   */
  showChildrenTrigger(route: any) {
    if (!route.children) {
      return;
    }
    this.showChildren = true;
    this.crumbsTitle =
      route.lang && route.lang[this.localeId]
        ? route.lang[this.localeId]
        : route.title;
    this.hideCloser.emit(false);
  }

  /**
   * Скрытие дочерних элементов роута
   */
  hideChildren() {
    this.showChildren = false;
    this.crumbsTitle = null;
    this.hideCloser.emit(true);
  }

  /**
   * Обработчик нажатия на кнопку Выйти
   */
  logout(): void {
    this.authService.logout();
  }

  /**
   * Обработчик нажатия на кнопку Вход
   */
  login() {
    this.hideMenu();
    this.router.navigate(['/login']);
  }

  /**
   * Редирект на главную страницу
   *
   * @description очищает все параметры, закрывает меню, и редиректит на главную страницу
   */
  goHome() {
    this.currPath.currentPathName = null;
    this.showChildren = false;
    this.crumbsTitle = null;
    this.togglerMenu.emit();
    document.body.classList.remove('body-overflow-mobile');
    this.router.navigate(['/']);
  }
}
