export const api = {
  auth: {
    login: '/login',
    register: '/account/register',
    restore: '/account/restore',
    updatePassword: '/account/password/{code}',
    captcha: '/captcha',
    confirmRegisterBySmsCode: '/account/password/{code}/{login}',
    confirmUpdateBySmsCode: '/account/password/update/{code}/{login}',
    againSendRegisterLink: '/account/resend/email',
    resendSms: '/account/resend/sms',
    loginConfig: '/auth/login-config',
    registerUserByCode: '/account/register/{code}',
    restoreUserByCode: '/account/restore/{code}',
  },
  admin: {
    admin: 'admin',
    faq: '/admin/faq',
  },
  customer: {
    data: '/account/customer',
    currentUser: '/auth/user',
    passport: '/account/customer/passport',
    contact: '/account/customer/contact',
    accountSettings: '/account/settings',
    qrCode: '/customer/qr-code',
    base: '/customer',
    customerById: '/customer/{id}',
    removeNotifications: '/notifications/read',
    notifications: '/notifications/paged',
    restore: '/account/express-registration/restore',
    verifyPhoneBySmsCode: '/account/verify/sms/{code}/{login}',
    oauthToken: '/oauth/token',
    confirmUpdateBySmsCode: '/account/password/update/{code}/{login}',
    resendSms: '/account/express-registration/resend',
    expressRegistration: '/account/express-registration/',
    userExpress: '/account/express-registration/user',
    confirmRegistration: '/account/express-registration/confirm/{code}/{login}',
    delete: {
      cheques: '/customer/delete/cheques',
      sendCode: '/customer/delete/send-code',
      confirm: '/customer/delete/confirm',
    },
    resetPassword: '/account/reset-password',
  },
  widget: {
    sendPath: '/log',
    widgetPath: '/widget',
    analyticCSV: '/widget/csv',
    locale: '/widget-locale',
    imCreate: '/widget/for-im',
    widgetLocationPath: '/widget/for-im/location/{shopId}',
    stats: '/widget/stats',
    partnerAnalyticCSV: '/partner/widget/csv',
    partnerStats: '/partner/widget/stats',
    partner: '/partner/widget',
    customer: '/account/express-registration/customer',
    confirmRegistration:
      '/account/express-registration/confirm/{code}/{login}/{shopId}',
  },
  widgetIntegration: {
    checkReceipt: '/receipt/present/{login}',
    receipts: '/receipt/{login}',
    restore: '/account/restore',
    resetPassword: '/account/reset-password',
    verifyPhoneBySmsCode: '/account/verify/sms/{code}/{login}',
  },
  paymentOption: {
    paymentOption: '/account/customer/paymentDetails',
    paymentOptionById: '/account/customer/paymentDetails/{paymentDetailsId}',
    setDefault: '/account/customer/paymentDetails/{paymentDetailsId}',
    remove: '/account/customer/paymentDetails/{paymentDetailsId}',
  },
  dictionary: {
    currency: '/dictionary/currency',
    allCurrencies: '/dictionary/currency?all=true',
    currencyPage: '/dictionary/currency-page',
    country: '/dictionary/country',
    paymentType: '/dictionary/payment-type',
    currencyByCode: '/dictionary/currency-by-code',
    unit: '/dictionary/unit',
    region: '/dictionary/region',
  },
  taxFreeCheque: {
    cheques: '/customer/tax-free-cheque/paged',
    chequeById: '/customer/tax-free-cheque/{id}',
    taxFreeChequeStat: '/customer/tax-free-cheque/stat',
    taxFreeChequeQrCode: '/customer/tax-free-cheque/{id}/qr-code',
    exportXMLFns: '/fns-registry/xml',
    exportExcelFns: '/report/registry/fns',
    exportOrt: '/registry/ort',
  },
  fnsRegistry: {
    generateTemplate: '/fns-registry/generate-template',
    validateTemplate: '/fns-registry/validate-template',
    exportXML: '/fns-registry/xml',
    exportXMLByTemplate: '/fns-registry/xml-by-template',
  },
  taxCalculator: {
    vat: '/tax-calculator/vat',
  },
  exciseProducts: {
    main: '/excise-product',
    config: '/excise-product/config',
    import: '/excise-product/import',
    export: '/excise-products',
  },
  offers: {
    offers: '/offers',
    active: '/partner/offers/active',
    addOffer: '/partner/offers',
    updateOffer: '/partner/offers/{offerId}',
    offerById: '/partner/offers/{offerId}',
    removeById: '/partner/offers/{offerId}',
    products: '/registry/ort',
    productsExcel: '/report/registry/ort',

    /** Check if we can remove this 'all' path */
    all: '/offers',
    partnerOffers: '/partner/offers',
  },
  tradingNetwork: {
    tradeNetworks: '/trading-networks',
    tradeNetworksForOfferFilter: '/trading-networks/offer-filter-values',
    tradeNetworksWithDigitalSignature:
      '/trading-networks/with-digital-signature',
    tradeNetworkById: '/trading-networks/{tradingNetworkId}',
    organizations: '/trading-networks/organizations',
    tradeNetworkOrg: '/trading-networks/{tradingNetworkId}/organizations',
    addShop: '/trading-networks/{tradingNetworkId}/organizations/im',
    addWithStores: '/trading-networks/create-with-orgs',
    tradeNetworksWithCheques: '/trading-networks/with-cheques',
  },
  user: {
    all: '/user',
    userById: '/user/{id}',
    block: '/user/{id}/block',
    allPaginate: '/user/paged',
  },
  partner: {
    partner: '/partners',
    partnerById: '/partners/{id}',
    partnerByUserId: '/partners/by-user-id/{id}',
    widget: '/partner/widget',
    organization: '/partner/widget/organizations',
    organizationUpdate:
      '/partners/change-organization?partnerId={partnerId}&networkId={networkId}',
    registrationIM: '/partner/registration/to/upload',
  },
  shoppers: {
    captcha: '/captcha',
    faq: '/faq',
    traceById: '/public/tax-free-cheque-trace/by-id',
    traceByPhone: '/public/tax-free-cheque-trace/by-phone',
    traceByEmail: '/public/tax-free-cheque-trace/by-email',
    traceByPassport: '/public/tax-free-cheque-trace/by-passport',
    traceByPurchase: '/public/tax-free-cheque-trace/by-payment-details',
    getConfirmSms: '/public/tax-free-cheque-trace/by-phone/send',
  },
  operator: {
    cheques: '/operator/cheques',
    chequeById: '/operator/cheques/{id}',
    offers: '/operator/offers',
    customers: '/operator/customers',
    customerComment: '/customer/comment',
    customerById: '/operator/customers/{id}',
    taxFreeFts: '/tax-free-cheque-for-fts',
    taxFreeFtsAll: '/tax-free-cheque-for-fts/fts-all-request',
    ftsResultDownload: '/tax-free-cheque-for-fts/{id}/fts-validation',
    ftsChequeDownload: '/tax-free-cheque-for-fts/{id}/fts-request',
    ftsConfirmDownload: '/tax-free-cheque-for-fts/{id}/fts-confirm',
    createOperator: '/operators',
    createPaymentOperator: '/payment-operator',
    downloadCheques: '/operator/registry',
    downloadRsbPaidCheques: '/report/tax-free-cheque/daily-paid',
    exportExcel: '/report/registry',
    ortDigitalSignatureRequestDownload:
      '/operator/ort-digital-signature/{id}/request',
    ortDigitalSignatureResponseDownload:
      '/operator/ort-digital-signature/{id}/response',
    ortDigitalSignatureRetry: '/operator/ort-digital-signature/{id}/retry',
    isOrtDigitalSignatureAlreadyRetry:
      '/operator/ort-digital-signature/{id}/retry',
    updatePayment: '/operator/cheques/{id}/{state}',
    confirmPayment: '/operator/cheques/{id}/manual-payment',
    cardById: '/operator/cheques/{id}/payment-details',
    refreshCache: '/report/refresh-cheques-cache',
    refreshCacheDate: '/report/update-cache-date',
    gateways: '/payment-operator/gateways',
    sendGateway: '/payment-operator/select-gateway-for-list',
  },
  websockets: '/ws/notification',
  report: {
    report: '/report/{reportType}',
    csv: '/report/{reportType}/csv',
    list: '/list/commodity',
    all: '/report/commodity/list',
    tradingOrgList: '/list/trading-organization',
    cheques: '/report/cheques',
    paymentOrders: '/report/payment-orders',
    commodities: '/report/commodities',
    minpromtorg: '/minpromtorg',
    refundedCanceled: '/report/refunded-canceled',
  },
  configuration: {
    config: '/config-file',
    creationChequeSetting: '/feature-setting/creation-cheque-on-portal',
    organizationFeatures: '/config-file/operator/organization-features',
    feedbackEmail: '/config-file/operator/feedback-email',
  },
  checkpoints: {
    main: '/checkpoint',
    checkpoints: '/checkpoint',
    checkpoint: '/checkpoint/{id}',
  },
  taxFreeRules: {
    taxFreeRules: '/tax-free-rules',
    taxFreeRulesById: '/tax-free-rules/{id}',
    allTaxFreeRules: '/tax-free-rules/all',
    pagedTaxFreeRules: '/tax-free-rules/paged',
  },
  terminal: {
    manufacturer: '/kkt-manufacturer',
    models: '/kkt-manufacturer/{id}/models',
  },
  kkt: {
    kkt: '/kkt',
    paged: '/kkt/paged',
    byId: '/kkt/{id}',
  },
  getInTouch: {
    config: '/config-file',
    getInTouch: '/git',
  },
  unitsOfMeasure: {
    main: '/dictionary/measurementUnit',
  },
  contentPages: {
    getAll: '/page',
    add: '/page',
    getByLocaleAndCode: '/page/code/{locale}/{code}',
    getByCode: '/page/code/{code}',
    getById: '/page/{id}',
    removeById: '/page/{id}',
  },
  media: {
    base: '/media',
    upload: '/media/pre-upload',
  },
  account: {
    confirmEmail: '/account/email/{code}',
    confirmPhoneNumber: '/account/login/{code}/{phone}',
    update: '/account/user/update',
  },
  paymentOrders: {
    getOrders: '/payment-order/paged',
    retry: '/payment-order/retry/',
  },
  taxFreeChequesFTS: {
    getCheques: '/taxFreeChequesForFTS',
  },
  list: {
    getTradingOrganizationList: '',
  },
  mobileApp: {
    settings: '/mobile-app-update-config',
  },
};
