export const contactsEdit = {
  phone: {
    maxLength: 19,
    minLength: 7
  },
  email: {
    maxLength: 50,
    minLength: 6
  }
};
