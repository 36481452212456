import { TaxFreeOperator } from './TaxFreeOperator';

export class ConfigurationFile {
  active?: boolean;
  id: string;
  taxFreeOperator: TaxFreeOperator;
  offerRadar?: string;
  shopRadius?: string;
  offerPeriodicity?: string;
  sendySubject?: string;

  constructor(rawData?: ConfigurationFile) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
