import { Injectable, LOCALE_ID, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map as lodashMap, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { PaymentSystem } from 'src/app/core/models/domain';
import { contentPageStatuses } from 'src/app/core/dictionary/contentPageStatuses';
import {
  Currency,
  ChequeStatus,
  Period,
  UnitsOfMeasure,
  Region
} from 'src/app/core/models';

import { dictionaryTranslator } from 'src/app/utility';
import {
  chequeStatuses,
  periods,
  partnerOfferStatuses
} from 'src/app/core/dictionary';
import { OfferStatus } from '../models/partnerOffer/OfferStatus';
import { ContentPageStatus } from '../models/contentPage';

/**
 * Сервис для работы со словарями
 */
@Injectable({ providedIn: 'root' })
export class DictionaryService {
  /**
   * Http Опции
   */
  httpOptions = {};
  /**
   * Http Опции с заголовком X-Skip-Interceptor
   */
  httpOptionsWithSkipCacheHeader = {};

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject(LOCALE_ID) protected localeId: string
  ) {
    this.httpOptions = this.authService.getHttpOptionsByAuthState();
    this.httpOptionsWithSkipCacheHeader = this.authService.getHttpOptionsByAuthStateWithSkipHeader();
  }

  /**
   * Получение списка курсов валют для платежных средств
   *
   * @returns Наблюдателя за получением списка курсов валют
   */
  public obtainNotAllCurrencies(): Observable<Currency[]> {
    return this.http.get<any>(this.getNotAllCurrenciesPath(), this.httpOptions);
  }

  /**
   * Получение списка курсов валют с опциями авторизации
   *
   * @returns Наблюдателя за получением списка курсов валют
   */
  public obtainCurrency(): Observable<Currency[]> {
    return this.obtainCurrencyWithHttpOptions(this.httpOptions);
  }

  /**
   * Получение списка курсов валют с заголовком X-Skip-Interceptor
   *
   * @returns Наблюдателя за получением списка курсов валют
   */
  public obtainCurrencyWithSkipCache(): Observable<Currency[]> {
    return this.obtainCurrencyWithHttpOptions(
      this.httpOptionsWithSkipCacheHeader
    );
  }

  /**
   * Получение списка типов платежных систем
   *
   * @returns Наблюдателя за получением списка платежных систем
   */
  public obtainPaymentType(): Observable<PaymentSystem[]> {
    return this.http.get<PaymentSystem[]>(
      this.getPaymentTypePath(),
      this.httpOptions
    );
  }

  /**
   * Получение списка стран
   *
   * @returns Наблюдателя за получением списка стран
   */
  public obtainCountry(): Observable<any> {
    return this.http
      .get<any>(this.getCountyPath(), this.httpOptions)
      .pipe(
        map(countries =>
          sortBy(dictionaryTranslator(countries, this.localeId), ['name'])
        )
      );
  }

  /**
   * Получение списка организаций
   *
   * @returns Наблюдателя за получением списка организций
   */
  public obtainOrganizations(): Observable<any> {
    return this.http
      .get<any>(this.getOrganizationPath(), this.httpOptions)
      .pipe(
        map(organizations =>
          organizations
            ? lodashMap(organizations, org => ({
              code: org.id,
              name: org.name,
              inn: org.inn,
              paymentAddress: org.paymentAddress
            }))
            : []
        )
      );
  }

  /**
   * Получение списка единиц измерения
   *
   * @returns Наблюдателя за получением списка единиц измерения
   */
  public obtainUnits(): Observable<UnitsOfMeasure[]> {
    return this.http.get<any>(this.getUnitPath(), this.httpOptions);
  }

  /**
   * Получение списка субъектов РФ
   *
   * @returns Наблюдателя за получением списка РФ
   */
  public obtainRegions(): Observable<Region[]> {
    return this.http.get<any>(this.getRegionsPath(), this.httpOptions);
  }

  /**
   * Получение локализованного списка статусов чека
   *
   * @description для константного списка статусов получает данные только нужной локали
   * @param locale текущая локализация
   * @returns Наблюдателя за получением списка статусов чека
   */
  public getChequeStatuses(locale?: string): Observable<ChequeStatus[]> {
    const localeId = locale ? locale : this.localeId;
    return new Observable(observer =>
      observer.next(dictionaryTranslator(chequeStatuses, localeId))
    );
  }

  /**
   * Получение локализованного статуса чека по коду
   *
   * @param code код статуса
   * @returns Наблюдателя за получением статуса по коду
   */
  public getChequeStatuseByCode(code: string): Observable<any[]> {
    return new Observable(observer =>
      observer.next(
        dictionaryTranslator(chequeStatuses, this.localeId).filter(
          status => code === status.code
        )
      )
    );
  }

  /**
   * Получение локализованого словаря периодов
   *
   * @returns Наблюдателя за получением списка периодов
   */
  public getPeriod(): Observable<Period[]> {
    return new Observable(observer =>
      observer.next(dictionaryTranslator(periods, this.localeId))
    );
  }

  /**
   * Получение локализованного списка статусов предложений
   *
   * @returns Наблюдателя за получением списка статусов предложений
   */
  public getPartnerOfferStatuses(): Observable<OfferStatus[]> {
    return new Observable(observer =>
      observer.next(dictionaryTranslator(partnerOfferStatuses, this.localeId))
    );
  }

  /**
   * Получение локализованного списка статусов страниц конетнта
   *
   * @returns Наблюдателя за получением списка статусов страниц конетнта
   */
  public getContentPageStatuses(): Observable<ContentPageStatus[]> {
    return new Observable(observer =>
      observer.next(dictionaryTranslator(contentPageStatuses, this.localeId))
    );
  }

  /**
   * Получение списка курсов валют с http опциями, переданными в функцию
   *
   * @param httpOptions опции http
   * @returns Наблюдателя за получением списка курсов валют
   */
  private obtainCurrencyWithHttpOptions(
    httpOptions: object
  ): Observable<Currency[]> {
    return this.http
      .get<any>(this.getAllCurrenciesPath(), httpOptions)
      .pipe(map(currencies => dictionaryTranslator(currencies, this.localeId)));
  }

  /**
   * Генерация URL списка курсов валют
   *
   * @returns URL списка курсов валют
   */
  private getAllCurrenciesPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.allCurrencies;
  }

  /**
   * Генерация URL для курсов валют в платежных системах
   *
   * @returns URL для курсов валют в платежных системах
   */
  private getNotAllCurrenciesPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.currency;
  }
  /**
   * Генерация URL для списка стран
   *
   * @returns URL для списка стран
   */
  private getCountyPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.country;
  }
  /**
   * Генерация URL для списка организаций
   *
   * @returns URL для списка организаций
   */
  private getOrganizationPath(): string {
    return environment.basePortalApiUrl + api.tradingNetwork.organizations;
  }
  /**
   * Генерация URL для списка платежных систем
   *
   * @returns URL для списка платежных систем
   */
  private getPaymentTypePath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.paymentType;
  }
  /**
   * Генерация URL для списка единиц измерения
   *
   * @returns URL для списка единиц измерения
   */
  private getUnitPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.unit;
  }
  /**
   * Генерация URL для списка субъектов РФ
   *
   * @returns URL для списка субъектов РФ
   */
  private getRegionsPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.region;
  }
}
