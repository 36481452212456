import * as moment from 'moment';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { isNil, isEmpty, uniq } from 'lodash';
import { countryCodes } from 'src/app/config/validators/countryCodes';
import { Directive } from '@angular/core';
import { ICountryCode } from '@app/core/models/CountryCode';
import { AbstractComponent } from 'src/app/core/classes/AbstractComponent';

/**
 * Абстрактный класс с использованием формы
 */
@Directive()
export abstract class AbstractFormComponent extends AbstractComponent {
  /**
   * Форма с данными
   *
   * @type {FormGroup}
   */
  form: UntypedFormGroup;
  /**
   * Признак отправки формы
   *
   * @type {boolean}
   */
  submitted = false;
  /**
   * Признак запрета на отправку формы
   *
   * @type {boolean}
   */
  disabledSubmit = true;
  /**
   * Признак отображения инструкции
   *
   * @type {boolean}
   */
  showInstructions = false;
  /**
   * Счётчик секунд
   *
   * @type {number}
   */
  counter = 0;
  /**
   * Признак отображения пароля
   *
   * @type {boolean}
   */
  showPassword = false;
  /**
   * Признак отображения подтверждения пароля
   *
   * @type {boolean}
   */
  confirmShowPassword = false;
  /**
   * Признак ненадёжного пароля
   *
   * @type {boolean}
   */
  weakPassword: boolean;
  /**
   * Признак среднего пароля
   *
   * @type {boolean}
   */
  averagePassword: boolean;
  /**
   * Признак надёжного пароля
   *
   * @type {boolean}
   */
  strongPassword: boolean;
  /**
   * Объект, содержащий признаки отображения ошибок для контролов формы
   *
   * @type {Map<string, boolean>}
   */
  private showFieldsErrors = new Map<string, boolean>();

  constructor() {
    super();
  }

  /**
   * Геттер контролов формы
   */
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  /**
   * Удаление пробелов и замена групп пробелов на один в контроле формы
   *
   * @description Из начала строки удаляет любое количество пробельных символов, после этого
   * заменяет все найденные группы пробелов на один пробел.
   * @param field название контрола формы
   */
  patternTextField(field: string): void {
    if (!this.f[field]?.value) {
      return;
    }
    let value = this.f[field].value;
    value = value.replace(/^\s+/, '');
    value = value.replace(/\s+/g, ' ');
    this.form.patchValue({ [field]: value });
  }

  /**
   * Метод валидации введенных значений
   *
   * @param field наименование поля
   * @param index индекс
   */
  patternTextArrayField(field: string, index: number): void {
    const tenVal = (this.f[field] as UntypedFormArray).at(index).value;
    if (/^ *$/.test(tenVal)) {
      (this.f[field] as UntypedFormArray)
        .at(index)
        .setValue(
          (this.f[field] as UntypedFormArray).at(index).value.replace(/\s/g, '')
        );
    }
    (this.f[field] as UntypedFormArray)
      .at(index)
      .patchValue(
        (this.f[field] as UntypedFormArray).at(index).value.replace(/\s+/g, ' ')
      );
  }

  /**
   * Метод валидации введенных значений
   *
   * @param parent родительский массив контролов
   * @param field наименование поля
   */
  patternInternalTextField(parent: UntypedFormArray, field: string): void {
    const control = parent.controls[field];
    if (control && control.value) {
      if (control.value.startsWith(' ')) {
        control.patchValue(control.value.replace(/\s/g, ''));
      }
      control.patchValue(control.value.replace(/\s+/g, ' '));
    }
  }

  /**
   * Метод обработки изменения поля
   *
   * @param field название контрола формы
   */
  changeField(field: string): void {
    if (this.f[field].errors) {
      this.enableShowFieldErrors(field);
    }
    if (
      !isNil(this.f[field].value) &&
      field === 'password' &&
      this.f.confirm?.errors?.doNotMatch
    ) {
      this.enableShowFieldErrors('confirm');
    }
  }

  /**
   * Поведение при фокусе на поле формы
   *
   * @param field название контрола формы
   */
  focusField(field: string): void {
    this.disableShowFieldErrors(field);
  }

  /**
   * Метод обработки фокуса поля
   *
   * @param field наименование поля
   * @param index индекс
   */
  focusInternalField(field: string, index?: number): void {
    this.disableShowFieldErrors(field, index);
  }

  /**
   * Метод обработки фокуса поля
   *
   * @param field наименование поля
   * @param index индекс
   * @param secondIndex индекс вложенный
   */
  focusInternalArrayField(
    field: string,
    index?: number,
    secondIndex?: number
  ): void {
    this.disableShowFieldErrors(field, index, secondIndex);
  }

  /**
   * Обработчки расфокуса с поля вложенного в другой массив
   *
   * @param parent родительский контрол
   * @param field наименование поля
   * @param index индекс в первом массиве
   * @param secondIndex индекс во втором массиве
   */
  changeInternalArrayField(
    parent: UntypedFormArray,
    field: string,
    index?: number,
    secondIndex?: number
  ) {
    if (parent.controls[field].errors) {
      this.enableShowFieldErrors(field, index, secondIndex);
    }
  }

  /**
   * Метод обработки изменения поля
   *
   * @param parent родительский массив контролов
   * @param field наименование поля
   * @param index индекс
   */
  changeInternalField(
    parent: UntypedFormArray,
    field: string,
    index?: number
  ): void {
    if (parent.controls[field].errors) {
      this.enableShowFieldErrors(field, index);
    }
  }

  /**
   * Метод обработки изменения массива полей
   *
   * @param parent родительский массив контролов
   * @param field наименование поля
   * @param index индекс
   */
  changeArrayControl(
    parent: UntypedFormArray,
    field: string,
    index: number
  ): void {
    if (parent.controls[index].errors) {
      this.enableShowFieldErrors(field, index);
    }
  }

  /**
   * Отображение ошибок для контрола формы
   *
   * @param field название контрола формы
   * @param index индекс
   * @param secondIndex индекс вложенный
   */
  enableShowFieldErrors(
    field: string,
    index?: number,
    secondIndex?: number
  ): void {
    if (isNil(index)) {
      this.showFieldsErrors[field] = true;
      return;
    }
    if (isNil(secondIndex)) {
      if (isNil(this.showFieldsErrors[index])) {
        this.showFieldsErrors[index] = { [field]: true };
        return;
      }
      this.showFieldsErrors[index][field] = true;
    } else {
      if (
        isNil(this.showFieldsErrors[index]) ||
        isNil(this.showFieldsErrors[index][secondIndex])
      ) {
        this.showFieldsErrors[index] = {
          [secondIndex]: {
            [field]: true
          }
        };
        return;
      }
      this.showFieldsErrors[index][secondIndex][field] = true;
    }
  }

  /**
   * Скрытие ошибок для контрола формы
   *
   * @param field название контрола формы
   * @param index индекс
   * @param secondIndex индекс вложенный
   */
  disableShowFieldErrors(
    field: string,
    index?: number,
    secondIndex?: number
  ): void {
    if (isNil(index)) {
      this.showFieldsErrors[field] = false;
      return;
    }

    if (isNil(secondIndex)) {
      if (isNil(this.showFieldsErrors[index])) {
        this.showFieldsErrors[index] = { [field]: false };
        return;
      }
      this.showFieldsErrors[index][field] = false;
      return;
    }
    if (
      isNil(this.showFieldsErrors[index]) ||
      isNil(this.showFieldsErrors[index][secondIndex])
    ) {
      this.showFieldsErrors[index] = {
        [secondIndex]: {
          [field]: false
        }
      };
      return;
    }
    this.showFieldsErrors[index][secondIndex][field] = false;
    return;
  }

  /**
   * Метод проверки что введенное значение не содержит криллицы
   *
   * @param field наименование поля
   */
  disableCyrillic(field: string): void {
    const fieldValue = this.f[field].value;
    if (!fieldValue) {
      return;
    }
    const reg = /[А-Яа-яІіЁё]/;
    this.f[field].patchValue(fieldValue.replace(reg, ''));
  }

  /**
   * Вычиление признака отображения ошибки для контрола формы
   *
   * @param field название контрола формы
   * @param index индекс
   * @param secondIndex индекс
   * @returns признак отображения ошибки для контрола формы
   */
  isDisplayFieldErrors(
    field: string,
    index?: number,
    secondIndex?: number
  ): boolean {
    if (isNil(index)) {
      return this.showFieldsErrors[field] || false;
    }
    if (isNil(secondIndex)) {
      return this.showFieldsErrors[index]
        ? this.showFieldsErrors[index][field] || false
        : false;
    }
    return this.showFieldsErrors[index] &&
      this.showFieldsErrors[index][secondIndex]
      ? this.showFieldsErrors[index][secondIndex][field] || false
      : false;
  }

  /**
   * Смена признака отображения пароля
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Смена признака отображения подтверждения пароля
   */
  toggleShowConfirmPassword(): void {
    this.confirmShowPassword = !this.confirmShowPassword;
  }

  /**
   * Проверка надёжности пароля
   *
   * @param e событие нажатия клавиши
   */
  checkPasswordStrength(e: KeyboardEvent): void {
    this.weakPassword = true;
    this.averagePassword = false;
    this.strongPassword = false;
    const target = e.target as HTMLInputElement;
    if (
      (!isNil(target.value.match(/^(?=.*[a-z0-9])/g)) &&
        isNil(target.value.match(/(?=.*[A-Z])/g))) ||
      (!isNil(target.value.match(/^(?=.*[A-Z0-9])/g)) &&
        isNil(target.value.match(/(?=.*[a-z])/g)))
    ) {
      this.weakPassword = true;
      this.averagePassword = false;
      this.strongPassword = false;
    }

    if (
      (!isNil(
        target.value.match(
          /^(?=.*[!@#\$%\^&\*`;:\?\(\)\-_=\{\}\[\]\\\/><\.,'|~])/g
        )
      ) &&
        !isNil(target.value.match(/^(?=.*[0-9])/g))) ||
      (!isNil(target.value.match(/^(?=.*[a-z])/g)) &&
        !isNil(target.value.match(/^(?=.*[A-Z])/g)) &&
        !isNil(target.value.match(/^(?=.*[0-9])/g)))
    ) {
      this.averagePassword = true;
      this.strongPassword = false;
      this.weakPassword = false;
    }

    if (
      !isNil(
        target.value.match(
          /^(?=.*[!@#\$%\^&\*`;:\?\(\)\-_=\{\}\[\]\\\/><\.,'|~\+"])/g
        )
      ) &&
      !isNil(target.value.match(/^(?=.*[a-z])/g)) &&
      !isNil(target.value.match(/(?=.*[A-Z])/g)) &&
      !isNil(target.value.match(/^(?=.*[0-9])/g))
    ) {
      this.strongPassword = true;
      this.weakPassword = false;
      this.averagePassword = false;
    }
  }

  /**
   * Проверка введённого пароля
   *
   * @description Проверяет ввод и повторный ввод пароля на совпадение и устанавливает ошибку для
   * поля ввода повторного пароля в случае, если пароли отличаются.
   */
  checkPasswords(): void {
    const password = this.f.password;
    const confirm = this.f.confirm;
    if (!password || !confirm) {
      return;
    }

    this.deleteError('confirm', 'doNotMatch');
    if (
      !isNil(password.value) &&
      !isNil(confirm.value) &&
      password.value !== confirm.value
    ) {
      this.setErrors('confirm', {
        ...confirm.errors,
        doNotMatch: true
      });
    }
    this.checkSubmit();
  }

  /**
   * Уменьшение счётчика
   *
   * @description Устанавливает интервал на уменьшение счётчика каждую секунду.
   */
  countdown(): void {
    setInterval(() => {
      this.counter--;
    }, 1000);
  }

  /**
   * Удаление пробельных символов при вводе в инпут
   *
   * @param e событие ввода
   */
  removeWhitespaces(e: InputEvent): void {
    const input = e.target as HTMLInputElement;
    input.value = input.value.replace(/\s/g, '');
  }

  /**
   * Проверка валидности формы для разрешения или запрета на отправку формы
   */
  protected checkSubmit(): void {
    this.disabledSubmit = this.form.invalid;
  }

  /**
   * Поиск масок для значения поля ввода номера телефона
   *
   * @param value введённое значение
   * @returns маски для поля ввода
   */
  protected getPhoneNumberMasks(value: string) {
    if (!value) {
      return [];
    }
    if (isEmpty(value) || value.length === 1) {
      return [];
    }

    const search = value.replace(/\s/g, '');
    const matches = this.findCountryCodesMatches(search);
    const masks = matches.map((match: string) => {
      const prefix = match.replace(/[0-9]/g, '0');
      return prefix.includes(' ') ? `${prefix}0*` : `${prefix} 0*`;
    });

    return masks.length ? uniq(masks) : ['+0*'];
  }

  /**
   * Валидатор периода дат
   *
   * @param fieldFrom поле даты От
   * @param fieldTo поле даты До
   */
  protected validateDatePeriod(fieldFrom: string, fieldTo: string): void {
    if (!this.form) {
      return;
    }
    const valueFrom = this.f[fieldFrom].value;
    const valueTo = this.f[fieldTo].value;
    const valueFromFilled = !isNil(valueFrom) && valueFrom !== '';
    const valueToFilled = !isNil(valueTo) && valueTo !== '';
    if (
      valueFromFilled &&
      valueToFilled &&
      new Date(valueFrom) > new Date(valueTo)
    ) {
      this.setErrors(fieldTo, {
        ...this.f[fieldTo].errors,
        outOfRange: true
      });
      return;
    }
    this.deleteError(fieldTo, 'outOfRange');
  }

  /**
   * Валидатор даты выдачи паспорта
   */
  protected validatePassportDateOfIssue(): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      const value: Date = control.value;
      const wrongIssueDate: boolean = moment(value).isAfter();
      return wrongIssueDate ? { outOfRange: { issueDate: value } } : null;
    };
  }

  /**
   * Метод обработки ошибки периода
   *
   * @param key наименование поля
   */
  protected setOutOfRangeError(key: string): void {
    const errors = { ...this.f[key].errors };
    this.setErrors(key, {
      ...errors,
      outOfRange: true
    });
  }

  /**
   * Метод валидации даты
   *
   * @description валидирует что дата больше или равна сегодняшней
   * @param fieldName Наименование поля
   */
  protected validateFutureDate(fieldName: string): void {
    const field = this.f[fieldName];
    if (field.value && new Date(field.value) > new Date()) {
      field.setErrors({
        ...field.errors,
        dateInFuture: true
      });
      return;
    }
    if (field.errors?.dateInFuture) {
      delete field.errors.dateInFuture;
    }
  }

  /**
   * Метод валидации даты
   *
   * @description валидирует что дата от раньше даты до
   */
  protected validateDateFromBeforeDateTo(): void {
    const fromDateField = this.f.dateFrom;
    const toDateField = this.f.dateTo;
    const fromTimeField = this.f.timeFrom;
    const toTimeField = this.f.timeTo;
    const from =new Date(fromDateField.value);
    const to =new Date(toDateField.value);
    from.setHours(fromTimeField.value.getHours(), fromTimeField.value.getMinutes(),0,0);
    to.setHours(toTimeField.value.getHours(), toTimeField.value.getMinutes(),0,0);
    if (
      fromDateField.value &&
      toDateField.value &&
      from >= to
    ) {
      fromDateField.setErrors({
        ...fromDateField.errors,
        fromAfterTo: true
      });
      return;
    }
    if (fromDateField.errors?.fromAfterTo) {
      delete fromDateField.errors.fromAfterTo;
      fromDateField.setErrors(null);
      return;
    }
  }

  /**
   * Удаление ошибки из контрола формы
   *
   * @param field название контрола формы
   * @param errorField название поля ошибки
   */
  protected deleteError(field: string, errorField: string): void {
    const errors = { ...this.f[field].errors };
    if (errors[errorField]) {
      delete errors[errorField];
    }
    this.setErrors(field, errors);
  }

  /**
   * Установка ошибок для контрола формы
   *
   * @description Устанавливает объект ошибок в контрол формы, если в объекте есть ошибки, иначе
   * устанавливает null
   * @param field название контрола формы
   * @param errors объект, содержащий ошибки
   */
  protected setErrors(field: string, errors: ValidationErrors): void {
    const hasErrors = Object.keys(errors).length !== 0;
    this.f[field].setErrors(hasErrors ? errors : null);
  }

  /**
   * Поиск телефонных кодов
   *
   * @description На первой итерации осуществляется поиск точного совпадения с переданной строкой,
   * затем на каждой итерации убирается один символ в конце переданной строки и осуществляется
   * поиск точного совпадения с обрезанной строкой. Цикл прерывается либо когда найдены какие-либо
   * совпадения, либо когда осталась пустая строка.
   * @param search строка, по которой осуществляется поиск
   * @returns массив найденных совпадений
   */
  private findCountryCodesMatches(search: string): string[] {
    let matches: string[] = [];
    const codes = countryCodes.map(
      (countryCode: ICountryCode) => countryCode.dialCode
    );
    for (let i = 0; i < search.length; i++) {
      matches = codes.filter(
        code => code.replace(/\s/g, '') === search.slice(0, search.length - i)
      );
      if (matches.length) {
        break;
      }
    }
    return matches;
  }
}
