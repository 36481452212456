import { ILocalization } from '@app/core/models';

export const localizations: ILocalization[] = [
  { label: 'EN', localeId: 'en', route: '' },
  { label: 'CN', localeId: 'zh', route: '/zh' },
  { label: 'RU', localeId: 'ru', route: '/ru' },
  { label: 'UZ', localeId: 'uz', route: '/uz' },
];

export const defaultLocalization = localizations[0];
