import { isNil } from 'lodash';
import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Валидатор инпута на запрещённые символы
 *
 * @returns функцию, которая принимает валидируемый контрол и возвращает ошибку валидации
 * или null, если ошибки нет
 */
export function forbiddenSymbolsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbiddenSymbolsRegexp = /[\u0000-\u0020\u007F-\uFFFF]/;
    if (forbiddenSymbolsRegexp.test(control.value)) {
      return { forbiddenSymbols: true };
    }
    return;
  };
}

/**
 * Валидатор инпута на ввод кириллицы
 *
 * @returns функцию, которая принимает валидируемый контрол и возвращает ошибку валидации,
 * если введён символ кириллицы или null, если ошибки нет
 */
export function cyrillicSymbolsValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const reg = new RegExp('[а-яА-ЯёЁ]');
    if (!isNil(value)) {
      return reg.test(value) ? { forbiddenSymbols: true } : null;
    }
  };
}
