import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  AuthService,
  EventBusService,
  UserService
} from '../../../../core/services';
import { CUSTOMER } from '../../../../core';
import { UserPassportUpdateEvent } from '../../../../core/models/events';
/**
 * Компонент с сообщением об успешном обновлении email
 */
@Component({
  selector: 'app-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {
  /**
   * Признак процесса отправки данных
   *
   * @type {boolean}
   */
  obtaining = false;
  /**
   * Код подтверждения
   *
   * @type {string}
   */
  confirmationCode: string;
  /**
   * Признак отображения нотификации об успешном обновлении e-mail
   *
   * @type {boolean}
   */
  success: boolean;
  /**
   * Признак ошибки обновления e-mail
   *
   * @type {boolean}
   */
  error = false;
  /**
   * Текст ошибки
   *
   * @type {boolean}
   */
  backendError: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private eventBus: EventBusService
  ) {}

  /**
   * Хук инициализации
   *
   * @description Достает код подтверждения из параметров url, если код содержится в url,
   * запускает процесс подтверждения
   */
  ngOnInit(): void {
    const { code } = this.router.routerState.snapshot.root.queryParams;
    this.confirmationCode = code;
    if (!code) {
      return;
    }
    this.confirmEmail();
  }

  /**
   * Подтверждение обновления E-mail
   *
   * @description Вызываем эндпоинт подтверждения, если подтверждение прошло успешно,
   * то для залогиненного пользователя триггерим событие обновления паспорта, затем получаем
   * данные авторизованного пользователя. Если у пользователя роль покупателя, то редиректим
   * в Настройки аккаунта
   */
  confirmEmail() {
    this.obtaining = true;
    this.userService.confirmUpdatedEmail(this.confirmationCode).subscribe(
      () => {
        this.obtaining = false;
        if (!this.authService.isLoggedIn()) {
          this.success = true;
          return;
        }
        this.success = true;
        this.eventBus.emitUpdateUserPassportEvent(
          new UserPassportUpdateEvent()
        );
        this.authService.obtainAuthUser().subscribe(user => {
          if (user.roles.includes(CUSTOMER)) {
            this.router.navigate(['/profile-edit/account-settings']);
          }
        });
      },
      error => {
        this.errorHandler(error);
      }
    );
  }

  /**
   * Обработчик ошибок при подтверждении обновления e-mail
   *
   * @param error объект ошибки
   */
  errorHandler(error: any): void {
    this.error = true;
    if (!error?.error?.errors) {
      this.backendError = 'Something went wrong';
      this.obtaining = false;
      return;
    }
    if (error.error.errors.find(err => err.code === 'invalid_code')) {
      this.backendError = 'Confirmation link is wrong or expired';
    }
    this.obtaining = false;
  }
}
