<div class="no-results-wrapper">
  <div class="icon-wrapper">
    <svg-icon
      class="no-results m-bottom-40"
      [src]="
        notificationsTrigger
          ? 'assets/icons/no-notify.svg'
          : widgetTrigger
          ? 'assets/icons/no-checks.svg'
          :'assets/icons/not_found.svg'
      "
    ></svg-icon>
  </div>
  <div class="no-results-texts">
    <h4>
      {{
        customTitle
          ? customTitle
          : notificationsTrigger
          ? localeId === 'en'
            ? 'No notifications'
            : localeId === 'zh'
            ? '没有通知'
            : localeId === 'uz'? 'Bildirishnomalar yo\'q' :'Нет уведомлений'
          : localeId === 'en'
          ? 'No Results'
          : localeId === 'zh'
          ? '没有结果'
          : localeId === 'uz'? 'Natijalar yo\'q' :'Результаты отсутствуют'
      }}
    </h4>
    <p class="uptitle">
      {{
        customText
          ? customText
          : notificationsTrigger
          ? localeId === 'en'
            ? 'Sorry you don\'t have any notifications yet'
            : localeId === 'zh'
            ? '抱歉，您还没有任何通知'
            : localeId === 'uz'? 'Kechirasiz, sizda hali bildirishnomalar yo\'q' :'Извините, у вас еще нет уведомлений'
          : localeId === 'en'
          ? 'Sorry, Tax Free checks not found'
            : localeId === 'zh'
            ? '抱歉，未找到免稅支票'
            : localeId === 'uz'? 'Извините, чеков Tax Free не найдено' :'Извините, чеков Tax Free не найдено'
      }}
    </p>
  </div>
</div>
