import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './components/widget/widget.component';
import { WidgetRoutingModule } from './widget.routing.module';
import { WidgetLoginComponent } from './components/widget-login/widget-login.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetSuccessLoginComponent } from './components/widget-success-login/widget-success-login.component';
import { WidgetRestoreAccessComponent } from './components/widget-restore-access/widget-restore-access.component';
import { WidgetService } from '@app/core/services/widget.service';
import { WidgetEntryCodeComponent } from './components/widget-entry-code/widget-entry-code.component';
import { DirectivesModule } from '@app/core/directives/directives.module';
import { WidgetSuccessRestoreComponent } from './components/widget-success-restore/widget-success-restore.component';
import { WidgetRegistrationComponent } from './components/widget-registration/widget-registration.component';
import { WidgetVerifyRegistrationComponent } from './components/widget-verify-registration/widget-verify-registration.component';
import { WidgetInitCalculatorComponent } from './components/widget-init-calculator/widget-init-calculator.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { WidgetErrorMessageComponent } from './components/widget-error-message/widget-error-message.component';
import { NgxColorsModule } from 'ngx-colors';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
@NgModule({
  declarations: [
    WidgetComponent,
    WidgetLoginComponent,
    WidgetSuccessLoginComponent,
    WidgetRestoreAccessComponent,
    WidgetEntryCodeComponent,
    WidgetSuccessRestoreComponent,
    WidgetRegistrationComponent,
    WidgetVerifyRegistrationComponent,
    WidgetInitCalculatorComponent,
    WidgetErrorMessageComponent
  ],
  imports: [
    WidgetRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    DirectivesModule,
    NgSelectModule,
    NgbTooltipModule,
    NgxColorsModule
  ],
  exports: [WidgetComponent],
  providers: [WidgetService, WidgetLocalizationService]
})
export class WidgetModule {}
