import { Serializable } from '../Serializable';

export class FaqFilter extends Serializable {
  page = 1;
  pageSize = 10;
  search: string;

  toHttpParams(): any {
    const result = {};

    if (this.page) {
      result['page'] = this.page;
    }
    if (this.pageSize) {
      result['pageSize'] = this.pageSize;
    }

    return result;
  }
}
