import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Компонент 2 шага добавления ИМ - генерация скрипта инициализации и secretKey
 */
@Component({
  selector: 'app-generation-shop-script',
  templateUrl: './generation-shop-script.component.html',
  styleUrls: ['./generation-shop-script.component.scss']
})
export class GenerationShopScriptComponent {
  /**
   * Эмиттер события дизейбла перехода на следующий шаг
   *
   * @type {EventEmitter}
   */
  @Output() disableNextStep = new EventEmitter();
  /**
   * Скрипт инициализации виджета
   *
   * @type {string}
   */
  @Input() script: string;
  /**
   * Shop ID
   *
   * @type {string}
   */
  @Input() shopId: string;
  /**
   * secretKey
   *
   * @type {string}
   */
  @Input() secretKey: string;

  /**
   * Признак администратора
   *
   * @type {boolean}
   */
  @Input() isAdmin: boolean;
}
