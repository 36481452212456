<div class="d-flex flex-column" [ngClass]="parentOverflow ? 'body-overflow' : ''" (scroll)="onWindowScroll();">
  <app-header (headerToggler)="toggleMobileMenu()"></app-header>
  <main role="main" class="content-wrapper main">
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
  <app-cookie-policy-popup></app-cookie-policy-popup>
  <div class="errors-wrapper receipt-success" *ngIf="success">
    <div class="error-item success">
      <div
        class="success-message">
        <svg-icon class="icon" src="assets/icons/icon-receipt-success.svg"></svg-icon>
        <span i18n="@@success-receipt-creation-message">Tax Free form has been successfully created</span>
      </div>
    </div>
  </div>
</div>
