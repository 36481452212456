import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '@core/services';
import { catchError } from 'rxjs/operators';

/**
 * Интерцептор
 */
@Injectable()
export class SetAuthHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  /**
   * Простановко Bearer токена
   *
   * @param req объект запроса
   * @returns клон запроса, с проставленным параметром Authorization
   */
  setActualToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      }
    });
  }

  /**
   * Перехватывает запрос
   *
   * @description если Хедер имеет ключ authorization проставляет токен и передает запрос по цепочке
   * Иначе просто передает запрос по цепочке
   *
   * @param request запрос
   * @param next следующий интерцептор в цепочке или бекэнд, если интерцепторов не осталось
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const { headers } = request;
    if (headers.get('authorization')) {
      return next.handle(this.setActualToken(request)).pipe(
        catchError(err => {
          if (err.status === 401) {
            this.authService.logout();
          }
          return throwError(err);
        })
      );
    }
    return next.handle(request);
  }
}
