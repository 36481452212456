import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { Observable } from 'rxjs';
import { IFaq } from '../types/models';
import { FaqFilter } from '../models';
/**
 * Сервис для работы с FAQ
 */
@Injectable({
  providedIn: 'root'
})
export class FaqService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    }),
    withCredentials: true
  };

  constructor(private http: HttpClient, private authService: AuthService) { }

  /**
   * Получение статей FAQ
   *
   * @param filter фильтр
   * @returns Наблюдателя за получением статей FAQ
   */
  public getFaqs(filter?: FaqFilter): Observable<IFaq[]> {
    const httpParams = {};
    if (filter) {
      Object.keys(filter).forEach(
        (key: string) => (httpParams[key] = filter[key])
      );
    }
    const options = Object.assign(this.httpOptions, {
      params: httpParams
    });
    return this.http.get<IFaq[]>(this.getFaqPath(), options);
  }

  /**
   * Получение статьи по ID
   *
   * @param id ID статьи
   * @returns Наблюдателя за получением статьи по ID
   */
  public getFaq(id: string): Observable<IFaq> {
    return this.http.get<IFaq>(this.getFaqPath() + `/${id}`, this.httpOptions);
  }

  /**
   * Получение всех статей FAQ по локализации
   *
   * @param locale код локализации
   * @returns Наблюдателя за получением статей FAQ
   */
  public getAllFAQ(locale?: string): Observable<IFaq[]> {
    let params = {};
    if (locale && locale.length) {
      params = {
        locale
      };
    }
    return this.http.get<IFaq[]>(
      environment.basePortalApiUrl + api.shoppers.faq,
      { params }
    );
  }

  /**
   * Обновление статьи FAQ
   *
   * @param faq данные для обновления статьи
   * @param id id обновляемой статьи
   * @returns Наблюдателя за обновлением статьи
   */
  public updateFaq(faq: IFaq, id: string): Observable<IFaq> {
    return this.http.put<IFaq>(
      this.getFaqPath() + `/${id}`,
      faq,
      this.httpOptions
    );
  }

  /**
   * Создание статьи FAQ
   *
   * @param faq данные статьи
   * @returns Наблюдателя за созданием статьи
   */
  public createFaq(faq: IFaq): Observable<IFaq> {
    return this.http.post<IFaq>(this.getFaqPath(), faq, this.httpOptions);
  }

  /**
   * Удаление статьи FAQ
   *
   * @param id id статьи
   * @returns Наблюдатель за удалением статьи
   */
  public deleteFaq(id: string): Observable<IFaq> {
    return this.http.delete<IFaq>(
      this.getFaqPath() + `/${id}`,
      this.httpOptions
    );
  }

  /**
   * Генерация URL для запросов по статьям FAQ
   *
   * @returns URL для запросов по статьям FAQ
   */
  private getFaqPath(): string {
    return environment.basePortalApiUrl + api.shoppers.faq;
  }
}
