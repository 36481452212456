import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  OnDestroy
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from 'src/app/core/services/auth.service';
import {
  PORTAL_ADMIN,
  CUSTOMER,
  PARTNER,
  REPORT_ADMIN,
  OPERATOR, PAYMENT_OPERATOR
} from 'src/app/core/constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from '@app/core/models';
/**
 * Компонент для авторизации
 */
@Component({
  selector: 'app-check-auth',
  templateUrl: './check-auth.component.html'
})
export class CheckAuthComponent implements OnInit, OnDestroy {
  /**
   * Признак отображения хлебных крошек
   *
   * @type {boolean}
   */
  showBreadcrumbs = true;
  /**
   * Subject для дестроя подписок
   *
   * @type {Subject<void>}
   */
  protected destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    public config: NgbModalConfig,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  /**
   * Хук финализации
   *
   * @description завершает Subject
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Хук инициализации
   *
   * @description если юзер уже залогинен, переводит на главную страницу его личного кабинета, в соответсвии с ролью
   * Иначе начинает процесс получения токена
   */
  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        this.onResize();
      }, 1);

      if (!this.authService.isLoggedIn()) {
        this.authService.obtainAccessToken();
        return;
      }
      this.authService
        .obtainAuthUser()
        .pipe(takeUntil(this.destroy$))
        .subscribe(user => {
          this.navigateByRoles(user);
        });
    }
  }

  /**
   * Навигация по роли пользователя
   *
   * @param user пользователь
   */
  navigateByRoles(user: User) {
    const roles = user.roles;
    if (roles.includes(this.getAdminRoleName())) {
      this.router.navigate(['/admin/profile']);
      return;
    }
    if (roles.includes(this.getPartnerRoleName())) {
      this.router.navigate(['/partner']);
      return;
    }
    if (roles.includes(this.getCustomerRoleName())) {
      this.router.navigate(['/profile']);
      return;
    }
    if (roles.includes(this.getOperatorRoleName()) || roles.includes(this.getPaymentOperatorRoleName())) {
      this.router.navigate(['/operator/info']);
      return;
    }
    if (roles.includes(this.getReportAdminRoleName())) {
      this.router.navigate(['/reports']);
      return;
    }
  }
  /**
   * Обработчик изменения размеров окна
   *
   * @description проставляет признак отображения хлебных крошек
   */
  onResize() {
    if (isPlatformBrowser(this.platform)) {
      this.showBreadcrumbs = window.innerWidth < 1199;
    }
  }

  /**
   * Получение названия роли покупателя
   *
   * @returns название роли покупателя
   */
  private getCustomerRoleName(): string {
    return CUSTOMER;
  }

  /**
   * Получение названия роли партнера
   *
   * @returns название роли партнера
   */
  private getPartnerRoleName(): string {
    return PARTNER;
  }

  /**
   * Получение названия роли оператора
   *
   * @returns название роли оператора
   */
  private getOperatorRoleName(): string {
    return OPERATOR;
  }

  /**
   * Получение названия роли оператора платежей
   *
   * @returns название роли оператора платежей
   */
  private getPaymentOperatorRoleName(): string {
    return PAYMENT_OPERATOR;
  }

  /**
   * Получение названия роли администратора
   *
   * @returns название роли администратора
   */
  private getAdminRoleName(): string {
    return PORTAL_ADMIN;
  }

  /**
   * Получение названия роли администратора отчетов
   *
   * @returns название роли администратора отчетов
   */
  private getReportAdminRoleName(): string {
    return REPORT_ADMIN;
  }
}
