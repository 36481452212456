import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
/**
 * Экран отображения сообщения об отсутствии результатов
 */
@Component({
  selector: 'app-no-results',
  templateUrl: './no-resalts.component.html',
  styleUrls: ['./no-resalts.component.scss']
})
export class NoResaltsComponent {
  /**
   * Кастомный текст для отображения
   *
   * @type {string}
   */
  @Input() customText: string;
  /**
   * Признак страницы уведомлений
   *
   * @type {boolean}
   */
  @Input() notificationsTrigger: boolean;
  /**
   * Признак страницы виджетов
   */
  @Input() widgetTrigger: boolean;
  /**
   * Кастомный текст заголовка
   *
   * @type {string}
   */
  @Input() customTitle: string;

  constructor(@Inject(LOCALE_ID) public localeId: string) {}
}
