export const dictionaryTranslator = (items: any[], localeId: string) => {
  return items.map(item => {
    const filtered = item.localizedNames
      ? item.localizedNames.filter(
          localizedName => localizedName.locale === localeId
        )
      : [];
    if (filtered[0]) {
      item.name = filtered[0].value;
    }
    return item;
  });
};
