import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { AuthService } from './auth.service';
import { Currency, CurrencySort } from '../models';
import { CurrencyFilter } from '../models/Currency/CurrencyFilter';
import { Paginable } from '../classes/';

/**
 * Сервис для работы с валютой
 */
@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {
  /**
   * Http Опции
   */
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Получение страницы курсов валют
   *
   * @param filter фильтер
   * @param sort сортировка
   * @returns Наблюдателя за получением страницы курса валют
   */
  getCurrenciesPage(
    filter: CurrencyFilter,
    sort: CurrencySort
  ): Observable<Paginable> {
    const filterParams = Object.assign(filter, sort);
    const httpParams = {};
    Object.keys(filterParams).forEach(
      (key: string) => (httpParams[key] = filterParams[key])
    );
    const options = Object.assign(this.httpOptions, {
      params: httpParams
    });
    return this.http
      .get<Paginable>(this.getCurrenciesPagePath(), options)
      .pipe(map((response: any) => response));
  }

  /**
   * Получение списка курсов валют
   *
   * @returns Наблюдателя за получением списка курсов валют
   */
  getCurrencies(): Observable<Currency[]> {
    return this.http.get<any>(this.getCurrenciesPath(), this.httpOptions);
  }

  /**
   * Обновление курса валюты
   *
   * @param currency данные о курсе валюты
   * @returns Наблюдателя за обновлением курса валюты
   */
  updateCurrency(currency: Currency): Observable<any> {
    return this.http.put<any>(
      this.getCurrencyByIdPath(currency.id),
      currency,
      this.httpOptions
    );
  }

  /**
   * Генерация URL для списка курсов валют
   *
   * @returns URL для списка курсов валют
   */
  private getCurrenciesPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.allCurrencies;
  }

  /**
   * Генерация URL для получения страницы курсов валют
   *
   * @returns URL для получения страницы курсов валют
   */
  private getCurrenciesPagePath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.currencyPage;
  }

  /**
   * Генерация URL для получения валюты по ID
   *
   * @param currensyId id валюты
   * @returns URL для получения валюты по ID
   */
  private getCurrencyByIdPath(currensyId: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.dictionary.currency +
      `/${currensyId}`
    );
  }
}
