import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { api } from '../../config/api';
import { AuthService } from './auth.service';

/**
 * Сервис по загрузке изображений на сервер
 */
@Injectable({ providedIn: 'root' })
export class ImageProcessingService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getAccessToken()}`
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Получение предварительной ссылки на загружаемый файл
   *
   * @param type тип загржуаемого изображения
   * @returns Наблюдателя за получением предварительной ссылки на файл
   */
  getPreUploadUrl = (type: string): Observable<any> => {
    if (!type) {
      return null;
    }
    const params = {
      type
    };
    const options = Object.assign(this.httpOptions, { params });
    return this.http.get<any>(this.getPreUploadUrlPath(), options);
  };

  /**
   * Загрузка изображения
   *
   * @param image изображение
   * @param url URL файла
   * @returns наблюдателя за загрузкой изображения
   */
  upload = (image: any, url: string): Observable<any> =>
    this.http.put<any>(url, image);

  uploadMedia = (type: string, image: File) =>
    this.getPreUploadUrl(type).pipe(
      mergeMap((preUpload: any) => {
        // получили предварительный URL для изображения
        const { url } = preUpload;
        return new Observable(observer => {
          // сохраняем файл, чтобы он был доступен по предварительной ссылке
          this.upload(image, url).subscribe(data => {
            const imageUrl = url.split('?')[0];
            observer.next(imageUrl);
          });
        });
      })
    );

  /**
   * Генерация URL для получения предварительной ссылки на файл
   *
   * @returns URL для получения предварительной ссылки на файл
   */
  private getPreUploadUrlPath(): string {
    return environment.basePortalApiUrl + api.media.upload;
  }
}
