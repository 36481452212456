import { Injectable } from '@angular/core';

import { IFilter } from 'src/app/core/classes/IFilter';
import { TAX_FREE_OFFER_FILTERS_ID } from 'src/app/core/constants';
import { OfferFilter } from 'src/app/core/models/offer/OfferFilter';
import { TradingNetwork } from 'src/app/core/models';

/**
 * Сервис для хранения фильтра предложений
 */
@Injectable({
  providedIn: 'root'
})
export class StoreFilterService implements IFilter {
  /**
   * Простановка торговых сетей в хранилище
   *
   * @param tradeNetworkIds торговые сети
   */
  public addFilteringByTradeNetworks(tradeNetworkIds: TradingNetwork[]): void {
    const filter = this.getStoredFilter();
    filter.tradeNetworkIds = tradeNetworkIds.map(tn => ({
      id: tn.id,
      name: tn.name
    }));
    this.storeFilter(filter);
  }

  /**
   * Получение значения фильтра из хранилища
   *
   * @returns значение фильтра или объект по умолчанию, если хранилище пусто
   */
  public getStoredFilter(): OfferFilter {
    const storedFilter = localStorage.getItem(this.getFilterIdentifier());
    if (storedFilter) {
      const parsedStoredFilter = JSON.parse(storedFilter);
      return new OfferFilter(parsedStoredFilter);
    }
    return new OfferFilter({});
  }

  /**
   * Сохранения фильтра в хранилище
   *
   * @param offerFilter фильтр предложений
   */
  public storeFilter(offerFilter: OfferFilter): void {
    localStorage.setItem(
      this.getFilterIdentifier(),
      JSON.stringify(offerFilter)
    );
  }

  /**
   * Очистка фильтра торговых сетей в хранилище
   */
  public cleanFilteringByStore() {
    const filter = this.getStoredFilter();
    filter.tradeNetworkIds = [];
    this.storeFilter(filter);
  }
  /**
   * Полная очистка фильтра из хранилища
   */
  cleanAllFiltering() {
    localStorage.removeItem(this.getFilterIdentifier());
  }

  /**
   * Получение идентификатора фильтра в хранилище
   *
   * @returns идентификатор фильтра в хранилище
   */
  private getFilterIdentifier(): string {
    return TAX_FREE_OFFER_FILTERS_ID;
  }
}
