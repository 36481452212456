<div *ngIf="loading" class="widget-loader">
  <span class="inner"></span>
</div>
<div>
  <div localization-key="widget-title" id="widget-restore-header" class="widget-title">Приступите к покупкам
    уже сейчас!</div>
  <div class="widget-centered-decription">
    <span id="widget-restore-prompt" localization-key="widget-login-prompt" style="width: 100%">
      Заполните поля ниже для получения максимальной выгоды с сервисом
      Tax Free от Digitax</span></div>
  <form [formGroup]="form">
    <div class="form-group">
      <input
        #username
        type="text"
        formControlName="username"
        id="username-restore"
        autocomplete="off"
        class="widget-form-control"
        placeholder="Email \ Номер телефона"
        [ngClass]="{
          'is-invalid': isDisplayFieldErrors('username') && f.username.errors
        }"
        (blur)="changeField('username')"
        (focus)="focusField('username')"
        (input)="onKey()"
        (paste)="onPaste($event, 'username')"
        mask="{{ mask ? mask : '' }}"
        maxLength="{{ mask ? phoneMaxLength + 2 : emailMaxLength}}"
        [dropSpecialCharacters]="false"
        appMobileLabelStyle
        (keydown.space)="$event.preventDefault()"
        />
      <div
        *ngIf="isDisplayFieldErrors('username') && f.username.errors"
        class="widget-invalid-feedback">
        <div localization-key="widget-username-error-required" *ngIf="f.username.errors.required">
          {{ getErrorText('widget-username-error-required') }}
        </div>
        <div localization-key="widget-username-error-email-format" *ngIf="f.username.errors.emailMaxLength">
          {{ getEmailErrorText('widget-username-error-email-format') }}
        </div>
        <div localization-key="widget-username-error-format" *ngIf="f.username.errors.phoneMaxLength || f.username.errors.phoneMinLength || f.username.errors['mask']">
          {{ getErrorText('widget-username-error-format') }}
        </div>
        <div localization-key="widget-username-error-emailIncorrect" *ngIf="f.username.errors.emailIncorrect">
          {{ getErrorText('widget-username-error-emailIncorrect') }}
        </div>
        <div
          localization-key="widget-username-error-phoneStart"
          *ngIf="f.username.errors.phoneStart"
        >
          {{ getErrorText('widget-username-error-phoneStart') }}
        </div>
      </div>
    </div>
    <button
        [disabled]="loading || disabledSubmit"
        [ngClass]="loading || disabledSubmit ? 'disabled' : ''"
        class="widget-btn widget-btn-primary full-width m-bottom-6"
        (click)="onSubmit()"
        localization-key="widget-restore-btn"
        >
        Восстановить
    </button>
    <div localization-key="widget-login-footer" id="widget-restore-footer" class="widget-footer-text">* Возврат доступен только для нерезидентов РФ</div>
  </form>
  
</div>