export class PaymentOrder {
  taxFreeChequeId: string;
  paymentGateway: string;
  paymentStartDate: Date;
  paymentDate: Date;
  status: PaymentOrderStatus;
  detailedStatus: string;
  jsonRequest: string;
  jsonResponse: string;
  taxFreeChequeNumber: string;
  transactionId: string;
  error: string;
  id: string;
  active: boolean;

  constructor(rawData?) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}

export enum PaymentOrderStatus {
  PAID = '0',
  NOT_PAID = '1',
  FAILED = '2'
}
