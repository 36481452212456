/* eslint-disable max-len */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
import {
  getHTMLElementById,
  hasTextConfigChanges,
  hasUserChanges,
  setTextInElement
} from '@app/core/utils';
import { Subscription } from 'rxjs';

export const checkCircle =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGgNCiAgICBkPSJNMjIgMTEuMDgxOFYxMi4wMDE4QzIxLjk5ODggMTQuMTU4MiAyMS4zMDA1IDE2LjI1NjUgMjAuMDA5MyAxNy45ODM2QzE4LjcxODIgMTkuNzEwOCAxNi45MDMzIDIwLjk3NDMgMTQuODM1NCAyMS41ODU3QzEyLjc2NzQgMjIuMTk3MSAxMC41NTczIDIyLjEyMzcgOC41MzQ0NyAyMS4zNzY0QzYuNTExNjggMjAuNjI5MSA0Ljc4NDY1IDE5LjI0NzkgMy42MTA5NiAxNy40Mzg5QzIuNDM3MjcgMTUuNjI5OSAxLjg3OTc5IDEzLjQ4OTkgMi4wMjE2OCAxMS4zMzgxQzIuMTYzNTYgOS4xODYzOCAyLjk5NzIxIDcuMTM4MTQgNC4zOTgyOCA1LjQ5ODg5QzUuNzk5MzUgMy44NTk2NCA3LjY5Mjc5IDIuNzE3MiA5Ljc5NjE5IDIuMjQxOTZDMTEuODk5NiAxLjc2NjczIDE0LjEwMDMgMS45ODQxNSAxNi4wNyAyLjg2MTgyIg0KICAgIHN0cm9rZT0iIzBFN0I2RSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIC8+DQogIDxwYXRoIGQ9Ik0yMiA0TDEyIDE0LjAxTDkgMTEuMDEiIHN0cm9rZT0iIzBFN0I2RSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiDQogICAgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgLz4NCjwvc3ZnPg==';
export const logoutIcon =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTExLjU4MzQgOUgyLjEwMDExIiBzdHJva2U9IiNDMkMyQzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiDQogICAgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgLz4NCiAgPHBhdGggZD0iTTMuOTU4MzUgNi4yMDgyNUwxLjE2NjY5IDguOTk5OTJMMy45NTgzNSAxMS43OTE2IiBzdHJva2U9IiNDMkMyQzIiIHN0cm9rZS13aWR0aD0iMS41Ig0KICAgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgLz4NCiAgPHBhdGgNCiAgICBkPSJNNi41ODMzNiA0LjQxNjY3VjIuNUM2LjU4MzM2IDEuOTQ3NzIgNy4wMzEwNyAxLjUgNy41ODMzNiAxLjVIMTZDMTYuNTUyMyAxLjUgMTcgMS45NDc3MiAxNyAyLjVWMTUuNUMxNyAxNi4wNTIzIDE2LjU1MjMgMTYuNSAxNiAxNi41SDcuNTgzMzZDNy4wMzEwNyAxNi41IDYuNTgzMzYgMTYuMDUyMyA2LjU4MzM2IDE1LjVWMTMuNTgzMyINCiAgICBzdHJva2U9IiNDMkMyQzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIC8+DQo8L3N2Zz4NCiAg';
export const DESCRIPTION_PHONE =
  'Информация о возможностях сервиса Tax Free c Digitax, направлена на Ваш телефон';
export const DESCRIPTION_EMAIL =
  'Информация о возможностях сервиса Tax Free c Digitax, направлена на Ваш Email';
@Component({
  selector: 'app-widget-success-login',
  templateUrl: './widget-success-login.component.html',
  styleUrls: ['./widget-success-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetSuccessLoginComponent
implements OnChanges, OnDestroy, AfterViewInit {
  /**
   * Данные пользователя
   *
   * @type {any}
   */
  @Input() user: any;
  /**
   * Конфигурация Текстовок
   *
   * @type {any[]}
   */
  @Input() textConfig: any[];
  /**
   * Данные пользователя
   *
   * @type {boolean}
   */
  @Input() isPhone: boolean;

  /**
   * Эмиттер события логаута
   *
   * @type {EventEmitter}
   */
  @Output() logoutEvent = new EventEmitter();
  /**
   * Подписка на изменения языка
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Иконка над заголовком
   *
   * @type {string}
   */
  checkCircle = checkCircle;

  /**
   * Иконка выхода из аккаунта
   *
   * @type {string}
   */
  logoutIcon = logoutIcon;
  /**
   * Сообщение об отправке кода на email
   *
   * @type {string}
   */
  descriptionEmail = DESCRIPTION_EMAIL;
  /**
   * Сообщение об отправке кода на телефон
   *
   * @type {string}
   */
  descriptionPhone = DESCRIPTION_PHONE;

  constructor(private localizationService: WidgetLocalizationService) {
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(val => {
        this.setTexts();
      });
  }
  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (hasUserChanges(changes)) {
      const usernameEl = getHTMLElementById('widget-username');
      if (usernameEl && changes.user?.currentValue.passport.name) {
        usernameEl.innerHTML = this.getNameFromPasport(changes);
      } else {
        usernameEl.innerHTML = '';
      }

      const emailEl = getHTMLElementById('widget-email');
      if (changes.user?.currentValue && changes.user?.currentValue.contact) {
        emailEl.innerHTML = this.getLoginFromChanges(changes);
      } else {
        emailEl.innerHTML = '';
      }

      const description = getHTMLElementById('widget-login-message');
      if (!description) {
        return;
      }
      description.innerHTML = `${this.getDescriptionText(
        changes.isPhone.currentValue
      )}`;
    }
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  /**
   * Получение имени пользователя из изменений
   *
   * @param changes объект изменений
   * @returns имя пользователя
   */
  getNameFromPasport(changes: SimpleChanges) {
    return `${
      changes.user?.currentValue && changes.user?.currentValue.passport
        ? changes.user?.currentValue.passport.name
        : ''
    },`;
  }
  /**
   * Получение логина из изменений
   *
   * @param changes объект изменений
   * @returns логин
   */
  getLoginFromChanges(changes: SimpleChanges) {
    return `${
      changes.isPhone?.currentValue
        ? changes.user?.currentValue.contact.phoneNumbers[0]
        : changes.user?.currentValue.contact.emails[0]
    }`;
  }
  /**
   * Получение описания из конфига, если он есть или дефолтные значения
   *
   * @param isPhoneNumber признак телефона
   * @returns строку с описанием
   */
  getDescriptionText(isPhoneNumber: boolean) {
    if (this.textConfig) {
      const currentLanguage = this.localizationService.getCurrentLanguage();
      return this.localizationService.findByKey(
        isPhoneNumber
          ? 'widget-login-message-phone'
          : 'widget-login-message-email',
        currentLanguage.localeId
      );
    }
    return isPhoneNumber ? this.descriptionPhone : this.descriptionEmail;
  }

  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }
    const map = new Map([
      ['widget-login-info', 'widget-login-link'],
      ['widget-success-login', 'widget-success-login']
    ]);
    map.forEach((field, key) => {
      setTextInElement(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
    if (this.isPhone !== undefined) {
      const text = this.localizationService.findByKey(
        this.isPhone
          ? 'widget-login-message-phone'
          : 'widget-login-message-email',
        currentLanguage.localeId
      );
      setTextInElement('widget-login-message', text);
    }
  }

  /**
   * Обработчик нажатия на кнопку логаута
   */
  logout() {
    this.logoutEvent.emit();
  }
}
