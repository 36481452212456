import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { filter, map, mergeMap, takeWhile } from 'rxjs/operators';

import { PaymentOption } from './../models';
import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { DictionaryService } from './dictionary.service';

/**
 * Сервис для работы с платежными средствами
 */
@Injectable({
  providedIn: 'root'
})
export class PaymentOptionService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private dictionaryService: DictionaryService
  ) {}

  /**
   * Создание платежного средства
   *
   * @param paymentOption  Данные платежного средства
   * @returns Наблюдателя за созданием платежного средства
   */
  add(paymentOption: PaymentOption): Observable<PaymentOption> {
    return this.http.post<PaymentOption>(
      this.getPaymentOptionPath(),
      paymentOption,
      this.httpOptions
    );
  }

  /**
   * Обновление платежного средства
   *
   * @param paymentOption данные платежного средства
   * @returns Наблюдателя за обновлением платежного средства
   */
  setDefault(paymentOption: PaymentOption): Observable<PaymentOption> {
    return this.http.put<PaymentOption>(
      this.getSetDefaultPaymentOptionPath(paymentOption.paymentDetailsId),
      null,
      this.httpOptions
    );
  }

  /**
   * Удаление платежного средства
   *
   * @param paymentOption данные платежного средства
   * @returns Наблюдателя за удалением платежного средства
   */
  remove(paymentOption: PaymentOption): Observable<any> {
    return this.http.delete(
      this.getRemovePaymentOptionPath(paymentOption.paymentDetailsId),
      this.httpOptions
    );
  }

  /**
   * Получение списка платежных средств
   *
   * @param update признак получения актуальных данных без кеша
   * @returns Наблюдателя за получением списка платежных средств
   */
  getPaymentOptions(update: boolean = false): Observable<any> {
    return this.dictionaryService.obtainCurrency().pipe(
      mergeMap(currencies => {
        const customer$ = this.getCustomerObserver(update);
        let customerObject;
        return customer$.pipe(
          filter(customer => !!customer),
          takeWhile(() => !customerObject),
          map((customer: any) => {
            if (!customer.paymentDetails) {
              customer.paymentDetails = [];
            }
            customer.paymentDetails.map(paymentDetail => {
              paymentDetail.setCurrency(
                currencies.filter(
                  currency =>
                    currency.numericCode === paymentDetail.currencyCode
                )[0]
              );
              return paymentDetail;
            });
            customerObject = customer;
            return customer.paymentDetails;
          })
        );
      })
    );
  }

  /**
   * Получение платежных средств напрямую у клиента
   *
   * @returns Наблюдателя за получением платежных средств
   */
  getDirectPaymentOptions(): Observable<any> {
    return this.userService
      .getCustomer()
      .pipe(map(customer => customer.paymentDetails));
  }

  /**
   * Получение платежного средства по ID
   *
   * @param paymentDetailsId id платежного средства
   * @returns Наблюдателя за получением платежного средства по ID
   */
  getPaymentOptionById(paymentDetailsId: string): Observable<any> {
    return this.http.get(
      this.getPaymentOptionByIdPath(paymentDetailsId),
      this.httpOptions
    );
  }

  /**
   * Получение обзервера для клиента
   *
   * @description если поставлен признак подгрузки актуальных данных без кеша,
   * то загружаем клиента с бека, иначе проверям значение в authService.
   * Если значение уже есть, берем из currentCustomerValue,
   * иначе берем observer за обновлением клиента в authServic'e
   *
   * @param update признак подгрузки данных без кеша
   * @returns Наблюдателя за получением customer
   */
  private getCustomerObserver(update: boolean = false) {
    if (update) {
      return this.userService.getCustomer();
    }
    if (this.authService.currentCustomerValue) {
      return of(this.authService.currentCustomerValue);
    }
    return this.authService.currentCustomerObserver;
  }

  /**
   * Генерация URL для создания платежного средства
   *
   * @returns URL для создания платежного средства
   */
  private getPaymentOptionPath(): string {
    return environment.basePortalApiUrl + api.paymentOption.paymentOption;
  }
  /**
   * Генерация URL для установки по умолчанию платежного средства
   *
   * @param paymentDetailsId ID платежного средства
   * @returns URL для для установки по умолчанию платежного средства
   */
  private getSetDefaultPaymentOptionPath(paymentDetailsId: string): string {
    return (
      environment.basePortalApiUrl +
      api.paymentOption.setDefault.replace(
        '{paymentDetailsId}',
        paymentDetailsId
      )
    );
  }
  /**
   * Генерация URL для удаления платежного средства
   *
   * @param paymentDetailsId ID платежного средства
   * @returns URL для удаления платежного средства
   */
  private getRemovePaymentOptionPath(paymentDetailsId: string): string {
    return (
      environment.basePortalApiUrl +
      api.paymentOption.remove.replace('{paymentDetailsId}', paymentDetailsId)
    );
  }
  /**
   * Генерация URL для получения платежного средства по ID
   *
   * @param paymentDetailsId ID платежного средства
   * @returns URL для получения платежного средства по ID
   */
  private getPaymentOptionByIdPath(paymentDetailsId: string): string {
    return (
      environment.basePortalApiUrl +
      api.paymentOption.paymentOptionById.replace(
        '{paymentDetailsId}',
        paymentDetailsId
      )
    );
  }
}
