<div *ngIf="loading" class="widget-loader">
  <span class="inner"></span>
</div>
<div>
  <div localization-key="widget-title" id="widget-reg-header" class="widget-title">Приступите к покупкам
    уже сейчас!</div>
  <div class="widget-centered-decription">
    <span localization-key="widget-login-prompt" id="widget-reg-prompt" style="width: 100%">Заполните поля ниже для получения максимальной выгоды с сервисом
      Tax Free от Digitax</span></div>
  <form [formGroup]="form">
    <div class="form-group">
      <input #username type="text" formControlName="username" id="username-reg" autocomplete="off" class="widget-form-control"
        placeholder="Email \ Номер телефона" [ngClass]="{
          'is-invalid': isDisplayFieldErrors('username') && f.username.errors
        }" (blur)="changeField('username')" (focus)="focusField('username')" (input)="onKey()"
        (paste)="onPaste($event, 'username')" mask="{{ mask ? mask : '' }}"
        maxLength="{{ mask ? phoneMaxLength + 2 : emailMaxLength}}" [dropSpecialCharacters]="false"
        appMobileLabelStyle
        (keydown.space)="$event.preventDefault()" />
      <div *ngIf="isDisplayFieldErrors('username') && f.username.errors" class="widget-invalid-feedback">
        <div localization-key="widget-username-error-required" *ngIf="f.username.errors.required">
          {{ getErrorText('widget-username-error-required') }}
        </div>
        <div localization-key="widget-username-error-email-format" *ngIf="f.username.errors.emailMaxLength">
          {{ getErrorTextWithParams('widget-username-error-email-format') }}
        </div>
        <div localization-key="widget-username-error-format" *ngIf="f.username.errors.phoneMaxLength || f.username.errors.phoneMinLength || f.username.errors['mask']">
          {{ getErrorText('widget-username-error-format') }}
        </div>
        <div localization-key="widget-username-error-emailIncorrect" *ngIf="f.username.errors.emailIncorrect">
          {{ getErrorText('widget-username-error-emailIncorrect') }}
        </div>
        <div localization-key="widget-username-error-phoneStart" *ngIf="f.username.errors.phoneStart">
          {{ getErrorText('widget-username-error-phoneStart') }}
        </div>
        <div
          localization-key="widget-username-already-used-error" *ngIf="f.username.errors.already"
        >
          {{ getErrorText('widget-username-already-used-error') }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <input autocomplete="new-name" id="name" class="widget-form-control capitalize" formControlName="name" required
          [ngClass]="{
            'is-invalid':
              (submitted || isDisplayFieldErrors('name')) && f.name.errors
          }" placeholder="Имя" (blur)="changeField('name')" (focus)="focusField('name')"
          (input)="patternTextField('name')" (paste)="patternTextField('name')" />
        <div *ngIf="isDisplayFieldErrors('name') && f.name.errors" class="widget-invalid-feedback">
          <span localization-key="widget-firstname-error-required" *ngIf="f.name.errors.required">
            {{ getErrorText('widget-firstname-error-required') }}
          </span>
          <span localization-key="widget-firstname-error-maxLength" *ngIf="f.name.errors.maxlength">
            {{ getErrorTextWithParams('widget-firstname-error-maxLength') }}
          </span>
          <span localization-key="widget-firstname-error-name-incorrect" *ngIf=" !f.name.errors.maxlength && !f.name.errors.required && f.name.errors.nameIncorrect">
            {{ getErrorText('widget-firstname-error-name-incorrect') }}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <input autocomplete="new-name" appMobileLabelStyle id="surname" class="widget-form-control capitalize"
          formControlName="surname" name="surname" [ngClass]="{
            'is-invalid':
              (submitted || isDisplayFieldErrors('surname')) &&
              f.surname.errors
          }" placeholder="Фамилия" (blur)="changeField('surname')" (focus)="focusField('surname')"
          (input)="patternTextField('surname')" (paste)="patternTextField('surname')" />
        <div *ngIf="isDisplayFieldErrors('surname') && f.surname.errors" class="widget-invalid-feedback">
          <span localization-key="widget-surname-error-required" *ngIf="f.surname.errors.required">
            {{ getErrorText('widget-surname-error-required') }}
          </span>
          <span localization-key="widget-surname-error-maxLength" *ngIf="f.surname.errors.maxlength">
            {{ getErrorTextWithParams('widget-surname-error-maxLength') }}
          </span>
          <span localization-key="widget-surname-error-name-incorrect" *ngIf=" !f.surname.errors.maxlength && !f.surname.errors.required && f.surname.errors.nameIncorrect">
            {{ getErrorText('widget-surname-error-name-incorrect') }}
          </span>
        </div>
      </div>
    </div>
    <div class="form-group">
      <input class="widget-display-none" id="confirm" type="checkbox" required formControlName="confirm" />
      <label for="confirm" class="widget-checkbox-confirm-label">
        <a localization-key="widget-agreement-start-link" id="result_link" [href]="consentToPersonalDataProcessingLink" target="_blank">Согласен </a>
        <span localization-key="widget-agreement-middle-text">на обработку персональных данных и ознакомился с </span>
        <a localization-key="widget-agreement-end-link" id="policy_link"
          [href]="personalDataProcessingPolicyLink"
          target="_blank"
              >политикой обработки персональных данных</a></label>
    </div>
    <button localization-key="widget-send-btn" [disabled]="loading || disabledSubmit" [ngClass]="loading || disabledSubmit ? 'disabled' : ''"
      class="widget-btn widget-btn-primary full-width m-bottom-6" (click)="onSubmit()">
      Отправить
    </button>
    <div localization-key="widget-login-footer" id="widget-reg-footer" class="widget-footer-text">* Возврат доступен только для нерезидентов РФ</div>
  </form>

</div>