import { Injectable } from '@angular/core';

import { TaxFreeCheque } from '../models/TaxFreeCheque';

/**
 * Сервис хранения отслеживания чеков
 */
@Injectable({
  providedIn: 'root'
})
export class ChequeTrackerStoreService {
  /**
   * Хранилище для статусов возмещения по ID чека
   *
   * @type {TaxFreeCheque[]}
   */
  trackByIdCheques: TaxFreeCheque[];
  /**
   * Хранилище для статусов возмещения по Номеру телефона
   *
   * @type {TaxFreeCheque[]}
   */
  trackByPhoneNumberCheques: TaxFreeCheque[];
  /**
   * Хранилище для статусов возмещения по E-mail
   *
   * @type {TaxFreeCheque[]}
   */
  trackByEmailCheques: TaxFreeCheque[];
  /**
   * Хранилище для статусов возмещения по паспорту
   *
   * @type {TaxFreeCheque[]}
   */
  trackByPassportCheques: TaxFreeCheque[];

  /**
   * Сохранение в хранилище
   *
   * @param type тип запроса
   * @param data данные найденных чеков
   */
  public setResults(type: string, data: TaxFreeCheque[]) {
    if (!data) {
      return null;
    }

    switch (type) {
      case 'TRACK_BY_ID': {
        this.trackByIdCheques = data;
        break;
      }
      case 'TRACK_BY_PHONE_NUMBER': {
        this.trackByPhoneNumberCheques = data;
        break;
      }
      case 'TRACK_BY_EMAIL': {
        this.trackByEmailCheques = data;
        break;
      }
      case 'TRACK_BY_PASSPORT': {
        this.trackByPassportCheques = data;
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Восстановление из хранилища
   *
   * @param type типа запроса
   * @returns сохраненные чеки по типу запроса
   */
  public getStoredResults(type: string): TaxFreeCheque[] {
    switch (type) {
      case 'TRACK_BY_ID': {
        return this.trackByIdCheques;
      }
      case 'TRACK_BY_PHONE_NUMBER': {
        return this.trackByPhoneNumberCheques;
      }
      case 'TRACK_BY_EMAIL': {
        return this.trackByEmailCheques;
      }
      case 'TRACK_BY_PASSPORT': {
        return this.trackByPassportCheques;
      }
      default: {
        return this.trackByIdCheques;
      }
    }
  }
}
