/* eslint-disable id-denylist */
import { Contacts } from '../Contacts';

export class TaxFreeChequeCustomer {
  customerId: string;
  userId: string;
  userEmail: string;
  userPhoneNumber: string;
  contact: Contacts;
  countryCode: string;
  countryName: string;
  dateOfIssue: string;
  name: string;
  number: string;
  patronymic: string;
  series: string;
  surname: string;
  dateOfBirth: string;
  dateOfExpiration: string;

  /**
   * Преобразование объекта в строковое представление
   *
   * @returns текстовое представление Покупателя
   */
  public toString(): string {
    return `TaxFreeChequeCustomer (customerId:${this.customerId}, contact: ${
      !!this.contact ? this.contact.toString() : ''
    },
        countryCode: ${this.countryCode}, countryName: ${
  this.countryName
}, dateOfIssue: ${this.dateOfIssue}, name: ${this.name},
        number: ${this.number}, patronymic: ${this.patronymic}, series: ${
  this.series
}, surname: ${this.surname},
        dateOfBirth: ${this.dateOfBirth}, dateOfExpiration: ${
  this.dateOfExpiration
})`;
  }
}
