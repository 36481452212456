import { Component } from '@angular/core';
/**
 * Компонент лоадера
 */
@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent {}
