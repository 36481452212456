import { Injectable, EventEmitter } from '@angular/core';
import { OfferFilter } from 'src/app/core/models/offer';
import { OfferPartnerFilter } from 'src/app/core/models/partnerOffer/OfferPartnerFilter';

import {
  StatusChequeApplyFilter,
  UserPassportUpdateEvent,
  PartnerOfferRemoveEvent,
  PartnerOfferSaveEvent,
  RefreshCaptchaClickEvent,
  ContentPageSaveEvent,
  CashboxSaveEvent,
  UserRegistrationEvent
} from 'src/app/core/models/events';

/**
 * Сервис для проброса событий
 */
@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  /**
   * Эмиттер события применения фильтра статуса чеков
   *
   * @type {EventEmitter<StatusChequeApplyFilter>}
   */
  statusChequeApplyFilter: EventEmitter<
  StatusChequeApplyFilter
  > = new EventEmitter();
  /**
   * Эмиттер события обновления паспорта
   *
   * @type {EventEmitter<UserPassportUpdateEvent>}
   */
  userPassportUpdateEvent: EventEmitter<
  UserPassportUpdateEvent
  > = new EventEmitter();
  /**
   * Эмиттер события удаления предложения
   *
   * @type {EventEmitter<PartnerOfferRemoveEvent>}
   */
  partnerOfferRemoveEvent: EventEmitter<
  PartnerOfferRemoveEvent
  > = new EventEmitter();
  /**
   * Эмиттер события сохранения предложения
   *
   * @type {EventEmitter<PartnerOfferSaveEvent>}
   */
  partnerOfferSaveEvent: EventEmitter<
  PartnerOfferSaveEvent
  > = new EventEmitter();
  /**
   * Эмиттер события изменения фильтра статуса предложения
   *
   * @type {EventEmitter<OfferPartnerFilter>}
   */
  partnerOfferApplyFilterEvent: EventEmitter<
  OfferPartnerFilter
  > = new EventEmitter();
  /**
   * Эмиттер события нажатия на кнопку обновления капчи
   *
   * @type {EventEmitter<RefreshCaptchaClickEvent>}
   */
  refreshCaptchaClickEvent: EventEmitter<
  RefreshCaptchaClickEvent
  > = new EventEmitter();
  /**
   * Эмиттер события изменения фильтра магазинов в предложениях
   *
   * @type {EventEmitter<OfferFilter>}
   */
  appliedFilterByStore: EventEmitter<OfferFilter> = new EventEmitter();
  /**
   * Эмиттер события сохранения страницы контента
   *
   * @type {EventEmitter<ContentPageSaveEvent>}
   */
  contentPageSaveEvent: EventEmitter<ContentPageSaveEvent> = new EventEmitter();
  /**
   * Эмиттер события сохранения кассовой техники
   *
   * @type {EventEmitter<CashboxSaveEvent>}
   */
  cashboxSaveEvent: EventEmitter<CashboxSaveEvent> = new EventEmitter();
  /**
   * Событие регистрации пользователя
   *
   * @type {EventEmitter<UserRegistrationEvent>}
   */
  userRegistrationEvent: EventEmitter<
  UserRegistrationEvent
  > = new EventEmitter();

  constructor() {
    this.statusChequeApplyFilter = new EventEmitter<StatusChequeApplyFilter>();
    this.userPassportUpdateEvent = new EventEmitter<UserPassportUpdateEvent>();
    this.partnerOfferRemoveEvent = new EventEmitter<PartnerOfferRemoveEvent>();
    this.partnerOfferSaveEvent = new EventEmitter<PartnerOfferSaveEvent>();
    this.partnerOfferApplyFilterEvent = new EventEmitter<OfferPartnerFilter>();
    this.refreshCaptchaClickEvent = new EventEmitter<
    RefreshCaptchaClickEvent
    >();
    this.appliedFilterByStore = new EventEmitter<OfferFilter>();
    this.contentPageSaveEvent = new EventEmitter<ContentPageSaveEvent>();
    this.cashboxSaveEvent = new EventEmitter<CashboxSaveEvent>();
    this.userRegistrationEvent = new EventEmitter<UserRegistrationEvent>();
  }

  /**
   * Триггер события изменения фильтра статусов
   *
   * @param statusChequeApplyFilter фильтр со статусом
   */
  public emitStatusChequeApplyFilterEvent(
    statusChequeApplyFilter: StatusChequeApplyFilter
  ): void {
    this.statusChequeApplyFilter.emit(statusChequeApplyFilter);
  }

  /**
   * Триггер события изменения фильтра статуса предложения
   *
   * @param offerFilter фильтр статуса предложения
   */
  public emitPartnerOfferFilterEvent(offerFilter: OfferPartnerFilter): void {
    this.partnerOfferApplyFilterEvent.emit(offerFilter);
  }

  /**
   * Триггер события обновления паспортных данных пользователя
   *
   * @param userPassportUpdateEvent обновленные данные паспорта
   */
  public emitUpdateUserPassportEvent(
    userPassportUpdateEvent: UserPassportUpdateEvent
  ): void {
    this.userPassportUpdateEvent.emit(userPassportUpdateEvent);
  }

  /**
   * Триггер события удаления предложения
   *
   * @param partnerOfferRemoveEvent данные события удаления предложения
   */
  public emitPartnerOfferRemoveEvent(
    partnerOfferRemoveEvent: PartnerOfferRemoveEvent
  ): void {
    this.partnerOfferRemoveEvent.emit(partnerOfferRemoveEvent);
  }

  /**
   * Триггер события сохранения предложения
   *
   * @param partnerOfferSaveEvent данные события сохранения предложения
   */
  public emitPartnerOfferSaveEvent(
    partnerOfferSaveEvent: PartnerOfferSaveEvent
  ): void {
    this.partnerOfferSaveEvent.emit(partnerOfferSaveEvent);
  }

  /**
   * Триггер события обновления капчи
   *
   * @param refreshCaptchaClickEvent данные события обновления капчи
   */
  public emitRefreshCaptchaClickEvent(
    refreshCaptchaClickEvent: RefreshCaptchaClickEvent
  ): void {
    this.refreshCaptchaClickEvent.emit(refreshCaptchaClickEvent);
  }

  /**
   * Пересоздание эмиттеров капчи после дестроя компонентов
   */
  public refreshCaptchaEventEmitters(): void {
    this.refreshCaptchaClickEvent = new EventEmitter<
    RefreshCaptchaClickEvent
    >();
  }

  /**
   * Триггер события применения фильтра торговых сетей
   *
   * @param offerFilter фильтр торговых сетей
   */
  public emitByTradingNetworkApplyFilterEvent(offerFilter: OfferFilter): void {
    this.appliedFilterByStore.emit(offerFilter);
  }

  /**
   * Триггер события сохранения страницы контента
   *
   * @param contentPageSaveEvent данные события сохранения страницы контента
   */
  public emitContentPageSaveEvent(
    contentPageSaveEvent: ContentPageSaveEvent
  ): void {
    this.contentPageSaveEvent.emit(contentPageSaveEvent);
  }

  /**
   * Триггер события сохранения кассовой техники
   *
   * @param cashboxSaveEvent данные события сохранения кассовой техники
   */
  public emitCashboxSaveEvent(cashboxSaveEvent: CashboxSaveEvent): void {
    this.cashboxSaveEvent.emit(cashboxSaveEvent);
  }

  /**
   * Эмитит событие регистрации пользователя
   *
   * @param userRegistrationEvent параметры события
   */
  public emitUserRegistrationEvent(
    userRegistrationEvent: UserRegistrationEvent
  ): void {
    this.userRegistrationEvent.emit(userRegistrationEvent);
  }
}
