import { MetaDefinition } from '@angular/platform-browser';

/**
 * Список роутов с их метатегами
 */
export interface MetaObject {
  [route: string]: MetaDefinition[];
}
/**
 * Объект, содержащий основные SEO-данные.
 */
export interface SeoObject {
  title: string;
  meta: MetaDefinition[];
}

/**
 * URL на получение информации о метатегах.
 */
export const getMetaUrl = '';

/**
 * Метатеги по умолчанию
 */
/* eslint-disable */
export const defaultMetaTags = [
  {
    name: 'keywords',
    content:
      'tax free,tax free refund,tax free russia,digital tax free,tax free moscow,digitax tax free,shopping in russia,shop tax free,taxfree,tax free в россии,возврат tax free,магазины tax free,как вернуть tax free,как получить tax free,покупки tax free,процент tax free,диджитакс'
  },
  {
    name: 'description',
    content:
      'DigiTax is a new innovative Tax Free provider. We offer complete range of Tax Free shopping information across Europe. DigiTax is the best Tax Free services solution.'
  },
  {
    property: 'og:description',
    content:
      'DigiTax is a new innovative Tax Free provider. We offer complete range of Tax Free shopping information across Europe. DigiTax is the best Tax Free services solution.'
  },
  {
    property: 'og:title',
    content: 'Digitaxfree is an official Tax Free operator in Russia'
  }
];
/* eslint-enable */

/**
 * Базовые метатеги, которые добавляются к основным.
 */
export const baseMetaTags = [
  {
    property: 'og:type',
    content: 'website'
  },
  {
    property: 'og:site_name',
    content: 'DIGITAX is a digital Tax Free operator'
  },
  {
    property: 'og:locale',
    content: 'en_US'
  },
  {
    property: 'og:locale:alternate',
    content: 'ru_RU'
  },
  {
    property: 'og:locale:alternate',
    content: 'zh_CN'
  },
  {
    name: 'google-site-verification',
    content: '5JUp5nnkWOR2QZV1GIwel5xEAh34WyuymkNuIYPunVE'
  }
];

/**
 * Заголовок страницы по умолчанию.
 */
export const defaultTitle =
  'Digitaxfree is an official Tax Free operator in Russia';
