import {
  ALL_STATUSES,
  ALL_STATUSES_EN,
  ALL_STATUSES_RU,
  ALL_STATUSES_UZ,
  ALL_STATUSES_ZH,
  INVALID,
  INVALID_CLASSNAME,
  INVALID_EN,
  INVALID_RU,
  INVALID_ZH,
  PAID,
  PAID_CLASSNAME,
  PAID_EN,
  PAID_RU,
  PAID_ZH,
  WAIT_PAYMENT,
  WAIT_PAYMENT_CLASSNAME,
  WAIT_PAYMENT_EN,
  WAIT_PAYMENT_RU,
  WAIT_PAYMENT_ZH,
  WAIT_VALIDATION,
  WAIT_VALIDATION_CLASSNAME,
  WAIT_VALIDATION_EN,
  WAIT_VALIDATION_RU,
  WAIT_VALIDATION_ZH
} from '../constants';

export const chequeStatuses = [
  {
    code: ALL_STATUSES,
    name: ALL_STATUSES,
    localizedNames: [
      { locale: 'ru', value: ALL_STATUSES_RU },
      { locale: 'uz', value: ALL_STATUSES_UZ },
      { locale: 'en', value: ALL_STATUSES_EN },
      { locale: 'zh', value: ALL_STATUSES_ZH }
    ],
    className: 'all_statuses',
    order: 1
  },
  {
    code: WAIT_VALIDATION,
    name: 'Validation pending',
    localizedNames: [
      { locale: 'ru', value: WAIT_VALIDATION_RU },
      { locale: 'uz', value: WAIT_VALIDATION_EN },
      { locale: 'en', value: WAIT_VALIDATION_EN },
      { locale: 'zh', value: WAIT_VALIDATION_ZH }
    ],
    className: WAIT_VALIDATION_CLASSNAME,
    order: 3
  },
  {
    code: WAIT_PAYMENT,
    name: WAIT_PAYMENT,
    localizedNames: [
      { locale: 'ru', value: WAIT_PAYMENT_RU },
      { locale: 'uz', value: WAIT_PAYMENT_EN },
      { locale: 'en', value: WAIT_PAYMENT_EN },
      { locale: 'zh', value: WAIT_PAYMENT_ZH }
    ],
    className: WAIT_PAYMENT_CLASSNAME,
    order: 4
  },
  {
    code: PAID,
    name: PAID,
    localizedNames: [
      { locale: 'ru', value: PAID_RU },
      { locale: 'uz', value: PAID_EN },
      { locale: 'en', value: PAID_EN },
      { locale: 'zh', value: PAID_ZH }
    ],
    className: PAID_CLASSNAME,
    order: 5
  },
  {
    code: INVALID,
    name: INVALID,
    localizedNames: [
      { locale: 'ru', value: INVALID_RU },
      { locale: 'uz', value: INVALID_EN },
      { locale: 'en', value: INVALID_EN },
      { locale: 'zh', value: INVALID_ZH }
    ],
    className: INVALID_CLASSNAME,
    order: 6
  }
];
