import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileLabelStyleDirective } from './mobile-label-style.directive';
import { InputSpaceRemoveDirective } from './input-space-remove';
import { PasteNumbersDirective } from './paste-numbers.directive';
import { ConfirmCodeValidatorDirective } from './confirm-code-validator.directive';
import { DigitsOnlyDirective } from './digits-only.directive';
import { CoordValidatorDirective } from './coord-validate.directive';
import { PastePhonenumbersDirective } from './paste-phonenumbers.directive';
import { PassNumberDirective } from './pass-number.directive';
import { OnlyLettersDirective } from './only-letters.directive';
import { MaskDateDirective } from '@core/directives/date-mask.directive';
import { HtmlCommentDirective } from './html-comment.directive';
import { IntDigitsOnlyDirective } from './int-digits-only.directive';
import { OnlyCyrillicDirective } from './only-cyrillic.directive';

@NgModule({
  declarations: [
    MobileLabelStyleDirective,
    InputSpaceRemoveDirective,
    PasteNumbersDirective,
    ConfirmCodeValidatorDirective,
    DigitsOnlyDirective,
    CoordValidatorDirective,
    PastePhonenumbersDirective,
    PassNumberDirective,
    OnlyLettersDirective,
    MaskDateDirective,
    HtmlCommentDirective,
    IntDigitsOnlyDirective,
    OnlyCyrillicDirective
  ],
  imports: [CommonModule],
  exports: [
    MobileLabelStyleDirective,
    InputSpaceRemoveDirective,
    PasteNumbersDirective,
    ConfirmCodeValidatorDirective,
    DigitsOnlyDirective,
    CoordValidatorDirective,
    PastePhonenumbersDirective,
    PassNumberDirective,
    OnlyLettersDirective,
    MaskDateDirective,
    HtmlCommentDirective,
    IntDigitsOnlyDirective,
    OnlyCyrillicDirective
  ]
})
export class DirectivesModule {}
