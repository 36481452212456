import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, takeWhile } from 'rxjs/operators';

import {
  Contacts,
  PaymentOption,
  Passport,
  Customer,
  AccountSettings
} from 'src/app/core/models';

import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { AuthService } from 'src/app/core/services/auth.service';

/**
 * Сервис для работы с пользователем
 */
@Injectable({ providedIn: 'root' })
export class UserService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Обновление паспорта
   *
   * @param passport данные паспорта
   * @returns Наблюдателя за обновлением паспорта
   */
  updatePassport(passport: Passport): Observable<any> {
    return this.http.put<any>(
      this.getPassportPath(),
      passport,
      this.httpOptions
    );
  }

  /**
   * Обновление контактных данных пользователя
   *
   * @param contacts контактные данные пользователя
   * @returns Наблюдателя за обновлением контактных данных пользователя
   */
  updateUserContacts(contacts: Contacts): Observable<any> {
    return this.http.put<any>(
      this.getContactPath(),
      contacts,
      this.httpOptions
    );
  }

  /**
   * Получение паспортных данных
   *
   * @returns Наблюдателя за получением паспортных данных
   */
  getPassport(): Observable<Passport> {
    let passport;
    const observable$ = this.authService.currentCustomerValue
      ? of(this.authService.currentCustomerValue)
      : this.authService.currentCustomerObserver;
    return observable$.pipe(
      filter(customer => !!customer),
      takeWhile(() => !passport),
      switchMap((customer: Customer) => {
        passport = customer.passport
          ? new Passport(customer.passport)
          : new Passport();
        return of(passport);
      })
    );
  }

  /**
   * Получение контактных данных
   *
   * @param isUpdate признак для получения данных без использования кеша
   * @returns Наблюдателя за получением контактных данных
   */
  getContacts(isUpdate: boolean = false): Observable<Contacts> {
    let contacts;
    const observable$ =
      isUpdate || !this.authService.currentCustomer
        ? this.getCustomer()
        : of(this.authService.currentCustomerValue);
    return observable$.pipe(
      filter(customer => !!customer),
      takeWhile(() => !contacts),
      switchMap((customer: Customer) => {
        contacts = customer.contact
          ? new Contacts(customer.contact)
          : new Contacts();
        return of(contacts);
      })
    );
  }

  /**
   * Получение данных покупателя
   *
   * @returns Наблюдателя за получением данных покупателя
   */
  getCustomer(): Observable<Customer> {
    return this.http
      .get<Customer>(this.getCustomerPath(), this.httpOptions)
      .pipe(
        map(customer => {
          if (!customer) {
            customer = new Customer();
          }
          if (customer.paymentDetails) {
            customer.paymentDetails = customer.paymentDetails.map(
              paymentDetail =>
                new PaymentOption(
                  paymentDetail.paymentDetailsId,
                  paymentDetail.currencyCode,
                  paymentDetail.currencyLetterCode,
                  paymentDetail.paymentType,
                  paymentDetail.recipientName,
                  paymentDetail.recipientSurname,
                  paymentDetail.recipientNumber,
                  paymentDetail.expirationDate,
                  paymentDetail.actual
                )
            );
          }
          // прокидывает новое значение в Subject в сервисе авторизации
          this.authService.currentCustomer.next(customer);
          return customer;
        })
      );
  }

  /**
   * Получение данных покупателя и настроек аккаунта
   *
   * @returns Наблюдателя за получением данных покупателя и настроек аккаунта
   */
  getCustomerAndAccountSettings(): Observable<any> {
    return forkJoin([this.getCustomer(), this.getAccountSettings()]).pipe(
      map(([customer, accountSettings]) => {
        if (!customer) {
          customer = new Customer();
        }
        customer.accountSettings = accountSettings;
        return customer;
      })
    );
  }

  /**
   * Получение настроек аккаунта
   *
   * @returns Наблюдателя за получением настроек аккаунта
   */
  getAccountSettings(): Observable<AccountSettings> {
    return this.http.get<AccountSettings>(
      this.getAccountSettingsPath(),
      this.httpOptions
    );
  }

  /**
   * Обновление настроек аккаунта
   *
   * @param accountSettings настройки аккаунта
   * @returns Наблюдателя за обновлением настроек аккаунта
   */
  updateAccountSettings(accountSettings: any): Observable<any> {
    return this.http.put<AccountSettings>(
      this.getAccountSettingsPath(),
      accountSettings,
      this.httpOptions
    );
  }

  /**
   * Подтверждение обновления Email
   *
   * @param code код верификации
   * @returns Наблюдателя за подтверждением обновления Email
   */
  confirmUpdatedEmail = (code: string): Observable<any> =>
    this.http.post(
      this.getEmailChangeConfirmationPath(code),
      null,
      this.httpOptions
    );

  /**
   * Подтверждение телефона
   *
   * @param code код верификации
   * @param phone номер телефона
   * @returns Наблюдателя за подтверждением телефона
   */
  confirmPhoneNumber = (code: string, phone: string): Observable<any> =>
    this.http.get<any>(
      this.getConfirmPhoneNumberExistPath(code, phone),
      this.httpOptions
    );

  /**
   * Сбросить пароль пользователю через администратора
   *
   * @param userId идентификатор пользователя
   * @param restoreType тип восстановления
   * @returns Наблюдателя за сбросом пароля
   */
  resetUsersPassword = (
    userId: string,
    restoreType: string
  ): Observable<any> => {
    const salt = new Date().getTime();
    return this.http.get<any>(
      this.getResetPasswordPath() +
        `?${salt}` +
        `&userId=${userId}` +
        `&restoreType=${restoreType}`,
      this.httpOptions
    );
  };

  /**
   * Обновление телефона
   *
   * @param code код верификации
   * @param phone номер телефона
   * @returns Наблюдателя за обновлением телефона
   */
  confirmUpdatedPhone = (code: string, phone: string): Observable<any> =>
    this.http.post<any>(
      this.getUpdatePhoneNumberPath(code, phone),
      null,
      this.httpOptions
    );

  /**
   * Подтверждение обновления телефона
   *
   * @param code код верификации
   * @param phone номер телефона
   * @returns Наблюдателя за подтверждением обновления телефона
   */
  updatePhone = (code: string, phone: string): Observable<any> =>
    this.confirmPhoneNumber(code, phone).pipe(
      mergeMap(() => this.confirmUpdatedPhone(code, phone))
    );
  /**
   * Генерация URL для получения данных покупателя
   *
   * @returns URL для получения данных покупателя
   */
  private getCustomerPath(): string {
    return environment.basePortalApiUrl + api.customer.data;
  }
  /**
   * Генерация URL для получения паспортных данных
   *
   * @returns URL для получения паспортных данных
   */
  private getPassportPath(): string {
    return environment.basePortalApiUrl + api.customer.passport;
  }
  /**
   * Генерация URL для получения контактных данных
   *
   * @returns URL для получения контактных данных
   */
  private getContactPath(): string {
    return environment.basePortalApiUrl + api.customer.contact;
  }
  /**
   * Генерация URL для получения настроек аккаунта
   *
   * @returns URL для получения настроек аккаунта
   */
  private getAccountSettingsPath(): string {
    return environment.basePortalApiUrl + api.customer.accountSettings;
  }
  /**
   * Генерация URL для подтверждения изменения Email
   *
   * @param code код верификации
   * @returns URL для подтверждения изменения Email
   */
  private getEmailChangeConfirmationPath(code: string): string {
    return (
      environment.auth.baseApiUrl +
      api.account.confirmEmail.replace('{code}', code)
    );
  }

  /**
   * Генерация URL для верификации телефона
   *
   * @param code код верификации
   * @param phone номер телефона
   * @returns URL для верификации телефона
   */
  private getConfirmPhoneNumberExistPath(code: string, phone: string): string {
    return (
      environment.basePortalApiUrl +
      api.customer.verifyPhoneBySmsCode
        .replace('{code}', code)
        .replace('{login}', encodeURIComponent(phone))
    );
  }
  /**
   * Генерация URL для обновления телефона
   *
   * @param code код верификации
   * @param phone номер телефона
   * @returns URL для обновления телефона
   */
  private getUpdatePhoneNumberPath(code: string, phone: string): string {
    return (
      environment.basePortalApiUrl +
      api.account.confirmPhoneNumber
        .replace('{code}', code)
        .replace('{phone}', encodeURIComponent(phone))
    );
  }
  /**
   * Генерация URL для сброса пароля через администратора
   *
   * @returns URL сброса пароля через администратора
   */
  private getResetPasswordPath(): string {
    return environment.basePortalApiUrl + api.customer.resetPassword;
  }
}
