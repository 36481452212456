import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getMetaUrl, MetaObject } from './meta.config';
import { Observable, of } from 'rxjs';

/**
 * Метасервис
 *
 * @class
 * @classdesc Сервис, который получает актуальные метатеги.
 */
@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private http: HttpClient) {}

  /**
   * Метод получения метатегов по api.
   *
   * @returns Возвращает Observable на получение метатегов
   * либо пустой Observable.
   */
  getMeta(): Observable<MetaObject | object> {
    return getMetaUrl ? this.http.get(getMetaUrl) : of({});
  }
}
