export const contentPageStatuses = [
  {
    code: 'NEW',
    name: 'NEW',
    localizedNames: [
      { locale: 'ru', value: 'Новая' },
      { locale: 'uz', value: 'Новая' },
      { locale: 'en', value: 'New' },
      { locale: 'zh', value: '新' }
    ]
  },
  {
    code: 'PUBLISHED',
    name: 'PUBLISHED',
    localizedNames: [
      { locale: 'ru', value: 'Опубликовано' },
      { locale: 'uz', value: 'Опубликовано' },
      { locale: 'en', value: 'Published' },
      { locale: 'zh', value: '发布时间' }
    ]
  }
];
