import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { AbstractPhoneMaskComponent } from '@app/core/classes';
import {
  emailAndPhoneValidator,
  isPhone
} from 'src/app/config/validators/emailPhoneValidator';
import { takeUntil, finalize } from 'rxjs/operators';
import { contactsEdit, passportEdit } from '../widget-login/widget-login.component';
import { States } from '../widget/widget.component';
import { WidgetService } from '@app/core/services/widget.service';
import { DOCUMENTS_URLS } from '@app/core/constants';
import {
  hasTextConfigChanges,
  setPlaceholder,
  setTextInElement
} from '@app/core/utils';
import { Subscription } from 'rxjs';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
export const WIDGET_REGISTRATION_DATA = 'WIDGET_REGISTRATION_DATA';
/**
 * Компонент экспресс регистрации виджета
 */
@Component({
  selector: 'app-widget-registration',
  templateUrl: './widget-registration.component.html',
  styleUrls: ['./widget-registration.component.scss']
})
export class WidgetRegistrationComponent extends AbstractPhoneMaskComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Конфигурация Текстовок
   *
   * @type {any[]}
   */
  @Input() textConfig: any[];
  /**
   * Эмиттер события отправки кода
   *
   * @type {EventEmitter}
   */
  @Output() sendCode = new EventEmitter();
  /**
   * Подписка на изменения языка
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Ограничение длины имени
   *
   * @type {number}
   */
  firstNameMaxLength = passportEdit.firstName.maxLength;
  /**
   * Ограничение длины фамилии
   *
   * @type {number}
   */
  lastNameMaxLength = passportEdit.lastName.maxLength;
  /**
   * Максимальная длина номера телефона
   *
   * @type {number}
   */
  phoneMaxLength: number = contactsEdit.phone.maxLength;
  /**
   * Максимальная длина e-mail
   *
   * @type {number}
   */
  emailMaxLength: number = contactsEdit.email.maxLength;
  /**
   * Ссылка на документ согласия
   *
   * @type {string}
   */
  consentToPersonalDataProcessingLink: string;
  /**
   * Ссылка на документ с политикой обработки перс.данных
   *
   * @type {string}
   */
  personalDataProcessingPolicyLink: string;
  /**
   * Текущий язык
   *
   * @type {any}
   */
  currentLanguage: any;

  constructor(
    private formBuilder: FormBuilder,
    private widgetService: WidgetService,
    private localizationService: WidgetLocalizationService
  ) {
    super();
  }
  /**
   * Хук инициализации компонента
   *
   * @description Инициализирует форму логина и подписывается на события формы и изменения
   * параметров роута.
   */
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: [null, [emailAndPhoneValidator(), Validators.required]],
      name: [
        null,
        [
          Validators.required,
          Validators.maxLength(this.firstNameMaxLength),
          this.nameValidators()
        ]
      ],
      surname: [
        null,
        [
          Validators.required,
          Validators.maxLength(this.lastNameMaxLength),
          this.nameValidators()
        ]
      ],
      confirm: [null, Validators.required]
    });
    this.updateLinks();
    this.mask = null;
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkSubmit();
    });
    this.restoreState();
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(val => {
        this.currentLanguage = val;
        this.setTexts();
        this.updateLinks();
      });
  }

  /**
   * Обновление ссылок на документы
   */
  updateLinks() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }
    const documentsUrls =
      DOCUMENTS_URLS[currentLanguage.localeId] || DOCUMENTS_URLS.en;
    this.consentToPersonalDataProcessingLink =
      documentsUrls.consentToPersonalDataProcessing;
    this.personalDataProcessingPolicyLink =
      documentsUrls.personalDataProcessingPolicy;
  }

  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  /**
   * Восстановление состояния
   */
  restoreState() {
    const restore = localStorage.getItem(WIDGET_REGISTRATION_DATA);
    if (!restore) {
      return;
    }
    this.form.patchValue(JSON.parse(restore));
  }
  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }
    const map = new Map([
      ['widget-reg-header', 'widget-title'],
      ['widget-reg-footer', 'widget-login-footer'],
      ['widget-reg-prompt', 'widget-login-prompt']
    ]);
    map.forEach((field, key) => {
      setTextInElement(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
    const mapPlaceholders = new Map([
      ['username-reg', 'widget-login-placeholder'],
      ['name', 'widget-firstname-placeholder'],
      ['surname', 'widget-surname-placeholder']
    ]);
    mapPlaceholders.forEach((field, key) => {
      setPlaceholder(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
  }

  /**
   * Обработчик события вставки в поле логина
   *
   * @param event переменная, содержащая информацию о буфере обмена
   * @param fieldName имя поля
   */
  onPaste(event: ClipboardEvent, fieldName: string): void {
    this.mask = undefined;
    event.preventDefault();
    const val = event.clipboardData.getData('text/plain').replace(/\s/g, '');
    if (fieldName === 'username' && val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
    this.f[fieldName].setValue(
      event.clipboardData.getData('text/plain').replace(/\s/g, '')
    );
    setTimeout(() => this.f[fieldName].updateValueAndValidity(), 0);
  }

  /**
   * Обработчик события ввода в поле логина
   */
  onKey(): void {
    const val = this.f.username.value;
    this.patternTextField('username');
    this.mask = undefined;
    if (val === '') {
      return;
    }
    if (val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
  }

  /**
   * Сабмит формы
   */
  onSubmit() {
    this.loading = true;
    const phoneOrEmail = this.f.username.value;
    const user: any = {};
    user.name = this.f.name.value;
    user.surname = this.f.surname.value;
    this.widgetService
      .registration(phoneOrEmail.replace(/\s/g, ''), user.name, user.surname)
      .pipe(
        finalize(() => (this.loading = false)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        res => {
          this.saveDataToStorage();
          this.sendCode.emit({
            registrationPhoneOrEmail: phoneOrEmail,
            isPhone: isPhone(phoneOrEmail.replace(/\s/g, '')),
            user,
            newState: States.VERIFY_REGISTRATION
          });
        },
        err => {
          if (err.status === 409) {
            this.f.username.setErrors({ ['already']: true });
            this.enableShowFieldErrors('username');
          }
        }
      );
  }

  /**
   * Сохранение данных в хранилище
   */
  saveDataToStorage() {
    localStorage.setItem(
      WIDGET_REGISTRATION_DATA,
      JSON.stringify(this.form.value)
    );
  }

  /**
   * Получение локализованной ошибки по длине email
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getErrorTextWithParams(fieldName: string) {
    const textNode = this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
    if (fieldName === 'widget-username-error-email-format') {
      return textNode.replace(
        '{{ emailMaxLength }}',
        this.emailMaxLength.toString()
      );
    }
    if (fieldName === 'widget-firstname-error-maxLength') {
      return textNode.replace(
        '{{ firstNameMaxLength }}',
        this.firstNameMaxLength.toString()
      );
    }
    return textNode.replace(
      '{{ lastNameMaxLength }}',
      this.lastNameMaxLength.toString()
    );
  }

  /**
   * Получение локализованной ошибки
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getErrorText(fieldName: string) {
    return this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
  }

  /**
   * Валидатор для имени
   *
   * @returns валидатор имени
   */
  private nameValidators = (): ValidatorFn => (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const nameRegExp = /^[\p{Alphabetic}\p{M}\s-]+$/gu;
    const value = control.value;
    if (!nameRegExp.test(value)) {
      return { nameIncorrect: { value } };
    }
  };
}
