import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { WebsocketService } from 'src/app/modules/websocket/websocket.service';
import { websocketEvents } from 'src/app/config/websocket.events';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { Paginable } from '../classes/Paginable';
import { PaginableClass } from '../models/PaginableClass';
import { NotificationModel } from '../models/NotificationModel';
/**
 * Сервис уведомления
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };
  /**
   * Наблюдатель за появлением страницы уведомлений
   *
   * @type {Observable<Paginable>}
   */
  private notifyListObserver: Observable<Paginable>;
  /**
   * Наблюдатель за появлением уведомления
   *
   * @type {Observable<any>}
   */
  private notifyObserver: Observable<any>;
  /**
   * Признак успешного коннекта к веб сокету
   *
   * @type {boolean}
   */
  private connectStatus: boolean;
  /**
   * Subject для передачи события успешного создания чека
   *
   * @type {Subject<boolean>}
   */
  private chequeCreated: Subject<boolean> = new Subject<boolean>();

  constructor(
    private wsService: WebsocketService,
    private http: HttpClient,
    private authService: AuthService
  ) {
    // инициализируем наблюдателей за событиями
    this.notifyListObserver = this.wsService.on<Paginable>([
      websocketEvents.notificationList
    ]);
    this.notifyObserver = this.wsService.on<any[]>([
      websocketEvents.notification
    ]);
    this.wsService.status.subscribe(status => (this.connectStatus = status));
  }

  /**
   * Геттер наблюдателя за появлением страницы уведомлений
   *
   * @returns наблюдателя за появлением страницы уведомлений
   */
  getNotifyListObserver() {
    return this.notifyListObserver;
  }

  /**
   * Геттер наблюдателя за появлением уведомления
   *
   * @returns наблюдателя за появлением уведомления
   */
  getNotifyObserver() {
    return this.notifyObserver;
  }

  /**
   * Загрузка списка уведомлений
   *
   * @param data данные о размере и текущей странице
   * @returns Наблюдателя за загрузкой списка уведомлений
   */
  getNotifications(data: {
    page: number;
    pageSize: number;
  }): Observable<PaginableClass<NotificationModel>> {
    const options = Object.assign(this.httpOptions, {
      params: Object.assign(data)
    });
    return this.http.get<PaginableClass<NotificationModel>>(
      this.getNotificationPath(),
      options
    );
  }

  /**
   * Удаление уведомления
   *
   * @param id id Уведомления
   * @returns Наблюдателя за удаление уведомления
   */
  markRead = (id: string): Observable<any> =>
    this.http.post(this.getRemoveNotificationPath(id), {}, this.httpOptions);

  /**
   * Обновление признака успешного создания чека, для выброса нотификации
   *
   * @param flag признак создания
   */
  updateChequeCreatedFlag(flag: boolean) {
    this.chequeCreated.next(flag);
  }

  /**
   * Получение наблюдателя за обновлением флага создания чека
   *
   * @returns наблюдателя за обновлением флага создания чека
   */
  getChequeObserver() {
    return this.chequeCreated.asObservable();
  }

  /**
   * Генерация URL для получения уведомления по ID
   *
   * @param id id уведомления
   * @returns URL для получения уведомления по ID
   */
  private getRemoveNotificationPath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      `${api.customer.removeNotifications}/${id}`
    );
  }

  /**
   * Генерация URL уведомлений
   *
   * @returns URL уведомлений
   */
  private getNotificationPath(): string {
    return environment.baseAccountingApiUrl + api.customer.notifications;
  }
}
