export class PaymentDetails {
    actual: string;
    currencyLetterCode: string;
    currencyCode: string;
    paymentDetailsId: string;
    paymentType: string;
    recipientName: string;
    recipientSurname: string;
    recipientNumber: string;
    expirationDate: string;

    public toString(): string {
        return `PaymentDetails (actual: ${this.actual},
        currencyCode: ${this.currencyCode},
        paymentDetailsId: ${this.paymentDetailsId},
        paymentType: ${this.paymentType},
        recipientName: ${this.recipientName},
        recipientSurname: ${this.recipientSurname},
        expirationDate: ${this.expirationDate},
        recipientNumber: ${this.recipientNumber}})`;
    }
}
