import { Serializable } from './Serializable';

export class Config extends Serializable {
  /**
   * URL API из окружения
   *
   * @type {string}
   */
  api: string;
  /**
   * URL сервиса авторизации из окружения
   *
   * @type {string}
   */
  auth: string;
  /**
   * URL портала из окружения
   *
   * @type {string}
   */
  portal: string;
}
