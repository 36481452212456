import { Injectable } from '@angular/core';
import {
  ACTIVE,
  TAX_FREE_PARTNER_OFFER_FILTER_ID
} from 'src/app/core/constants';
import { OfferPartnerFilter } from 'src/app/core/models/partnerOffer';
/**
 * Сервис для работы с фильтрами предложений Партнера
 */
@Injectable({
  providedIn: 'root'
})
export class PartnerOffersFilterService {
  /**
   * Сохранение фильтра по умолчанию
   *
   * @param displayValue Отображаемое значение
   * @param active Признак активных предложений
   */
  public addFilteringByActive(
    displayValue: string,
    active: boolean = true
  ): void {
    const filter = this.getStoredFilter();
    filter.active = active;
    filter.displayValue = displayValue;
    this.storeFilter(filter);
  }

  /**
   * Получение значения фильтра из хранилища
   *
   * @description если в хранилище есть фильтр, то создаем объект из сохраненного значения
   * иначе возвращаем объект по умолчанию
   * @returns Значение фильтра для предложений
   */
  public getStoredFilter(): OfferPartnerFilter {
    const storedFilter = localStorage.getItem(this.getFilterIdentifier());

    if (storedFilter) {
      const parsedStoredFilter = JSON.parse(storedFilter);
      return new OfferPartnerFilter({
        active: parsedStoredFilter.active,
        displayValue: parsedStoredFilter.displayValue
      });
    }
    return new OfferPartnerFilter({
      active: true,
      displayValue: ACTIVE
    });
  }

  /**
   * Сохранение значения фильтра в локальное хранилище
   *
   * @param offerFilter фильтр предложений
   */
  private storeFilter(offerFilter: OfferPartnerFilter): void {
    localStorage.setItem(
      this.getFilterIdentifier(),
      JSON.stringify({
        active: offerFilter.active,
        displayValue: offerFilter.displayValue
      })
    );
  }

  /**
   * Геттер идентификатора фильтра в хранилище
   *
   * @returns идентификатор фильтра в хранилище
   */
  private getFilterIdentifier(): string {
    return TAX_FREE_PARTNER_OFFER_FILTER_ID;
  }
}
