export const passportEdit = {
  firstName: {
    maxLength: 36
  },
  lastName: {
    maxLength: 36
  },
  middleName: {
    maxLength: 60
  },
  country: {
    maxLength: 51
  },
  issueDate: {
    maxLength: 10
  },
  passportSeries: {
    maxLength: 11
  },
  passportNumber: {
    maxLength: 25
  },
  phone: {
    maxLength: 12
  },
  fax: {
    maxLength: 12
  },
  telex: {
    maxLength: 12
  }
};
