import { Product } from './Product';

export class Receipt {
  codeQR: string;
  receiptNumber?: string;
  date?: string;
  fiscalStorageNumber: string;
  fiscalReceiptNumber: number;
  fiscalAttribute: string;
  handwritten?: string;
  validHandwritten?: string;
  chequeDateTime?: string; // Дата кассового чека
  sum?: number;
  paymentIndication?: number;
  commodities?: Product[];
  constructor(rawData?) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
