import { Serializable } from './Serializable';
import { ModelManufacturer } from './ModelManufacturer';

export class Manufacturer extends Serializable {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  active: boolean;
  name: string;
  models: ModelManufacturer[];

  constructor(json?: any) {
    super(json);
  }
}
