import {
  APP_ID,
  Inject,
  LOCALE_ID,
  NgModule,
  PLATFORM_ID
} from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule
} from '@angular/platform-browser';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HeaderComponent,
  UserControlsComponent,
  IndicatorNotificationsComponent,
  CookiePolicyPopupComponent,
  UpdateEmailComponent
} from './modules/shared/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModuleModule } from './modules/shared/shared-module.module';
import { WebsocketModule } from './modules/websocket/websocket.module';
import { environment } from 'src/environments/environment';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { SetAuthHeaderInterceptor } from './core/interceptors/set-auth-header.interceptor';
import localeChine from '@angular/common/locales/zh';
import localeRu from '@angular/common/locales/ru';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { MetaModule } from './modules/meta/meta.module';
import { CountersModule } from './modules/counters/counters.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { WidgetComponent } from './modules/widget/components/widget/widget.component';
import { WidgetLoginComponent } from './modules/widget/components/widget-login/widget-login.component';
// import { PdfViewerModule } from 'ng2-pdf-v/iewer';
import { MetrikaModule } from 'ng-yandex-metrika';
import { NgOptimizedImage } from '@angular/common';

registerLocaleData(localeRu);
registerLocaleData(localeChine);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserControlsComponent,
    IndicatorNotificationsComponent,
    UpdateEmailComponent,
    CookiePolicyPopupComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    SharedModuleModule,
    AppRoutingModule,
    MatSelectModule,
    OAuthModule.forRoot(),
    HttpClientModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    WebsocketModule.config({
      url: environment.websocket
    }),
    FormsModule,
    ReactiveFormsModule,
    MetaModule,
    CountersModule,
    AngularSvgIconModule.forRoot(),
    MetrikaModule.forRoot(
      environment.yandexMetrikaOn ? { id: 89521800 } : { id: null }
    ),
    NgOptimizedImage,
  ],
  providers: [
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetAuthHeaderInterceptor,
      multi: true
    },
    DigitOnlyModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [WidgetComponent, WidgetLoginComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
