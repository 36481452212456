import { OTHER_LABEL, WECHAT_PAY } from '@app/core/constants';

const minCardLength = (paymentTypeVal: string) =>
  paymentTypeVal === OTHER_LABEL ? 12 : 16;
const maxCardLength = (paymentTypeVal: string) =>
  paymentTypeVal === OTHER_LABEL ? 19 : 16;

export const paymentOptionsAddForm = {
  paymentType: { maxLength: 128 },
  recipientName: { maxLength: 128 },
  currencyCode: { maxLength: 128 },
  paymentDetailsId: { maxLength: 128 },
  recipientNumber: {
    minLength: (paymentTypeVal: string): number =>
      paymentTypeVal === WECHAT_PAY ? 18 : minCardLength(paymentTypeVal),
    maxLength: (paymentTypeVal: string): number =>
      paymentTypeVal === WECHAT_PAY ? 18 : maxCardLength(paymentTypeVal)
  },
  recipientNumberType: { maxLength: 128 },
  actual: { maxLength: 128 }
};
