import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from 'src/app/modules/shared/components/not-found/not-found.component';
import { NotAllowedComponent } from './modules/shared/components/not-allowed/not-allowed.component';
import { CheckAuthComponent } from './modules/shared/components/check-auth/check-auth.component';
import { UpdateEmailComponent } from './modules/shared/components/update-email/update-email.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../app/modules/public/public.module').then(
      m => m.PublicModule
    )
  },
  {
    path: '',
    loadChildren: () =>
      import('../app/modules/auth/auth.module').then(m => m.AuthModule)
  },
  { path: 'authenticate', component: CheckAuthComponent },
  { path: 'not-allowed', component: NotAllowedComponent },
  { path: 'updateEmail/confirm', component: UpdateEmailComponent },
  {
    path: 'profile',
    loadChildren: () =>
      import('../app/modules/customer/customer.module').then(
        m => m.CustomerModule
      )
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('../app/modules/portal-admin/portal-admin.module').then(
        m => m.PortalAdminModule
      )
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../app/modules/report-admin/report-admin.module').then(
        m => m.ReportAdminModule
      )
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('../app/modules/customer-parner/customer-partner.module').then(
        m => m.CustomerPartnerModule
      )
  },
  {
    path: 'operator',
    loadChildren: () =>
      import('../app/modules/customer-operator/customer-operator.module').then(
        m => m.CustomerOperatorModule
      )
  },
  {
    path: 'tax-details',
    loadChildren: () =>
      import(
        '../app/modules/customer/pages/tax-details/tax-details.module'
      ).then(m => m.TaxDetailsModule)
  },
  {
    path: 'profile-edit',
    loadChildren: () =>
      import(
        '../app/modules/customer/pages/profile/profile-edit/profile-edit.module'
      ).then(m => m.ProfileEditModule)
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: {
        en: 'Not Found',
        zh: 'Not Found',
        ru: 'Страница не найдена',
        uz: 'Страница не найдена'
      }
    }
  }
];
@NgModule({
  declarations: [NotAllowedComponent],
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
    AngularSvgIconModule.forRoot(),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
