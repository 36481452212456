import { Serializable } from '../Serializable';

export class TaxFreeRules extends Serializable {
  id: string;
  createdAt: Date; // дата вступления
  updatedAt: Date;
  activationDate: Date;
  lastUpdatedBy: string;
  active: boolean;
  commission: number;
  baseVATValue: string; // НДС
  notTransferredCommission: number; // комиссия при не вывозе
  sumLimit: number; // пороговая сумма
  dateLimitation: Record<string, number>; // пороговый срок
  dateLimitationFNS: Record<string, number>; // срок ожидания фискальных чеков ФНС
  minCustomerAge: number; // минимальный возраст покупателя, при котором возможно оформление чека Tax Free
  nameMismatchAlert: string; // Проверка на совпадение имени в карте покупателя с именем в паспорте
  cardExpireAlertMonths: number; // Остаточный срок действия карты, при котором выдается рекомендация об использовании другой
  paymentLimitations: Record<string, number>; // Максимальный срок с даты покупки, в течение которого возможно получение компенсации НДС

  constructor(json?: any) {
    super(json);
  }
}
