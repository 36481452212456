export const periods = [
  {
    code: 'FOR_ALL_TIME',
    name: 'FOR_ALL_TIME',
    localizedNames: [
      { locale: 'ru', value: 'За все время' },
      { locale: 'uz', value: 'Over all time' },
      { locale: 'en', value: 'Over all time' },
      { locale: 'zh', value: '全部时间' }
    ]
  },
  {
    code: 'TODAY',
    name: 'TODAY',
    localizedNames: [
      { locale: 'ru', value: 'Сегодня' },
      { locale: 'uz', value: 'Today' },
      { locale: 'en', value: 'Today' },
      { locale: 'zh', value: '今天' }
    ]
  },
  {
    code: 'THIS_WEEK',
    name: 'THIS_WEEK',
    localizedNames: [
      { locale: 'ru', value: 'На этой неделе' },
      { locale: 'uz', value: 'This Week' },
      { locale: 'en', value: 'This Week' },
      { locale: 'zh', value: '本周' }
    ]
  },
  {
    code: 'THIS_MONTH',
    name: 'THIS_MONTH',
    localizedNames: [
      { locale: 'ru', value: 'За этот месяц' },
      { locale: 'uz', value: 'This Month' },
      { locale: 'en', value: 'This Month' },
      { locale: 'zh', value: '本月' }
    ]
  }
];
