<div class="not-found-wrapper">
  <div class="icon-wrapper">
    <svg-icon
      class="icon not-found d-flex flex-column justify-content-center align-items-center"
      src="/assets/icons/not_found.svg"
    ></svg-icon>
  </div>
  <div class="not-found-texts">
    <h4 i18n="@@not-allow-page-title">Not Allowed</h4>
  </div>
</div>
