<footer role="contentinfo" class="footer-section">
  <div class="container-inner">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="footer-soc-link">
            <ul class="ul-social d-flex justify-content-center align-items-center">
              <li>
                <a
                  href="https://wa.me/79257403039"
                  i18n-title="@@operator-whatsapp_link-title"
                  title="Call or write DIGITAX team in WhatsApp"
                  role="link"
                >
                  <span class="soc-what"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="mob-shop">
          <html-comment comment="noindex"></html-comment>
          <ul class="ul-shop d-flex flex-column flex-md-row align-items-center">
            <li>
              <a
                class="playmarket"
                href="https://play.google.com/store/apps/details?id=ru.digitax.droid"
                target="_blank"
                role="link"
              >
                <img
                  ngSrc="assets/icons/playmarket-icon.svg"
                  i18n-alt="@@tagline-ico-main_Google_Play"
                  alt="Google Play"
                  width="100"
                  height="30"
                />
              </a>
            </li>
            <li>
              <a class="playmarket-apk"
                href="https://mediaserver.digitaxfree.ru/media/digitax-taxfree-adhoc.apk"
                target="_blank"
                role="link"
              >
                <img
                  ngSrc="assets/img/link_apk.svg"
                  i18n-alt="@@tagline-ico-main_App_Link"
                  alt="DIGITAX app link"
                  width="100"
                  height="30"
                />
              </a>
            </li>
          </ul>
          <html-comment comment="/noindex"></html-comment>
        </div>
      </div>

      <div class="copyright" i18n="@@operator-copyright-landing">
        © LLC "SAV", {{ currentYear }}
      </div>

      <div class="footer-menu">
        <ul class="d-flex flex-column flex-md-row align-items-center justify-content-center">
          <li>
            <a
              routerLink="/about/terms"
              rel="nofollow"
              role="link"
              i18n="@@footer-legal-information"
            >Legal information</a>
          </li>
          <li>
            <a routerLink="/eula" rel="nofollow" role="link" i18n="@@footer-license-agreement">
              License agreement
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
