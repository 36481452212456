import { SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { formatDate, formatTimeH24 } from 'src/app/utility';

/**
 * Изменения размера с учетом размера панелей браузера
 */
export const ResizeByBrowserInterface = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

/**
 * Проверка отображения предупреждения о скором завершении срока действия карты
 *
 * @param date дата
 * @param cardExpireAlertMonths колличество месяцев, по конфигурации, меньше которого, необходимо отображать предупреждение
 * @returns признак отображения предупреждения
 */
export const lessConfigDate = (
  date: Date,
  cardExpireAlertMonths: number
): boolean =>
  moment(date)
    .endOf('month')
    .isBefore(moment().add(cardExpireAlertMonths, 'months'));

/**
 * Проверяет совпадает ли введенное имя и фамилия с данными паспорта
 *
 * @param data массив с именем и фамилией
 * @param userName имя в профиле
 * @param userSurname фамилия в профиле
 * @returns признак отображения предупреждения
 */
export const checkUnmatchRecipientName = (
  data: any[],
  userName: string,
  userSurname: string
): boolean => {
  // сначала ищем совпадение по имени
  const name = data.find(item => item === userName);
  if (!name) {
    return true;
  }
  // если найдено, то проверку фамилии ведем с учетом индекса имени, т.к. в поле может быть указаны варианты:
  // имя фамилия
  // фамилия имя
  const nameIndex = data.indexOf(name);
  const surname = data.find(
    (item, index) => item === userSurname && index !== nameIndex
  );
  return !surname;
};

/**
 * Форматирование даты для запроса на сервер
 *
 * @param dateValue дата
 * @param timeValue время
 * @returns отформатированную дату и время
 */
export const formatDateTimeForServer = (dateValue: any, timeValue: any) =>
  `${formatDate(dateValue, 'y-m-d')}T${formatTimeH24(timeValue, 'hh:mm')}` +
  ':00.000-00:00';

/**
 * Форматирование даты для запроса на сервер
 *
 * @param dateValue дата
 * @returns отформатированную дату
 */
export const formatDateForServer = (dateValue: any) => `${formatDate(dateValue, 'y-m-d')}`;

/**
 * Форматирование даты для имени файла
 *
 * @param dateValue дата
 * @param timeValue время
 * @returns отформатированную дату и время
 */
export const formatDateTimeForName = (dateValue: any, timeValue: any) =>
  `${formatDate(dateValue, 'y-m-d')}_${formatTimeH24(timeValue, 'hh:mm')}`;
/**
 * Валидация суммы на соответствие формату
 *
 * @param testedText промверяемый текст
 * @returns признак, что в строке есть только цифры, точка или запятая
 */
export const validationOfSum = (testedText: string) =>
  /^\d+(?:[\.,]\d+)?$/.test(testedText.replace(/\s/g, ''));

export const getHTMLElementById = (elementId: string) => {
  const widget = document.querySelector('#widget');
  const iframe = document.querySelector('#iframe') as HTMLIFrameElement;
  let htmlElement;
  if (widget) {
    htmlElement = widget.shadowRoot.getElementById(elementId);
  } else {
    htmlElement = iframe
      ? iframe.contentDocument.getElementById(elementId)
      : document.getElementById(elementId);
  }
  return htmlElement;
};

/**
 * Простановка текста в элемент
 *
 * @param fieldId id элемента
 * @param textValue текстовое значение для подстановки
 */
export const setTextInElement = (fieldId: string, textValue: string) => {
  const htmlElement = getHTMLElementById(fieldId);
  if (htmlElement && textValue) {
    htmlElement.innerHTML = textValue;
  }
};

/**
 * Простановка текста в placeholder
 *
 * @param fieldId id элемента
 * @param textValue текстовое значение для подстановки
 */
export const setPlaceholder = (fieldId: string, textValue: string) => {
  const htmlElement = getHTMLElementById(fieldId);
  if (htmlElement && textValue) {
    htmlElement.placeholder = textValue;
  }
};

export const getUserDataFromResponse = (user, customer) => {
  if (user && user.expressRegistrationData) {
    return {
      passport: {
        name: user.expressRegistrationData.name,
        surname: user.expressRegistrationData.surname
      },
      contact: {
        emails: [user.login],
        phoneNumbers: customer.contact.phoneNumbers
      }
    };
  } else {
    return customer;
  }
};

export const getUserDataWithFlag = (user, customer, isPhoneFlag, username) => {
  if (user && user.expressRegistrationData) {
    return {
      passport: {
        name: user.expressRegistrationData.name,
        surname: user.expressRegistrationData.surname
      },
      contact: {
        emails: isPhoneFlag ? [] : [user.login],
        phoneNumbers: isPhoneFlag ? [username] : []
      }
    };
  } else {
    return customer;
  }
};

/**
 * Проверка есть ли изменения пользователя
 *
 * @param changes объект изменений
 * @returns признак есть ли изменения
 */
export const hasUserChanges = (changes: SimpleChanges) =>
  changes.user?.previousValue !== changes.user?.currentValue &&
  changes.user?.currentValue;
/**
 * Проверка есть ли изменения во флаге телефона
 *
 * @param changes объект изменений
 * @returns признак есть ли изменения
 */
export const hasIsPhoneChanges = (changes: SimpleChanges) =>
  changes.isPhone?.previousValue !== changes.isPhone?.currentValue &&
  changes.isPhone?.currentValue !== undefined;
/**
 * Проверка есть ли изменения в конфигурации текстов
 *
 * @param changes изменения
 * @returns признак есть ли изменения
 */
export const hasTextConfigChanges = (changes: SimpleChanges) =>
  changes.textConfig &&
  changes.textConfig?.previousValue !== changes.textConfig?.currentValue;

/**
 * Проверка есть ли изменения в логине
 *
 * @param changes объект изменений
 * @returns признак есть ли изменения
 */
export const hasPhoneOrEmailChanges = (changes: SimpleChanges) =>
  changes.phoneOrEmail?.previousValue !== changes.phoneOrEmail?.currentValue &&
  changes.phoneOrEmail?.currentValue;
