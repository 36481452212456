<div>
  <div class="text-center">
    <img [src]="errorIcon">
  </div>
  <div class="widget-error-text">
    <div localization-key="email-phone-errors-reset-blocked-title" id="widget-message-block-error-title" class="widget-title padding-bottom-8">
      {{blockMessageTitle}}
    </div>
    <div id="widget-block-message" class="widget-centered-decription">
      {{blockMessage}}
    </div>
  </div>
  <button localization-key="widget-ok-btn" class="widget-btn widget-btn-primary full-width"
    (click)="returnToMain()">Ок</button>
</div>