import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebsocketService } from 'src/app/modules/websocket/websocket.service';
import { config } from './websocket.config';
import { WebSocketConfig } from './websocket.interfaces';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [WebsocketService]
})
export class WebsocketModule {
  /**
   * Конфигурация сокета
   *
   * @param wsConfig конфиг сокета
   * @returns Провайдер сокета
   */
  public static config(
    wsConfig: WebSocketConfig
  ): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [{ provide: config, useValue: wsConfig }]
    };
  }
}
