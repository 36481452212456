import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@app/env';
import { MobileAppSettings } from '@app/core/models';
import { api } from 'src/app/config';
import { AuthService } from './auth.service';

/**
 * Сервис для работы с настройками мобильных приложений
 */
@Injectable({ providedIn: 'root' })
export class MobileAppSettingsService {
  /**
   * Http Опции
   */
  httpOptions = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.makeHttpOptionsByAuthState(
      this.authService.isLoggedIn()
    );
  }
  /**
   * Получение настроек мобильных приложений
   *
   * @returns Наблюдателя за получением настроек
   */
  getSettings(): Observable<{ configs: MobileAppSettings[] }> {
    return this.http.get<{ configs: MobileAppSettings[] }>(
      this.getSettingsPath(),
      this.httpOptions
    );
  }

  /**
   * Обновление настроек мобильных приложений
   *
   * @param configs обновлённые настройки
   * @returns Наблюдателя за обновлением настроек
   */
  updateSettings(configs: MobileAppSettings[]): Observable<void> {
    return this.http.put<void>(
      this.getSettingsPath(),
      { configs },
      this.httpOptions
    );
  }

  /**
   * Получение URL эндпоинтов настроек МП
   *
   * @returns URL эндпоинтов настроек МП
   */
  private getSettingsPath(): string {
    return environment.baseAccountingApiUrl + api.mobileApp.settings;
  }

  /**
   * Генерация Http опций в зависимости от того, залогинен пользователь или нет
   *
   * @param isLoggedIn признак, залогинен ли пользователь
   * @returns Http опции
   */
  private makeHttpOptionsByAuthState(isLoggedIn: boolean = false) {
    if (isLoggedIn) {
      return this.authService.getSecuredHttpOptions();
    }
    return {};
  }
}
