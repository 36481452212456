import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { contactsEdit } from '../widget-login/widget-login.component';
import { takeUntil, finalize } from 'rxjs/operators';
import { AbstractPhoneMaskComponent } from '@app/core/classes';
import {
  emailAndPhoneValidator,
  isPhone
} from 'src/app/config/validators/emailPhoneValidator';
import { UntypedFormBuilder } from '@angular/forms';
import { WidgetService } from '@app/core/services/widget.service';
import { States } from '../widget/widget.component';
import {
  hasTextConfigChanges,
  setPlaceholder,
  setTextInElement
} from '@app/core/utils';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
import { Subscription } from 'rxjs';
export const WIDGET_RESTORE_DATA = 'WIDGET_RESTORE_DATA';
@Component({
  selector: 'app-widget-restore-access',
  templateUrl: './widget-restore-access.component.html',
  styleUrls: ['./widget-restore-access.component.scss'],
  providers: [WidgetService]
})
export class WidgetRestoreAccessComponent extends AbstractPhoneMaskComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Конфигурация Текстовок
   *
   * @type {any[]}
   */
  @Input() textConfig: any[];
  /**
   * Эмиттер события продолжения восстановления пароля
   *
   * @type {EventEmitter}
   */
  @Output() continueRestore = new EventEmitter();
  /**
   * Эмиттер события продолжения восстановления пароля
   *
   * @type {EventEmitter}
   */
  @Output() restoreError = new EventEmitter();
  /**
   * Максимальная длина e-mail
   *
   * @type {number}
   */
  emailMaxLength: number = contactsEdit.email.maxLength;
  /**
   * Максимальная длина номера телефона
   *
   * @type {number}
   */
  phoneMaxLength: number = contactsEdit.phone.maxLength;
  /**
   * Подписка на изменения языка
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Текущий язык
   *
   * @type {any}
   */
  currentLanguage: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private widgetService: WidgetService,
    private localizationService: WidgetLocalizationService
  ) {
    super();
  }
  /**
   * Хук инициализации компонента
   *
   * @description Инициализирует форму логина и подписывается на события формы и изменения
   * параметров роута.
   */
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: [null, emailAndPhoneValidator()]
    });
    this.mask = null;
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkSubmit();
    });
    this.restoreState();
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(val => {
        this.currentLanguage = val;
        this.setTexts();
      });
  }

  /**
   * Восстановление состояния
   */
  restoreState() {
    const restore = localStorage.getItem(WIDGET_RESTORE_DATA);
    if (!restore) {
      return;
    }
    this.f.username.setValue(restore);
  }

  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }
  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }
    const map = new Map([
      ['widget-restore-header', 'widget-title'],
      ['widget-restore-footer', 'widget-login-footer'],
      ['widget-restore-prompt', 'widget-login-prompt']
    ]);
    map.forEach((field, key) => {
      setTextInElement(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
    setPlaceholder(
      'username-restore',
      this.localizationService.findByKey(
        'widget-login-placeholder',
        currentLanguage.localeId
      )
    );
  }
  /**
   * Обработчик события вставки в поле логина
   *
   * @param event переменная, содержащая информацию о буфере обмена
   * @param fieldName имя поля
   */
  onPaste(event: ClipboardEvent, fieldName: string): void {
    this.mask = undefined;
    event.preventDefault();
    const val = event.clipboardData.getData('text/plain').replace(/\s/g, '');
    if (fieldName === 'username' && val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
    this.f[fieldName].setValue(val);
    setTimeout(() => this.f[fieldName].updateValueAndValidity(), 0);
  }
  /**
   * Обработчик события ввода в поле логина
   */
  onKey(): void {
    const val = this.f.username.value;
    this.patternTextField('username');
    this.mask = undefined;
    if (val === '') {
      return;
    }
    if (val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
  }

  /**
   * Сабмит формы
   */
  onSubmit(): void {
    const phoneOrEmail = this.f.username.value;
    this.loading = true;
    this.widgetService
      .initRestoreProcess(phoneOrEmail.replace(/\s/g, ''))
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        val => {
          if (!val) {
            return;
          }
          localStorage.setItem(WIDGET_RESTORE_DATA, this.f.username.value);
          this.continueRestore.emit({
            restoredPhoneOrEmail: phoneOrEmail,
            isPhone: isPhone(phoneOrEmail.replace(/\s/g, '')),
            newState: States.RESTORE_CODE_CHECK
          });
        },
        ({ error }: any) => {
          for (const er of error.errors) {
            const { code, data } = er;
            if (code === 'reset_password_blocked') {
              this.showResetBlockedErrorModal(Number(data), true);
              continue;
            }
            if (code === 'reset_password_use_email') {
              this.showResetBlockedErrorModal(Number(data), false);
              continue;
            }
          }
          this.loading = false;
        }
      );
  }

  /**
   * Отображение экрана с ошибкой об использовании максимального количества попыток ввода
   * кода подтверждения
   *
   * @param secondsToRetry количество секунд до следующей попытки
   * @param isTotalBlocked признак ичерпания всех попыток
   */
  showResetBlockedErrorModal(
    secondsToRetry: number,
    isTotalBlocked: boolean
  ): void {
    this.restoreError.emit({
      newState: States.RESTORE_ERROR,
      seconds: secondsToRetry,
      isTotalBlocked
    });
  }

  /**
   * Получение локализованной ошибки по длине email
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getEmailErrorText(fieldName: string) {
    const textNode = this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
    return textNode.replace(
      '{{ emailMaxLength }}',
      this.emailMaxLength.toString()
    );
  }

  /**
   * Получение локализованной ошибки
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getErrorText(fieldName: string) {
    return this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
  }
}
