import { Serializable } from 'src/app/core/models/Serializable';

export class RefundPassport extends Serializable {
  firstName: string;
  country: string;
  issueDate: Date;
  passportNumber: number;
  middleName: string;
  passportSeries: number;
  lastName: string;
  expirationDate: string;
  dateOfBirth: string;
}
