import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config';
/**
 * Сервис для работы с QR
 */
@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {}

  /**
   * Получение данных для QR кода чека
   *
   * @param taxFreeChequeId id чека
   * @returns Наблюдателя за получением данных для qr кода
   */
  getQrCode(taxFreeChequeId: string): Observable<any> {
    return this.http.get<any>(
      this.getTaxFreeChequeQrCodePath(taxFreeChequeId),
      this.authService.getSecuredHttpOptions()
    );
  }

  /**
   * Получение данных для QR кода профиля
   *
   * @returns Наблюдателя за получением данных для qr кода
   */
  getProfileQrCode(): Observable<any> {
    return this.http.get<any>(this.getQrCodePath(), this.httpOptions);
  }

  /**
   * Генерация URL для получений QR кода чека
   *
   * @param id id чека
   * @returns URL для получений QR кода чека
   */
  private getTaxFreeChequeQrCodePath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.taxFreeCheque.taxFreeChequeQrCode.replace('{id}', String(id))
    );
  }

  /**
   * Генерация URL для получений QR кода профиля
   *
   * @returns URL для получений QR кода профиля
   */
  private getQrCodePath(): string {
    return environment.baseAccountingApiUrl + api.customer.qrCode;
  }
}
