import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AbstractFormComponent } from '@app/core/classes';
import { WidgetConfig } from '@app/core/models/Widget/WidgetConfig';

@Component({
  selector: 'app-intergation-code',
  templateUrl: './intergation-code.component.html',
  styleUrls: ['./intergation-code.component.scss']
})
export class IntergationCodeComponent extends AbstractFormComponent
  implements OnInit {
  /**
   * Эмиттер обновления кода
   *
   * @type {EventEmitter}
   */
  @Output() updateCode = new EventEmitter();
  /**
   * Конфигурация виджета
   *
   * @type {WidgetConfig}
   */
  _widgetConfig: WidgetConfig;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  /**
   * Геттер конфигурации виджета
   */
  get widgetConfig(): WidgetConfig {
    return this._widgetConfig;
  }
  /**
   * Сеттер Конфигурации виджета
   */
  @Input() set widgetConfig(config: WidgetConfig) {
    if (config && this._widgetConfig !== config) {
      this._widgetConfig = config;
      if (!this.form) {
        return;
      }
      const scriptValue =
        this.widgetConfig.script === 'Empty' ? null : this.widgetConfig.script;
      this.f.code.setValue(scriptValue);
      this.updateCode.emit(this.form.value);
    }
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    const scriptValue =
      this.widgetConfig.script === 'Empty' ? null : this.widgetConfig.script;

    this.form = this.formBuilder.group({
      code: [this.widgetConfig ? scriptValue : null, null]
    });
    if (scriptValue) {
      this.updateCode.emit(this.form.value);
    }
    this.form.valueChanges.subscribe(val => {
      this.updateCode.emit(val);
    });
  }
}
