import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  HostListener,
  OnDestroy
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AuthService, NotificationsService } from './core/services';
import { WebsocketService } from './modules/websocket/websocket.service';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModel } from './core/models/NotificationModel';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Признак что пользователь залогинен
   *
   * @type {boolean}
   */
  isLoggedIn = false;
  /**
   * Признак простановки класса body-overflow
   *
   * @type {boolean}
   */
  parentOverflow = false;
  /**
   * Признак отображения нотификации о создании чека
   *
   * @type {boolean}
   */
  success = false;
  /**
   * Subject для завершения всех подписок при дестрое компонента
   *
   * @type {Subject}
   */
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private websocket: WebsocketService,
    private notificationsService: NotificationsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public config: NgbModalConfig,
    @Inject(PLATFORM_ID) private platform: any
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  /**
   * Обработчик события скролла
   *
   * @description меняет состояние хедера, сужает его при скролле больше 100 пикселей
   * @param e событие скролла
   */
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (isPlatformBrowser(this.platform)) {
      const el = document.getElementById('navbar');
      window.pageYOffset > 100
        ? el.classList.add('sticky')
        : el.classList.remove('sticky');
    }
  }

  /**
   * Хук инициализации
   *
   * @description создает коннект к веб сокету, подписывается на события нотификаций и создания чека
   * Создает хаб между порталом авторизации и текущим для передачи параметров между системами
   */
  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.isLoggedIn = this.authService.isLoggedIn();
      if (this.isLoggedIn) {
        this.websocket.connect();
      }

      this.notificationsService
        .getNotifyObserver()
        .subscribe((value: NotificationModel) => {
          // если пришло сообщение, что пользователь заблокирован - выполняем выход из системы
          if (value.notificationType === 'USER_BLOCKED') {
            this.authService.logout();
          }
        });

      this.notificationsService
        .getChequeObserver()
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          this.success = val;
        });
    }
    this.onWindowScroll();
  }
  /**
   * Хук финализации
   *
   * @description финулизирует Subject
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Обработчик события открытия меню
   *
   * @description меняет состояние признака проставноки класса
   */
  toggleMobileMenu() {
    this.parentOverflow = !this.parentOverflow;
  }
}
