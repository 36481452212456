<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body main-modal-body p-bottom-0">
  
  <div class="modal-body-inner">
    <h5 class="modal-title text-center w-100 font-weight-bold">
      {{ isEdit ? 'Редактировать настройки ИМ' : isAdmin? 'Добавить новый ИМ': 'Добавить виджет' }}
    </h5>
    <!-- Шапка степера -->
    <div class="modal-steps">
      <div class="step-item">
        <span class="number">1</span>
        <ng-container>Данные по ИМ</ng-container>
      </div>
      <div class="step-item">
        <span class="number">2</span>
        <ng-container>Генерация и копирование скрипта и параметров для размещения на странице ИМ</ng-container>
      </div>
      <div class="step-item">
        <span class="number">3</span>
        <ng-container>{{ isAdmin ? 'Скрипт для сбора не размеченных данных' : 'Завершить' }}</ng-container>
      </div>
    </div>
    <!-- Компоненты шагов -->

    <div class="tab" style="min-height: 350px; max-height: 350px;">
      <app-shop-information #shopInfo *ngIf="activeStep === 0" 
        [isAdmin]="isAdmin"
        [internetShop]="widgetData? widgetData: shopData"
        [saveShopInfo]="saveShopInfo"
        [oports]="oports"
        (disableNextStep)="disableNextSteps($event, 0)">
      </app-shop-information>
    </div>
    <div class="tab" style="max-height: 350px;">
      <app-generation-shop-script #scriptTab *ngIf="activeStep === 1" 
        [isAdmin]="isAdmin"
        [script]="script"
        [shopId]="shopId"
        [secretKey]="secretKey"
        (disableNextStep)="disableNextSteps($event, 1)"></app-generation-shop-script>
    </div>
    <div class="tab" style="max-height: 350px;">
      <app-widget-success-add 
        *ngIf="activeStep === 2 && !isAdmin" 
        [isAdmin]="isAdmin"
        (redirectEvent)="redirectToSettings()" 
        (closeEvent)="close()"></app-widget-success-add>
      <app-widget-success-add 
        *ngIf="isAdmin && showSuccess" 
        [isAdmin]="isAdmin"
        (redirectEvent)="redirectToSettings()" 
        (closeEvent)="close()"></app-widget-success-add>
      <app-intergation-code (updateCode)="updateCode($event)" [widgetConfig]="widgetConfig" *ngIf="activeStep === 2 && isAdmin && !showSuccess">
      </app-intergation-code>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="item">
    <ng-container *ngIf="isAdmin">
      <button *ngIf="activeStep > 0 && !showSuccess" type="button" class="btn btn-transparent btn-back" (click)="toStep(-1)">
        <img class="m-right-12" src="assets/icons/icon-arrow-left-green.svg">
        Назад
      </button>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <button *ngIf="activeStep > 0 && activeStep !== 2" type="button" class="btn btn-transparent btn-back" (click)="toStep(-1)">
        <img class="m-right-12" src="assets/icons/icon-arrow-left-green.svg">
        Назад
      </button>
    </ng-container>
    
    
  </div>
  <div *ngIf="activeStep === 2 && isAdmin && !showSuccess" class="btn-holder item next-item">
    <button type="button" class="btn btn-link" (click)="close()">
      Отмена
    </button>
    <button [disabled]="isButtonDisabled()" (click)="toStep(1)" type="button" class="btn btn-primary btn-save">
      Завершить
    </button>
  </div>
  <div *ngIf="activeStep !== 2" class="btn-holder item next-item">
    <button type="button" class="btn btn-link" (click)="close()">
      Отмена
    </button>
    <button [disabled]="isButtonDisabled()" (click)="toStep(1)" type="button" class="btn btn-primary btn-save">
      Далее
      <img class="m-left-12" src="assets/icons/icon-arrow-right.svg">
    </button>
  </div>
</div>