import { Component } from '@angular/core';

/**
 * Компонент футера.
 *
 * @class
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  /**
   * Текущий год
   *
   * @type {number}
   */
  readonly currentYear = new Date().getFullYear();
}
