export enum MobileAppType {
  MOBILE_CONSULTANT = 'MOBILE_CONSULTANT', // МП консультанта
  ANDROID = 'ANDROID' // МП покупателя
}

export const MobileAppTitle = {
  [MobileAppType.MOBILE_CONSULTANT]:
    'Настройка мобильного приложения Консультанта Андроид',
  [MobileAppType.ANDROID]: 'Настройка мобильного приложения Покупателя Андроид'
};

export interface MobileAppSettings {
  id: string;
  appVersionInMarket: string;
  appVersion: string;
  apkLink: string;
  critical: boolean;
  appType: MobileAppType;
}
