import { Serializable } from '../Serializable';

export class TradingNetworksSort extends Serializable {
  sortOrder: string;
  sortType: string;

  toHttpParams(): any {
    const result = {};

    if (this.sortOrder) {
      result['sortOrder'] = this.sortOrder;
    }
    if (this.sortType) {
      result['sortType'] = this.sortType;
    }
    return result;
  }
}
