import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  Optional
} from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
/**
 * Компонент Не найдена старница
 */
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  constructor(
    @Optional() @Inject(RESPONSE) private res: any,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  /**
   * Хук инициализации
   *
   * @description проставляется статус 404
   */
  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      this.res.status(404);
    }
  }
}
