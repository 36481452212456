import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { api } from '../../config';
import { GetInTouch } from '../models';

/**
 * Сервис связаться с нами
 */
@Injectable({
  providedIn: 'root'
})
export class GetInTouchService {
  /**
   * Http Опции
   */
  httpOptions = {};

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.makeHttpOptionsByAuthState(
      this.authService.isLoggedIn()
    );
  }

  /**
   * Получение контактных данных поддержки
   *
   * @returns Наблюдателя за получением данных поддержки
   */
  public getFeedbackData(): Observable<any> {
    return this.http.get<any>(this.getFeedbackEmail());
  }

  /**
   * Отправка сообщения в разделах Связаться с нами
   *
   * @param payload данные для отправки
   * @returns Наблюдателя за отправкой сообщения
   */
  sendMessage(payload: GetInTouch): Observable<any> {
    return this.http.post<any>(
      this.getGetInTouchPath(),
      payload,
      this.httpOptions
    );
  }

  /**
   * Генерация URL для запроса feedback email
   *
   * @returns URL для запроса feedback email
   */
  private getFeedbackEmail(): string {
    return environment.baseAccountingApiUrl + api.configuration.feedbackEmail;
  }

  /**
   * Генерация URL для отправки сообщения в поддержку
   *
   * @returns URL для отправки сообщения в поддержку
   */
  private getGetInTouchPath(): string {
    return environment.basePortalApiUrl + api.getInTouch.getInTouch;
  }

  /**
   * Генерация HttpOptions для запросов
   *
   * @description если пользователь залогинен, добавляем в заголовки запроса Bearer токен
   * @param isLoggedIn признак залогиненного пользователя
   * @returns http опции
   */
  private makeHttpOptionsByAuthState(isLoggedIn: boolean = false) {
    const options: { withCredentials: boolean; headers?: HttpHeaders } = {
      withCredentials: true
    };
    if (isLoggedIn) {
      options.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.getAccessToken()
      });
    }
    return options;
  }
}
