/* eslint-disable id-denylist */
/* eslint-disable max-len */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { WidgetService } from '@app/core/services/widget.service';
import { takeUntil, finalize, switchMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  emailAndPhoneValidator,
  isPhone
} from 'src/app/config/validators/emailPhoneValidator';
import {
  contactsEdit,
  eyeCrossIcon,
  eyeIcon
} from '../widget-login/widget-login.component';
import { States } from '../widget/widget.component';
import { isNil } from 'lodash';
import {
  DESCRIPTION_EMAIL,
  DESCRIPTION_PHONE,
  checkCircle,
  logoutIcon
} from '../widget-success-login/widget-success-login.component';
import { ShopData } from '@app/core/models/ShopData';
import {
  getHTMLElementById,
  getUserDataWithFlag,
  hasIsPhoneChanges,
  hasTextConfigChanges,
  hasUserChanges,
  setPlaceholder,
  setTextInElement
} from '@app/core/utils';
import { Subscription, forkJoin } from 'rxjs';
import { WidgetLocalizationService } from '@app/core/services/widget.localization.service';
import { WIDGET_RESTORE_DATA } from '../widget-restore-access/widget-restore-access.component';
import { WIDGET_REGISTRATION_DATA } from '../widget-registration/widget-registration.component';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty } from 'lodash';
import { Currency } from '@app/core/models';
import { Meta } from '@angular/platform-browser';
import { AbstractWidgetComponent } from '../../AbstractWidgetComponent';
import { CurrencyItem } from '@app/core/models/CurrencyItem';

declare global {
  interface Window {
    getShopData: () => void;
  }
}

export const currencies: CurrencyItem[] = [
  {
    label: 'Доллар',
    letterCode: 'USD',
    currencyCode: '$'
  },
  {
    label: 'Евро',
    letterCode: 'EUR',
    currencyCode: '€'
  },
  {
    label: 'Рубль',
    letterCode: 'RUB',
    currencyCode: '₽'
  },
  {
    label: 'Китайский юань',
    letterCode: 'CNY',
    currencyCode: '¥'
  },
  {
    label: 'Армянский драм',
    letterCode: 'AMD',
    currencyCode: '֏'
  },
  {
    label: 'Казахстанский тенге',
    letterCode: 'KZT',
    currencyCode: '₸'
  },
  {
    label: 'Узбекский сумм',
    letterCode: 'UZS',
    currencyCode: null
  },
  {
    label: 'Сербский динар',
    letterCode: 'RSD',
    currencyCode: null
  },
  {
    label: 'Вьетнамский донг',
    letterCode: 'VND',
    currencyCode: '₫'
  },
  {
    label: 'Тайский бат',
    letterCode: 'THB',
    currencyCode: '฿'
  },
  {
    label: 'Киргизский сом',
    letterCode: 'KGS',
    currencyCode: '⃀'
  }
];
/**
 * Компонент корзины в виджете
 */
@Component({
  selector: 'app-widget-init-calculator',
  templateUrl: './widget-init-calculator.component.html',
  styleUrls: ['./widget-init-calculator.component.scss']
})
export class WidgetInitCalculatorComponent extends AbstractWidgetComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Событие логина
   *
   * @type {EventEmitter}
   */
  @Output() successLogin = new EventEmitter<any>();
  /**
   * Событие клика на восстановление пароля
   *
   * @type {EventEmitter}
   */
  @Output() restoreClick = new EventEmitter<any>();
  /**
   * Событие клика на экспресс регистрацию
   *
   * @type {EventEmitter}
   */
  @Output() expressRegistration = new EventEmitter<any>();
  /**
   * Эмиттер события логаута
   *
   * @type {EventEmitter}
   */
  @Output() logoutEvent = new EventEmitter();
  /**
   * Полная стоимость товаров в корзине
   *
   * @type {number}
   */
  @Input() totalSum = 0;
  /**
   * Символ обозначения валюты
   *
   * @type {string}
   */
  @Input() currencyCode = 'RUB';

  /**
   * Суммы товаров
   *
   * @type {any}
   */
  @Input() comodities = [];
  /**
   * Пользователь
   *
   * @type {any}
   */
  @Input() user: any;
  /**
   * Признак логина по телефону
   *
   * @type {boolean}
   */
  @Input() isPhone: boolean;
  /**
   * Конфигурация Текстовок
   *
   * @type {any}
   */
  @Input() textConfig: any;
  /**
   * Компонент выпадающего списка валюты
   *
   * @type {NgSelectComponent}
   */
  @ViewChild('search') currenciesNgSelect: NgSelectComponent;
  /**
   * Подписка на изменения языка
   *
   * @type {Subscription}
   */
  subscription: Subscription;
  /**
   * Сумма возврата
   *
   * @type {string}
   */
  totalRefund = '0';
  /**
   * Сумма возврата для отображения с валютой
   *
   * @type {string}
   */
  totalRefundCalculated = '0';

  /**
   * Максимальная длина e-mail
   *
   * @type {number}
   */
  emailMaxLength: number = contactsEdit.email.maxLength;
  /**
   * Иконка при отображении пароля
   *
   * @type {string}
   */
  eyeIcon = eyeIcon;
  /**
   * Иконка при скрытом пароле
   *
   * @type {string}
   */
  eyeCrossIcon = eyeCrossIcon;
  /**
   * Иконка
   *
   * @type {string}
   */
  checkCircle = checkCircle;
  /**
   * Иконка выхода из аккаунта
   *
   * @type {string}
   */
  logoutIcon = logoutIcon;
  /**
   * Максимальная длина номера телефона
   *
   * @type {number}
   */
  phoneMaxLength: number = contactsEdit.phone.maxLength;
  /**
   * Признак отображения пароля
   *
   * @type {boolean}
   */
  showPassword = false;
  /**
   * Признак отображения подтверждения пароля
   *
   * @type {boolean}
   */
  confirmShowPassword = false;

  /**
   * Процент НДС
   *
   * @type {number}
   */
  vat = 12;
  /**
   * Символ разделителя
   *
   * @type {string}
   */
  separatorChar = ',';
  /**
   * Признак, что есть пользователь
   *
   * @type {boolean}
   */
  isLoggedIn = false;
  /**
   * Сообщение об отправке кода на email
   *
   * @type {string}
   */
  descriptionEmail = DESCRIPTION_EMAIL;
  /**
   * Сообщение об отправке кода на телефон
   *
   * @type {string}
   */
  descriptionPhone = DESCRIPTION_PHONE;
  /**
   * Символ валюты
   *
   * @type {string}
   */
  currencySymbol: string;

  /**
   * Данные магазина
   *
   * @type {ShopData}
   */
  shopData: ShopData = new ShopData();

  /**
   * Признак что собранные данные отправлены
   *
   * @type {boolean}
   */
  dataIsSended = false;
  /**
   * Текущий язык
   *
   * @type {any}
   */
  currentLanguage: any;
  /**
   * Список валют
   *
   * @type {CurrencyItem[]}
   */
  currencies = currencies;
  /**
   * Отфильтрованный Список валют
   *
   * @type {CurrencyItem[]}
   */
  filteredCurrencies = currencies;
  /**
   * Список конвертаций
   *
   * @type {Currency[]}
   */
  coeficients: Currency[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private widgetService: WidgetService,
    private localizationService: WidgetLocalizationService,
    private meta: Meta
  ) {
    super();
  }

  /**
   * Хук инициализации компонента
   *
   * @description Инициализирует форму логина и подписывается на события формы и изменения
   * параметров роута.
   */
  ngOnInit(): void {
    localStorage.removeItem(WIDGET_RESTORE_DATA);
    localStorage.removeItem(WIDGET_REGISTRATION_DATA);
    const storedCurrencyCode = localStorage.getItem('WIDGET_CART_CURRENCY');
    const storedCurrency = this.currencies.find(
      item => item.letterCode === storedCurrencyCode
    );
    this.createForm(storedCurrency);
    this.isLoggedIn = this.widgetService.isLoggedIn();
    this.obtainData();
    this.widgetService.getChangedTokenObservable().subscribe(() => {
      this.isLoggedIn = this.widgetService.isLoggedIn();
    });
    this.mask = null;
    this.initSubscriptions();
    if (!storedCurrency) {
      this.searchMetaCurrency();
    }
  }

  /**
   * Восстановление из хранилища значения возврата
   *
   * @description если не найдено, вызываем простановку как при 0,
   * чтобы обновить отображение текстов
   */
  restoreRefundFromStorage() {
    const refund = localStorage.getItem('WIDGET_CART_REFUND_RUB');
    if (!refund) {
      this.setDisplay('0');
      return;
    }
    const recalculatedRefund = this.recalculateRefund();
    this.setRecalculatedRefund(recalculatedRefund);
  }

  /**
   * Поиск на странице свойства валюты, если найдена и есть в списке, устанавливается
   * в выпадающий список
   */
  searchMetaCurrency() {
    const metaCurrency = this.meta.getTag('property=\'og:price:currency\'');
    const currencyCode = metaCurrency ? metaCurrency.content : 'RUB';
    const search = this.currencies.find(
      item => item.letterCode === currencyCode
    );
    if (!search) {
      return;
    }
    this.f?.currency.setValue(search);
  }

  /**
   * Создание формы
   *
   * @param currency валюта
   */
  createForm(currency?: any) {
    if (this.form) {
      return;
    }
    const checkboxState = localStorage.getItem('CHECKBOX_STATE');
    this.form = this.formBuilder.group({
      username: [null, emailAndPhoneValidator()],
      password: [null, Validators.required],
      confirm: [checkboxState ? true : null],
      currency: [currency ? currency : null]
    });
  }

  /**
   * Получение данных
   */
  obtainData() {
    this.loading = true;
    this.widgetService
      .obtainCurrency()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(res => {
        this.coeficients = res;
        this.restoreRefundFromStorage();
      });
  }
  /**
   * Инициализация подписок
   */
  initSubscriptions() {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.checkSubmit();
    });
    this.form?.controls.confirm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => {
        if (!val) {
          localStorage.removeItem('CHECKBOX_STATE');
          return;
        }
        localStorage.setItem('CHECKBOX_STATE', 'true');
        this.getData();
        this.sendData();
      });
    this.form?.controls.currency.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(currency => {
        this.saveCurrencyCode(currency.letterCode);
      });
    this.subscription = this.localizationService
      .getLocalizationObservable()
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.setTexts();
      });
    window.addEventListener('popstate', event => {
      this.getData();
      this.sendData();
    });
  }
  /**
   * Хук дестроя
   */
  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  /**
   * Хук после проверки View
   */
  ngAfterViewInit() {
    this.localizationService.updateTexts();
  }
  /**
   * Хук изменения компонента
   *
   * @param changes изменения
   */
  ngOnChanges(changes: SimpleChanges) {
    if (this.hasSumAndCodeChanges(changes)) {
      const refund = (Number(changes.totalSum?.currentValue) / 100) * this.vat;
      this.totalRefund = this.formatPriceValue(refund.toString());
      localStorage.setItem('WIDGET_CART_REFUND_RUB', this.totalRefund);
      const recalculatedRefund = this.recalculateRefund();
      this.setRecalculatedRefund(recalculatedRefund);
      this.setCurrencySymbol(changes.currencyCode?.currentValue);
    }
    if (this.hasCurrencyChanges(changes)) {
      this.changeCurrency();
    }
    if (this.hasUserOrPhoneFlagChanges(changes)) {
      this.setUserData(changes);
    }
    if (hasTextConfigChanges(changes)) {
      this.setTexts();
    }
  }

  /**
   * Проверка есть ли изменения суммы возврата или кода валюты
   *
   * @param changes изменения
   * @returns признак есть ли изменения
   */
  hasSumAndCodeChanges = (changes: SimpleChanges) =>
    changes.totalSum &&
    changes.totalSum?.currentValue !== undefined &&
    (changes.totalSum?.previousValue !== changes.totalSum?.currentValue ||
      this.hasCurrencyChanges(changes));
  /**
   * Проверка есть ли изменения пользователя или флага телефона
   *
   * @param changes изменения
   * @returns признак есть ли изменения
   */
  hasUserOrPhoneFlagChanges = (changes: SimpleChanges) =>
    hasUserChanges(changes) ||
    (changes.isPhone !== undefined && hasIsPhoneChanges(changes));

  /**
   * Проверка есть ли изменения в коде валюты
   *
   * @param changes изменения
   * @returns признак есть ли изменения
   */
  hasCurrencyChanges = (changes: SimpleChanges) =>
    changes.currencyCode &&
    changes.currencyCode?.previousValue !== changes.currencyCode?.currentValue;
  /**
   * Простановка значения суммы возврата в html тег
   *
   * @param totalRefundCalculated сумма возврата
   */
  updateRefundValueInHtml(totalRefundCalculated: string) {
    const refundSumEl = getHTMLElementById('widget-total-refund-sum');
    if (!refundSumEl) {
      return;
    }
    refundSumEl.innerHTML = `${totalRefundCalculated}`;
  }

  /**
   * Обновление данных о валюте
   *
   * @param currencyCode код валюты
   */
  saveCurrencyCode(currencyCode: string) {
    localStorage.setItem('WIDGET_CART_CURRENCY', currencyCode || 'RUB');
  }
  /**
   * Простановка текстов из конфига
   */
  setTexts() {
    const currentLanguage = this.localizationService.getCurrentLanguage();
    if (!currentLanguage) {
      return;
    }
    const map = new Map([
      ['widget-refund-title', 'widget-refund-title'],
      ['widget-get-money-back', 'widget-get-money-back'],
      ['widget-authorize-description', 'widget-authorize-instruction'],
      ['widget-calc-footer', 'widget-login-footer'],
      ['widget-calc-success-login', 'widget-success-login']
    ]);
    map.forEach((field, key) => {
      setTextInElement(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
    setTextInElement(
      'widget-cart-description',
      this.localizationService.findByKey(
        this.isPhone
          ? 'widget-login-message-phone'
          : 'widget-login-message-email',
        currentLanguage.localeId
      )
    );

    const mapPlaceholders = new Map([
      ['username-input-calculator', 'widget-login-placeholder'],
      ['password', 'widget-password-placeholder']
    ]);
    mapPlaceholders.forEach((field, key) => {
      setPlaceholder(
        key,
        this.localizationService.findByKey(field, currentLanguage.localeId)
      );
    });
  }

  /**
   * Отображение при сумме возврата 0
   *
   * @param totalRefund сумма возврата
   */
  setDisplay(totalRefund: string) {
    const refundSumEl = getHTMLElementById('widget-total-refund-sum');
    const refundTitle = getHTMLElementById('widget-refund-title');
    const refundSelect = getHTMLElementById('widget-total-refund-select');
    const refundMoneyBack = getHTMLElementById('widget-get-money-back');
    if (!refundSumEl) {
      return;
    }
    if (totalRefund === '0') {
      refundMoneyBack.style.removeProperty('display');
      refundTitle.style['display'] = 'none';
      refundSelect.style['display'] = 'none';
      refundSumEl.style['display'] = 'none';
    } else {
      refundTitle.style.removeProperty('display');
      refundSelect.style.removeProperty('display');
      refundSumEl.style.removeProperty('display');
      refundMoneyBack.style['display'] = 'none';
    }
  }
  /**
   * Простановка данных пользователя
   *
   * @param changes изменения полей
   */
  setUserData(changes: SimpleChanges) {
    const usernameEl = getHTMLElementById('widget-cart-username');
    if (usernameEl && changes.user?.currentValue.passport.name) {
      usernameEl.innerHTML = this.getUsernameFromChanges(changes);
    } else {
      const infoBlockEl = getHTMLElementById('widget-info-block');
      infoBlockEl.style['paddingTop'] = '0px';
      usernameEl.innerHTML = '';
    }
    
    const restoredData = getHTMLElementById('widget-cart-data');
    if (restoredData) {
      restoredData.innerHTML = (this.isPhone)
        ? this.getPhoneNumberFromChanges(changes)
        : this.getEmailFromChanges(changes);
    }
    const description = getHTMLElementById('widget-cart-description');
    if (description) {
      description.innerHTML = `${this.getDescriptionText(
        this.isPhone
      )}`;
    }
  }
  /**
   * Получение имени из паспортных данных
   *
   * @param changes изменения
   * @returns имя из паспорта
   */
  getUsernameFromChanges(changes: SimpleChanges) {
    return `${
      changes.user?.currentValue && changes.user?.currentValue.passport
        ? changes.user?.currentValue.passport.name + ','
        : ''
    }`;
  }
  /**
   * Получение номера телефона из изменений
   *
   * @param changes изменения
   * @returns номер телефона
   */
  getPhoneNumberFromChanges(changes: SimpleChanges) {
    return changes.user?.currentValue && changes.user?.currentValue.contact
      ? changes.user?.currentValue.contact.phoneNumbers[0]
      : '';
  }
  /**
   * Получение Email из изменений
   *
   * @param changes изменения
   * @returns номер телефона
   */
  getEmailFromChanges(changes: SimpleChanges) {
    return changes.user?.currentValue && changes.user?.currentValue.contact
      ? changes.user?.currentValue.contact?.emails[0]
      : '';
  }
  /**
   * Получение описания из конфига, если он есть или дефолтные значения
   *
   * @param isPhoneNumber признак телефона
   * @returns строку с описанием
   */
  getDescriptionText(isPhoneNumber: boolean) {
    if (this.textConfig) {
      const currentLanguage = this.localizationService.getCurrentLanguage();
      return this.localizationService.findByKey(
        isPhoneNumber
          ? 'widget-login-message-phone'
          : 'widget-login-message-email',
        currentLanguage.localeId
      );
    }
    return isPhoneNumber ? this.descriptionPhone : this.descriptionEmail;
  }

  /**
   * Обработчик события вставки в поле логина
   *
   * @param event переменная, содержащая информацию о буфере обмена
   * @param fieldName имя поля
   */
  onPaste(event: ClipboardEvent, fieldName: string): void {
    this.mask = undefined;
    event.preventDefault();
    const rawValue = event.clipboardData.getData('text/plain');
    const value = rawValue.startsWith('+')
      ? rawValue.replace(/[^\d+]/g, '')
      : rawValue.replace(/\s/g, '');
    if (fieldName === 'username' && value.startsWith('+')) {
      this.checkMaskAndSetError(value, 'username');
    }
    this.f[fieldName].setValue(value);
    setTimeout(() => this.f[fieldName].updateValueAndValidity(), 0);
  }
  /**
   * Обработчик события ввода в поле логина
   */
  onKey(): void {
    const val = this.f?.username.value;
    this.patternTextField('username');
    this.mask = undefined;
    if (val === '') {
      return;
    }
    if (val.startsWith('+')) {
      this.checkMaskAndSetError(val, 'username');
    }
  }

  /**
   * Смена признака отображения пароля
   */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Простановка символа валюты по коду
   *
   * @param code код валюты
   */
  setCurrencySymbol(code: string) {
    const search = this.currencies.find(item => item.letterCode === code);
    if (!search) {
      return;
    }
    if (!this.form) {
      this.createForm(search);
    } else {
      setTimeout(() => {
        this.f?.currency.setValue(search);
      }, 1000);
    }
  }

  /**
   * Получение информации о логине
   *
   * @returns логин
   */
  getRegistrationInfo() {
    const phoneOrEmailElement = getHTMLElementById('widget-cart-data');
    if (!phoneOrEmailElement) {
      return '';
    }
    return phoneOrEmailElement.innerHTML.replace(/\s/g, '');
  }
  /**
   * Сабмит формы
   */
  onSubmit(): void {
    this.loading = true;
    const username = this.f?.username.value.replace(/\s/, '');
    this.widgetService
      .authorize(username, this.f?.password.value)
      .pipe(
        switchMap(res => {
          if (!res || !res['access_token']) {
            this.loading = false;
            return of(null);
          }
          return this.widgetService.saveToken(res);
        }),
        filter(v => v !== null),
        switchMap(() =>
          forkJoin([
            this.widgetService.getCustomer(),
            this.widgetService.obtainRegAuthUser()
          ])
        )
      )
      .subscribe(
        ([customer, user]) => {
          const isPhoneFlag = isPhone(
            this.f?.username.value.replace(/\s/g, '')
          );
          const userObject = getUserDataWithFlag(
            user,
            customer,
            isPhoneFlag,
            username
          );
          this.successLogin.emit({
            user: userObject,
            newState: States.SUCCESS_LOGIN,
            isPhone:
              user && user.expressRegistrationData
                ? isPhoneFlag
                : isPhone(this.f?.username.value.replace(/\s/g, ''))
          });
          this.sendData(this.f?.username.value.replace(/\s/, ''));
          this.loading = false;
        },
        err => {
          this.loading = false;
          if (err && err.error && err.error.error) {
            if (err.error.error === 'invalid_grant') {
              this.form.setErrors({ invalid_creds: true });
            }
          }
        }
      );
  }
  /**
   * Отсылка данных на сервер
   *
   * @param username - логин
   */
  sendData(username?: string) {
    const shopId = localStorage.getItem('WIDGET_SHOP_ID');
    if (!shopId) {
      return;
    }
    this.shopData.shopId = shopId;
    this.shopData.login = username ? username : this.getRegistrationInfo();
    this.loading = true;
    this.widgetService
      .sendShopData(this.shopData)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(val => {
        localStorage.setItem('DATA_IS_SENDED', 'true');
      });
  }

  /**
   * Триггер события перехода на страницу восстановления пароля
   */
  triggerRetoreEvent() {
    this.restoreClick.emit({
      newState: States.RESTORE_ACCESS
    });
  }

  /**
   * Триггер события перехода на экспресс регистрацию
   */
  triggerExpressRegistration() {
    this.expressRegistration.emit({
      newState: States.EXPRESS_REGISTRATION
    });
  }

  /**
   * Обработчик нажатия на кнопку логаута
   */
  logout() {
    this.logoutEvent.emit();
  }

  /**
   * Получение локализованной ошибки по длине email
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getEmailErrorText(fieldName: string) {
    const textNode = this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
    const parts = textNode.split('{{ emailMaxLength }}');
    return parts[0] + this.emailMaxLength + parts[1];
  }

  /**
   * Получение локализованной ошибки
   *
   * @param fieldName имя поля
   * @returns локализованная ошибка
   */
  getErrorText(fieldName: string) {
    return this.localizationService.findByKey(
      fieldName,
      this.currentLanguage.localeId
    );
  }

  /**
   * Функция поиска по списку валют
   *
   * @param search поисковая строка
   */
  public searchItem(search: string) {
    const regexp = new RegExp(search, 'gi');
    this.filteredCurrencies = this.currencies.filter(
      u => regexp.test(u.label) || regexp.test(u.letterCode)
    );
  }

  /**
   * Обработчик изменения валюты
   */
  changeCurrency() {
    if (!this.form) {
      return;
    }
    const newCurrencyCode = this.f?.currency?.value?.letterCode;
    const storedTotalRefundRub = localStorage.getItem('WIDGET_CART_REFUND_RUB');
    if (!storedTotalRefundRub) {
      return;
    }
    const search: Currency = this.coeficients?.find(
      coef => coef.letterCode === newCurrencyCode
    );
    if (!search) {
      return;
    }
    const recalculatedRefund = this.recalculateRefund();
    this.setRecalculatedRefund(recalculatedRefund);
  }

  /**
   * Простановка пересчитанного значения суммы возврата
   *
   * @param recalculatedRefund новое значение
   */
  setRecalculatedRefund(recalculatedRefund: string) {
    const formattedRefund = this.formatPriceValue(recalculatedRefund);
    this.totalRefundCalculated = formattedRefund;
    this.setDisplay(this.totalRefundCalculated);
    this.updateRefundValueInHtml(this.totalRefundCalculated);
  }

  /**
   * Фокусировка на выбранную валюту при открытии выпадающего списка
   */
  focusSelectedItem(): void {
    const selectedItems = this.currenciesNgSelect.selectedItems;
    if (isEmpty(selectedItems)) {
      return;
    }

    setTimeout(() => {
      if (!this.currenciesNgSelect.dropdownPanel) {
        this.focusSelectedItem();
      }
    }, 10);
  }

  /**
   * Форматирование стоимости под формат с разделителем после каждых 3 символов
   *
   * @param val значение
   */
  private formatPriceValue(val: string): string {
    const regexp = /(\d+)(\d{3})/;
    let integer = null;
    let value = val.replace(/[^0-9,.]/g, '');
    value = val.replace(/,/g, '.');
    integer = Math.round(Number(value)).toString();
    while (regexp.test(integer)) {
      integer = integer.replace(regexp, '$1' + ' ' + '$2');
    }
    let formattedVal = integer;
    if (isNil(formattedVal)) {
      formattedVal = '';
    }
    return formattedVal;
  }
  /**
   * Пересчёт суммы возврата в соответствии с валютой
   *
   * @returns пересчитанное значение
   */
  private recalculateRefund(): string {
    const storedCurrencyCode = localStorage.getItem('WIDGET_CART_CURRENCY');
    const storedTotalRefundRub = localStorage.getItem('WIDGET_CART_REFUND_RUB');
    const storedTotalRefundRubNumber = Number(
      storedTotalRefundRub.replace(/\s/g, '').replace(/,/g, '.')
    );
    if (storedCurrencyCode === 'RUB') {
      return storedTotalRefundRubNumber.toString();
    }
    const coeficient: Currency = this.coeficients?.find(
      coef => coef.letterCode === storedCurrencyCode
    );
    if (!coeficient) {
      return;
    }
    const recalculatedRefund =
      Number(storedTotalRefundRubNumber) / Number(coeficient.rate);
    return recalculatedRefund.toString();
  }
}
