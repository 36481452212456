/* eslint-disable @typescript-eslint/no-this-alias */
import { isPlatformBrowser } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  Directive
} from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { AbstractDeviceComponent } from './AbstractDeviceComponent';
/**
 * Абстрактный класс сканирования изображения с камеры
 */
@Directive()
export abstract class AbstractScanComponent extends AbstractDeviceComponent
  implements OnInit, AfterContentInit {
  /**
   * Число попыток для распознавания
   *
   * @type {number}
   */
  @Input() scanAttempts = 42;
  /**
   * Эмиттер события клика на кнопку отмены
   *
   * @type {EventEmitter<boolean>}
   */
  @Output() cancelClick = new EventEmitter<boolean>();
  /**
   * Ошибки инициализации Webcam
   *
   * @type {WebcamInitError[]}
   */
  errors: WebcamInitError[] = [];
  /**
   * id устройства
   *
   * @type {string}
   */
  deviceId: string;
  /**
   * Изображение с камеры
   *
   * @type {WebcamImage}
   */
  webcamImage: WebcamImage = null;
  /**
   * Ширина блока траснляции
   *
   * @type {number}
   */
  cameraWidth: number;
  /**
   * Текущая попытка распознавания
   *
   * @type {number}
   */
  attempt = 0;
  /**
   * Таймер попыток
   *
   * @type {ReturnType<any>}
   */
  scanAttemptTimer: ReturnType<any>;
  /**
   * Триггер захвата изображения
   *
   * @type {Subject<void>}
   */
  private trigger: Subject<void> = new Subject<void>();
  /**
   * Subject для переключения камер
   *
   * @type {number}
   */
  private nextWebcam: Subject<boolean | string> = new Subject<
  boolean | string
  >();

  constructor(
    protected elRef: ElementRef,
    @Inject(PLATFORM_ID) protected platform: any,
    protected cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  /**
   * Геттер для получения наблюдателя за событием переключения камеры
   */
  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  /**
   * Геттер наблюдателя для события захвата изображения
   */
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  /**
   * Обработчик события изменения размеров окна
   */
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.cameraWidth = this.elRef.nativeElement.parentElement.offsetWidth;
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    super.ngOnInit();
    this.showNextWebcam = this.showNext.bind(this);
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.cameraInit(mediaDevices);
        this.hasDevicesEmit.emit(this.hasDevices);
      }
    );
  }

  /**
   * Хук после инициализации контента
   *
   * @description проставляет таймер для периодического захвата изображения с камеры
   */
  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        this.cameraWidth = this.elRef.nativeElement.parentElement.offsetWidth;
      }, 100);
      let delay = 1000;

      const parentThis = this;
      this.scanAttemptTimer = setTimeout(function tick() {
        parentThis.scan();

        parentThis.attempt++;
        if (parentThis.attempt === 20) {
          delay = 3000;
        }
        if (parentThis.attempt === 30) {
          delay = 5000;
        }
        parentThis.scanAttemptTimer = setTimeout(tick, delay);
        if (parentThis.attempt === parentThis.scanAttempts) {
          clearTimeout(parentThis.scanAttemptTimer);
        }
      }, delay);
    }
  }

  /**
   * Обработчик нажатия на кнопку отмены или закрыть
   */
  onCancelClick() {
    this.cancelClick.emit(true);
  }

  /**
   * Триггер события захвата изображения
   */
  scan() {
    this.trigger.next();
  }

  /**
   * Обработчик ошибок инициализации
   *
   * @param error Ошибка возникшая при инициализации
   */
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  /**
   * Обработчик события успешного переключения камеры
   *
   * @param deviceId id камеры
   */
  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  /**
   * Триггер события переключения камеры
   *
   * @param directionOrDeviceId направление переключения или id камеры
   */
  public showNext(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
    this.cdr.detectChanges();
  }

  /**
   * Обработчик нажатия кнопки переключения камеры
   */
  public toggleNextCamera(): void {
    this.nextCamera();
  }

  /**
   * Обработчик события получения изображения с камеры
   *
   * @param webcamImage изображение с камеры
   */
  public abstract handleImage(webcamImage: WebcamImage): void;
}
