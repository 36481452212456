<div *ngIf="loading" class="widget-loader">
  <span class="inner"></span>
</div>
<div>
  <div *ngIf="isLoggedIn" class="text-center padding-bottom-13">
    <img [src]="checkCircle">
  </div>
  <form [formGroup]="form">
    <div localization-key="widget-refund-title" id="widget-refund-title" class="widget-title">Вы можете вернуть до</div>
    <div style="display:none" id="widget-get-money-back" localization-key="widget-get-money-back" class="widget-title">Верните свои деньги</div>
    <div class="d-flex align-items-center justify-content-center widget-refund-block">
      <div id="widget-total-refund-sum" class="widget-refund-sum">{{totalRefundCalculated}}</div>
      <div id="widget-total-refund-select" class="widget-cur-select">
        <ng-select
            id="curValue"
            #search
            required
            appMobileLabelStyle
            [clearable]="false"
            [searchable]="false"
            [items]="filteredCurrencies"
            formControlName="currency"
            [ngClass]="{
              'is-invalid': form && f?.currency.errors
            }"
            (keydown.space)="$event.preventDefault()"
            (change)="changeCurrency()"
            (open)="focusSelectedItem()" notFoundText="Валюта не найдена"
        >
        <ng-template ng-header-tmp>
          <div class="searchable-input-wrapper">
            <input
              class="widget-form-control searchable"
              type="text"
              (input)="searchItem($event.target.value)"
            />
          </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
            <span>{{item.letterCode}}</span>
            <div class="widget-calculator-change-icon" style="padding-left: 8px;">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M5.00002 11V11C5.00002 8.79086 6.79088 7 9.00002 7H20L16.5 3.5" stroke="#168172" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M20 13V13C20 15.2091 18.2092 17 16 17H5.00002L8.50002 20.5" stroke="#168172" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div style="display: flex;">
            <span>{{item.letterCode}}</span>
          </div>
        </ng-template>
        </ng-select>
      </div>
      <!-- <img class="widget-clickable-icon widget-btn-icon" (click)="open()" [src]="calculatorIcon"> -->
    </div>
    <div *ngIf="!isLoggedIn" class="widget-checkbox-wrapper">
      <div class="form-group widget-checkbox-group">
        <input class="widget-checkbox-input" id="confirm" type="checkbox" formControlName="confirm" />
        <label localization-key="widget-checkbox-label-use-service" for="confirm" class="widget-checkbox-label">Воспользоваться сервисом <strong>Tax Free</strong></label>
      </div>
      <span class="flare"></span>
    </div>
    <div [hidden]="isLoggedIn">
      <div class="widget-centered-decription">
        <span id="widget-authorize-description" style="width: 100%" localization-key="widget-authorize-instruction">
          Авторизируйтесь или пройдите регистрацию для получения максимальной выгоды
          с
          сервисом
          TaxFree от Digitax!
        </span>
      </div>
      <div class="form-group">
        <input #username
          type="text"
          formControlName="username"
          id="username-input-calculator"
          appMobileLabelStyle
          autocomplete="off"
          mask="{{ mask ? mask : '' }}"
          maxLength="{{ mask ? phoneMaxLength + 2 : emailMaxLength}}"
          placeholder="Email \ Номер телефона"
          class="widget-form-control"
          [ngClass]="{
            'is-invalid': (isDisplayFieldErrors('username') && f?.username.errors)
            || (form?.errors && form?.errors['invalid_creds'])
          }"
          [dropSpecialCharacters]="false"
          (keydown.space)="$event.preventDefault()"
          (blur)="changeField('username')"
          (focus)="focusField('username')"
          (input)="onKey()"
          (paste)="onPaste($event, 'username')"
        />
        <div *ngIf="isDisplayFieldErrors('username') && f?.username.errors" class="widget-invalid-feedback">
          <div localization-key="widget-username-error-required" *ngIf="f?.username.errors.required">
            {{ getErrorText('widget-username-error-required') }}
          </div>
          <div localization-key="widget-username-error-email-format" *ngIf="f?.username.errors.emailMaxLength">
            {{ getEmailErrorText('widget-username-error-email-format') }}
          </div>
          <div
            localization-key="widget-username-error-format" *ngIf="f?.username.errors.phoneMaxLength || f?.username.errors.phoneMinLength || f?.username.errors['mask']">
            {{ getErrorText('widget-username-error-format') }}
          </div>
          <div localization-key="widget-username-error-emailIncorrect" *ngIf="f?.username.errors.emailIncorrect">
            {{ getErrorText('widget-username-error-emailIncorrect') }}
          </div>
          <div localization-key="widget-username-error-phoneStart" *ngIf="f?.username.errors.phoneStart">
            {{ getErrorText('widget-username-error-phoneStart') }}
          </div>
        </div>
      </div>
      <div class="form-group password-group">
        <input #passw
          autocomplete="new-password"
          appMobileLabelStyle
          placeholder="Пароль"
          formControlName="password"
          id="password"
          class="widget-form-control input-pswd-field"
          [ngClass]="{
            'is-invalid': (isDisplayFieldErrors('password') && f?.password.errors)
            || (form?.errors && form?.errors['invalid_creds'])
          }"
          [attr.type]="!showPassword ? 'password' : 'text'"
          (blur)="changeField('password')"
          (focus)="focusField('password')"
          (input)="patternTextField('password')"
          (keydown.space)="$event.preventDefault()"
        />
        <button type="button" class="show-pass" (click)="toggleShowPassword()">
          <img [src]="
            !showPassword
              ? eyeCrossIcon
              : eyeIcon
          ">
        </button>
        <div *ngIf="isDisplayFieldErrors('password') && f?.password.errors" class="widget-invalid-feedback">
          <div localization-key="widget-password-error-required" *ngIf="f?.password.errors.required">
            {{ getErrorText('widget-password-error-required') }}
          </div>
        </div>
        <div class="widget-invalid-feedback-form" localization-key="widget-password-error-invalid-creds" *ngIf="form?.errors && form?.errors['invalid_creds']">
          {{ getErrorText('widget-password-error-invalid-creds') }}
        </div>
      </div>
      <div class="form-group text-right">
        <a localization-key="widget-reset-link" (click)="triggerRetoreEvent()" class="reset_pass">Забыли пароль?</a>
      </div>
      <button localization-key="widget-login-btn" [disabled]="loading || disabledSubmit" [ngClass]="loading || disabledSubmit ? 'disabled' : ''"
        class="widget-btn widget-btn-primary full-width" (click)="onSubmit()">
        Войти
      </button>

      <div class="widget-register-link">
        <a localization-key="widget-reg-btn" (click)="triggerExpressRegistration()">Не зарегистрированы?</a>
      </div>
    </div>
    <div [hidden]="!isLoggedIn">
      <div id="widget-info-block" class="widget-info-block">
        <div id="widget-cart-username" class="text-center widget-user-name">{{user?.name}}</div>
        <div localization-key="widget-login-message" id="widget-cart-description" class="text-center widget-description">{{isPhone ? descriptionPhone: descriptionEmail}}</div>
      </div>
      <div id="widget-cart-data" class="text-center widget-email">{{isPhone ? user?.phone : user?.email}}</div>
      <div class="widget-logout-block d-flex align-items-center justify-content-center">
        <div localization-key="widget-success-login" id="widget-calc-success-login" class="widget-strong-description">Приятных покупок</div>
        <button class="widget-btn-with-icon" (click)="logout()"><img class="widget-btn-icon" [src]="logoutIcon"></button>
      </div>
    </div>
  </form>

  <div localization-key="widget-login-footer" id="widget-calc-footer" class="widget-footer-text">* Возврат доступен только для нерезидентов РФ</div>
</div>
