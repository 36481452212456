/**
 * Проверка переданного значения на совпадение с регулярным выражением номера телефона
 *
 * @param value значение
 * @returns признак, определяющий, является ли переданное значение номером телефона
 */
export const isPhone = (value: string): boolean => {
  const phoneRegExp = new RegExp('^[+][s./0-9]*$');
  return phoneRegExp.test(value);
};
