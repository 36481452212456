import { ValidatorFn, AbstractControl } from '@angular/forms';
import { EMAIL_REG_EXP } from '@app/core/constants';
import { shoppersRefund } from './shoppersRefund';

/**
 * Валидатор email
 *
 * @description проверяет введенный email по регулярному выражению;
 * если не соответсвует, то проставляется ошибка в поле формы
 * @returns функцию валидации
 */
export const emailValidator = (): ValidatorFn => (
  control: AbstractControl
): { [key: string]: any } | null => {
  const emailRegExp = EMAIL_REG_EXP;
  const maxLength = shoppersRefund.email.maxLength;
  const value = control.value;

  if (value !== null) {
    if (!value) {
      return { required: { value } };
    }
    if (value.length > maxLength) {
      return { emailMaxLength: { value, maxLength } };
    }
    if (!emailRegExp.test(value)) {
      return { emailIncorrect: { value } };
    }
  }
};
