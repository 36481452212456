export class Operator {
  id: string;
  email: string;

  constructor(rawData?) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
