import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { NavigationMobileService } from './navigation-mobile.service';
import {
  defaultLocalization,
  localizations,
} from '@app/core/constants/localization';
import { ILocalization } from '@app/core/models';
/**
 * Сервис для работы с роутом и локализацией
 */
@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  /**
   * BehaviourSubject для наблюдения за изменениями локализации
   *
   * @type {BehaviorSubject<ILocalization>}
   */
  private currentLocalization$ = new BehaviorSubject<ILocalization>(null);
  /**
   * Ключ объекта локализации в local storage
   *
   * @type {string}
   */
  private readonly storageLocalizationKey = 'localization';
  /**
   * Локали браузера
   *
   * @type {string[]}
   */
  private readonly browserLocales = this.getBrowserLocales({
    languageCodeOnly: true,
  });

  constructor(
    private router: Router,
    private currPath: NavigationMobileService,
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(PLATFORM_ID) private platform: any,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currPath.currentUrl = event.urlAfterRedirects.replace(
          /(\/ru\/|\/zh\/|\/uz\/)/g,
          '/',
        );
      }
    });
  }

  /**
   * Геттер наблюдателя локализации
   */
  get currentLocalization(): Observable<ILocalization> {
    return this.currentLocalization$.asObservable();
  }

  /**
   * Обновление значения объекта локализации и сохранение значения в local storage
   *
   * @param localization устанавливаемое значение
   */
  setCurrentLocalization(localization: ILocalization): void {
    this.storeLocalization(localization);
    this.currentLocalization$.next(localization);
    if (localization?.localeId === this.localeId) {
      return;
    }
    window.location.href = `${localization.route}${
      this.currPath.currentUrl || ''
    }`;
  }

  /**
   * Поиск локализации системы
   *
   * @description Если в local storage нет сохранённой локализации, производится поиск локализации
   * в соответствии с локалью браузера. Если такая локализация найдена, она возвращается.
   * Если она не найдена, то проверяется совпадение локализации из local storage с текущей локалью
   * приложения. Если совпадает, то возвращается локализация из local storage.
   * Если не совпадает, то производится поиск объекта локализации, соответствующего текущей локали
   * приложения. Если он найден, то возвращается. Если не найден, то возвращается дефлолтное
   * значение локализации (английская)
   * @returns объект с установленной локализацией
   */
  getLocalization(): ILocalization {
    const storedLocalization = this.getStoredLocalization();
    const localizationByBrowser = this.getLocalizationByBrowser();
    const localizationByLocaleId = this.getLocalizationByLocaleId(
      this.localeId,
    );

    if (!storedLocalization && localizationByBrowser) {
      return localizationByBrowser;
    }
    if (storedLocalization?.localeId === this.localeId) {
      return storedLocalization;
    }
    if (localizationByLocaleId) {
      return localizationByLocaleId;
    }
    return defaultLocalization;
  }

  /**
   * Получение объекта локализации, сохранённого в local storage
   *
   * @returns объект локализации из local storage или null, если его нет
   */
  getStoredLocalization(): ILocalization | null {
    return JSON.parse(localStorage.getItem(this.storageLocalizationKey));
  }

  /**
   * Поиск объекта локализации по localeId
   *
   * @param localeId - локаль для поиска
   * @returns найденный объект локализации
   */
  getLocalizationByLocaleId(localeId: string): ILocalization | null {
    return localizations.find(
      (localization) => localization.localeId === localeId,
    );
  }

  /**
   * Поиск объекта локализации по названию
   *
   * @param label - название локали для поиска
   * @returns найденный объект локализации
   */
  getLocalizationByLabel(label: string): ILocalization | null {
    return localizations.find(
      (localization) =>
        localization.label.toLowerCase() === label.toLowerCase(),
    );
  }

  /**
   * Сохранение объекта локализации в local storage
   *
   * @param localization объект локализации
   */
  private storeLocalization(localization: ILocalization) {
    if (!localization) {
      return;
    }
    localStorage.setItem(
      this.storageLocalizationKey,
      JSON.stringify(localization),
    );
  }

  /**
   * Поиск объекта локализации, соответствующего языку браузера
   *
   * @returns найденный объект локализации или null, если объект не найден
   */
  private getLocalizationByBrowser(): ILocalization | null {
    if (!isPlatformBrowser(this.platform) || !this.browserLocales?.length) {
      return null;
    }
    const firstBrowserLocale = this.browserLocales[0];
    const localizationByBrowser =
      this.getLocalizationByLocaleId(firstBrowserLocale) ||
      this.getLocalizationByLabel(firstBrowserLocale);
    return localizationByBrowser;
  }

  /**
   * Получение языков, используемых в браузере
   *
   * @param options опция для получения только кодов языков
   * @returns языки, используемые в браузере
   */
  private getBrowserLocales(options = { languageCodeOnly: false }): string[] {
    const browserLocales =
      navigator.languages || [navigator.language].filter((x) => x);
    if (!browserLocales) {
      return;
    }
    const trimmedLocales = browserLocales.map((locale) => locale.trim());
    return options.languageCodeOnly
      ? trimmedLocales.map((locale) => locale.split(/-|_/)[0])
      : trimmedLocales;
  }
}
