export class AccountSettings {
  email: string;
  phone?: string;
  password: string;
  oldPassword?: string;

  constructor(rawData?: AccountSettings) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
