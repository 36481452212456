import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Customer, Buyer } from 'src/app/core/models';
import { Paginable } from '../classes';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { BuyersFilter } from '../models/Buyers/BuyersFilter';
import { map } from 'rxjs/operators';

/**
 * Сервис для данных покупателя из ЛК Оператора
 */
@Injectable({
  providedIn: 'root'
})
export class BuyerService {
  /**
   * Http Опции
   */
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.authService.getAccessToken()
    })
  };

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  /**
   * Получение покупателей
   *
   * @param filter фильтра
   * @returns Наблюдателя за получением списка покупателей
   */
  getBuyers = (filter: BuyersFilter): Observable<Paginable> => {
    const params = filter.toHttpParams();
    const options = Object.assign(this.httpOptions, { params });
    return this.http.get<Paginable>(this.getCustomerPath(), options).pipe(
      map(response => {
        if (response) {
          response.elements = response.elements.map(
            (u: any): Customer => new Buyer(u)
          );
        }
        return response;
      })
    );
  };

  /**
   * Получение деталей покупателя
   *
   * @param id id покупателя
   * @returns Наблюдателя за получением деталей покупателя
   */
  getBuyerById = (id: string): Observable<Buyer> =>
    this.http
      .get<Buyer>(this.getBuyerByIdPath(id), this.httpOptions)
      .pipe(map(res => new Buyer(res)));

  /**
   * Обновление комментария к пользователю
   *
   * @param id - id покупателя
   * @param comment - текст комментария
   */
  putBuyerComment = (id: string, comment: string): Observable<any> =>
    this.http.put<any>(
      this.getCustomerCommentUrl(),
      { id, comment },
      { headers: this.httpOptions.headers }
    );

  /**
   * Генерация URL запроса на список покупателей
   *
   * @returns URL запроса на список покупателей
   */
  private getCustomerPath(): string {
    return environment.baseAccountingApiUrl + api.operator.customers;
  }

  /**
   * Генерация URL запроса на обновление комментария для покупателя
   *
   * @returns URL запроса на обновление комментария для покупателя
   */
  private getCustomerCommentUrl(): string {
    return environment.basePortalApiUrl + api.operator.customerComment;
  }

  /**
   * Генерация URL для запроса деталей покупателя
   *
   * @param id id покупателя
   * @returns URL для запроса деталей покупателя
   */
  private getBuyerByIdPath(id: string): string {
    return (
      environment.baseAccountingApiUrl +
      api.operator.customerById.replace('{id}', id)
    );
  }


}
