import { DESC } from '../..';
import { Serializable } from '../Serializable';

export class FtsChequeSort extends Serializable {
  sortOrder = DESC;
  sortType:
  | null
  | 'FTS_REQUEST_SENT_AT'
  | 'FTS_RESPONSE_RECEIVED_AT'
  | 'CHEQUE_ID_NUMBER' = 'FTS_REQUEST_SENT_AT';
  /**
   * Преобразование объекта в http Параметры
   *
   * @returns http параметры
   */
  toHttpParams(): any {
    const result = {};

    if (this.sortOrder) {
      result['sortOrder'] = this.sortOrder;
    }

    if (this.sortType) {
      result['sortType'] = this.sortType;
    }
    return result;
  }
}
