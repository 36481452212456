<div class="modal-header">
  <h4
    class="modal-title text-center w-100 font-weight-bold"
    id="modal-basic-title"
  >
    Удалить<br />интернет магазин?
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="close()"
  ></button>
</div>
<div class="modal-body">
  <div class="modal-body-inner">
    <div class="row">
      <div class="col-12">
        <div class="modal-text">
          Вы уверены, что хотите удалить <br />
          <strong>«{{ shop.name }}»?</strong>
          <br />
          <br />
          Действие не может быть отменено.
        </div>
        <br />
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-link" (click)="close()">
    Отмена
  </button>
  <button
    type="button"
    class="btn btn-primary btn-save"
    [ngClass]="loading ? 'loading' : ''"
    (click)="onSubmit()"
  >
    Удалить
    <span *ngIf="loading" class="loading"
      ><span class="loader-icon"></span
    ></span>
  </button>
  <div *ngIf="backendError" class="alert alert-danger noty-message">
    {{ backendError }}
  </div>
</div>
