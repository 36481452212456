import { Directive } from '@angular/core';
import { AbstractFormComponent } from './AbstractFormComponent';
/**
 * Абстрактный класс с формой и капчей
 */
@Directive()
export abstract class AbstractCaptchaComponent extends AbstractFormComponent {
  /**
   * Изображение капчи
   *
   * @type {string | ArrayBuffer | null}
   */
  captchaImg: string | ArrayBuffer | null;

  constructor() {
    super();
  }

  /**
   * Генерация изображения из Blob
   *
   * @param image blob данные
   */
  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.captchaImg = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
