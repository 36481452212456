import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { api } from 'src/app/config';
import { AuthService } from 'src/app/core/services/auth.service';
import { PaymentSystem } from 'src/app/core/models';
import { PAYMENT_SYSTEMS } from '../constants';
import { isUndefined } from 'lodash';
import { map } from 'rxjs/operators';

/**
 * Сервис для работы с типами платежных средств и карт
 */
@Injectable({
  providedIn: 'root'
})
export class PaymentSystemService {
  /**
   * Http Опции
   */
  httpOptions = {};

  /**
   * платёжные системы
   */
  backendPaymentSystems: any;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.httpOptions = this.authService.getHttpOptionsByAuthState();
  }

  /**
   * Получение типов платежных систем
   *
   * @returns Наблюдателя за получением типов платежных систем
   */
  public obtainPaymentSystem(): Observable<PaymentSystem[]> {
    return this.http.get<PaymentSystem[]>(
      this.getPaymentSystemPath(),
      this.httpOptions
    );
  }

  /**
   * Проверка на соответсвие платежным системам
   *
   * @description проверяет соответствие номера карты типу платежных системы
   * @param paymentSystemCode код платежной системы
   * @param fragmentCardNumber фрагмент номера карты
   * @returns true если фрагмент кода соответсвутет одному из типов
   */
  public isMatchCardNumberTypePaymentSystem(
    paymentSystemCode: string,
    fragmentCardNumber: number
  ): Observable<boolean> {
    return this.findPaymentSystemByCode(paymentSystemCode).pipe(
      map(paymentSystem => {
        const firstChar = String(fragmentCardNumber).charAt(0);
        if (!paymentSystem) {
          return false;
        }
        return this.calculateResultByType(
          paymentSystem,
          firstChar,
          fragmentCardNumber
        );
      })
    );
  }

  /**
   * Проверка на соответсвие номера карты платежным системам
   *
   * @param paymentSystem тип платежной системы
   * @param firstChar первый символ
   * @param fragmentCardNumber фрагмент номера карты
   * @returns true если фрагмент кода соответсвутет одному из типов
   */
  calculateResultByType(
    paymentSystem: any,
    firstChar: string,
    fragmentCardNumber: number
  ) {
    let result = false;
    switch (paymentSystem.type) {
      case PAYMENT_SYSTEMS.VISA:
        result = firstChar === '4';
        break;
      case PAYMENT_SYSTEMS.MASTERCARD: {
        result = this.calculateResultForMasterCard(
          firstChar,
          fragmentCardNumber
        );
        break;
      }
      case PAYMENT_SYSTEMS.UNIONPAY: {
        const firstSymbols = firstChar + String(fragmentCardNumber).charAt(1);
        result = ['62', '81'].indexOf(firstSymbols) !== -1;
        break;
      }
      case PAYMENT_SYSTEMS.AMEX: {
        const firstSymbols = firstChar + String(fragmentCardNumber).charAt(1);
        result = ['34', '37'].indexOf(firstSymbols) !== -1;
        break;
      }
      case PAYMENT_SYSTEMS.MIR: {
        result = this.calculateResultForMir(fragmentCardNumber);
        break;
      }
      case PAYMENT_SYSTEMS.JCB: {
        result = firstChar + String(fragmentCardNumber).charAt(1) === '35';
        break;
      }
      case PAYMENT_SYSTEMS.OTHER: {
        result = true;
        break;
      }
    }
    return result;
  }

  /**
   * Проверка на соответсвии номера карты платежной системы MasterCard
   *
   * @param firstChar первый символ номера карты
   * @param fragmentCardNumber фрагмент номера карты
   * @returns true если номер соответсвует master card
   */
  calculateResultForMasterCard(
    firstChar: string,
    fragmentCardNumber: number
  ): boolean {
    const firstSymbols = firstChar + String(fragmentCardNumber).charAt(1);
    if (fragmentCardNumber.toString().length < 4) {
      return ['51', '52', '53', '54', '55'].indexOf(firstSymbols) !== -1;
    }
    const fourSymbols = Number(String(fragmentCardNumber).substring(0, 4));
    return (
      ['51', '52', '53', '54', '55'].indexOf(firstSymbols) !== -1 ||
      (fourSymbols >= 2221 && fourSymbols <= 2720)
    );
  }

  /**
   * Проверка на соответсвии номера карты платежной системы MIR
   *
   * @param fragmentCardNumber фрагмент номера карты
   * @returns true если номер соответсвует master card
   */
  calculateResultForMir(fragmentCardNumber: number): boolean {
    if (fragmentCardNumber.toString().length < 4) {
      return false;
    }
    const fourSymbols = Number(String(fragmentCardNumber).substring(0, 4));
    return fourSymbols >= 2200 && fourSymbols <= 2204;
  }

  /**
   * Поиск платежных систем по коду
   *
   * @param code код платежной системы
   * @returns Наблюдателя за результатми поиска платежных систем
   */
  public findPaymentSystemByCode(code: string): Observable<any> {
    if (this.backendPaymentSystems) {
      const res = this.backendPaymentSystems.find(ps => ps.type === code);
      return isUndefined(res) ? of(null) : of(new PaymentSystem(res));
    }
    return this.obtainPaymentSystem().pipe(
      map(paymentSystems => {
        this.backendPaymentSystems = paymentSystems;
        const res = paymentSystems.find(ps => ps.type === code);
        return isUndefined(res) ? null : new PaymentSystem(res);
      })
    );
  }

  /**
   * Генерация URL для получения платежных типов
   *
   * @returns URL для получения платежных типов
   */
  private getPaymentSystemPath(): string {
    return environment.baseAccountingApiUrl + api.dictionary.paymentType;
  }
}
