/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { NewBreadcrumbsComponent } from './components/new-breadcrumbs/new-breadcrumbs.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  NumberFormatPipe,
  SafeStylePipe,
  UnderscoreReplacePipe,
  FileSizePipe,
} from 'src/app/core/pipes';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { NoResaltsComponent } from './components/no-resalts/no-resalts.component';
import { CaptchaComponent } from 'src/app/modules/shared/components/captcha/captcha.component';
import { AccountSettingsEditComponent } from './components/account-settings-edit/account-settings-edit.component';
import { MobileMenuComponent } from './components/header/mobile-menu/mobile-menu.component';
import { CheckAuthComponent } from './components/check-auth/check-auth.component';
import { RouterModule } from '@angular/router';
import { ContentPagesComponent } from './components/content-pages/content-pages.component';
import { NotFoundComponent } from './components';
import { ChecksNotFoundComponent } from './components/checks-not-found/checks-not-found.component';
import { DeleteFormConfirmComponent } from './components/delete-form-confirm/delete-form-confirm.component';
import { UpdatePhoneNumberComponent } from './components/update-phone-number/update-phonenumber.component';
import { ConfirmEmailModalComponent } from './components/confirm-email-modal/confirm-email-modal.component';
import { RemoveProfileBlockedMessageModalComponent } from './components/remove-profile-blocked-message-modal/remove-profile-blocked-message-modal.component';
import { RemoveProfileConfirmationModalComponent } from './components/remove-profile-confirmation-modal/remove-profile-confirmation-modal.component';
import { RemoveProfileSuccessMessageModalComponent } from './components/remove-profile-success-message-modal/remove-profile-success-message-modal.component';
import { RemoveProfileRedirectToModalComponent } from './components/remove-profile-redirect-to-email-modal/remove-profile-redirect-to-email-modal.component';
import { RemoveProfileWarningModalComponent } from './components/remove-profile-warning-modal/remove-profile-warning-modal.component';
import { DirectivesModule } from '../../core/directives/directives.module';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { TaxStatusComponent } from './components/tax-status/tax-status.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { QRCodeModule } from 'angularx-qrcode';
import { IconCardComponent } from './components/icon-card/icon-card.component';
import { PassportEditComponent } from './components/passport-edit/passport-edit.component';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { ChequesSendConfirmationComponent } from './components/cheques-send-confirmation/cheques-send-confirmation';
import { ConfirmPassModalComponent } from './components/confirm-pass-modal/confirm-pass-modal';
import { ScanModalComponent } from './components/scan-modal/scan-modal.component';
import { ScanPassportComponent } from './components/scan-passport/scan-passport.component';
import { ScanCardComponent } from './components/scan-card/scan-card.component';
import { WebcamModule } from 'ngx-webcam';
import { ScanningService } from '@app/core/services/scanner.service';
import { CustomMessageModalComponent } from './components/custom-message/custom-message-modal.component';
import { UploadModalComponent } from './components/upload-modal/upload-modal.component';
import { WalletScannerComponent } from './components/wallet-scanner/wallet-scanner.component';
import { ScanReceiptComponent } from './components/scan-receipt/scan-receipt.component';
import { DeleteReceiptModalComponent } from '../customer/pages/profile/profile-page/new-receipt-modal/components/delete-receipt-modal/delete-receipt-modal.component';
import { QrParserService } from '@app/core/services/qr-parser.service';
import { DraftModalComponent } from './components/draft-modal/draft-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ComponentWrapperComponent } from './components/component-wrapper/component-wrapper.component';
import { ContentPagesFormComponent } from './components/content-pages-form/content-pages-form.component';
import { QrCodeModalComponent } from './components/qr-code-modal/qr-code-modal.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CheckpointIconComponent } from './components/checkpoint-icon/checkpoint-icon.component';
import { CardPaymentTypeComponent } from './components/card-payment-type/card-payment-type.component';
import { WidgetSettingsComponent } from './components/widget-settings/widget-settings.component';
import { NgxColorsModule } from 'ngx-colors';
import { WidgetSharedService } from '@app/core/services/widget.shared.service';
import { WidgetPreviewFrameComponent } from './components/widget-preview-frame/widget-preview-frame.component';
import { WidgetModule } from '../widget/widget.module';
import { DeleteShopModalComponent } from './components/delete-shop-modal/delete-shop-modal.component';
import { CopyTextAreaComponent } from './copy-text-area/copy-text-area.component';
import { ShopInformationComponent } from './components/shop-information/shop-information.component';
import { GenerationShopScriptComponent } from './components/generation-shop-script/generation-shop-script.component';
import { WidgetSuccessAddComponent } from './components/widget-success-add/widget-success-add.component';
import { CreateEditWidgetModalComponent } from './components/create-edit-widget-modal/create-edit-widget-modal.component';
import { AnalyticFilterComponent } from './components/analytic-filter/analytic-filter.component';
import { AnalyticTableComponent } from './components/analytic-table/analytic-table.component';
import { IntergationCodeComponent } from './components/intergation-code/intergation-code.component';
import { ContentPageSelfConnectionComponent } from './components/content-page-self-connection/content-page-self-connection.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { AgreementViewerComponent } from './components/agreement-viewer/agreement-viewer.component';
import { OrganizationService } from '@app/core/services/organization.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DeleteMsgModalComponent } from './components/delete-msg-modal/delete-msg-modal.component';
import { ExciseProductsFilterComponent } from './components/excise-products-filter/excise-products-filter.component';

@NgModule({
  declarations: [
    BreadcrumbsComponent,
    NewBreadcrumbsComponent,
    PreloaderComponent,
    NoResaltsComponent,
    NumberFormatPipe,
    FileSizePipe,
    CaptchaComponent,
    AccountSettingsEditComponent,
    SafeStylePipe,
    UnderscoreReplacePipe,
    MobileMenuComponent,
    CheckAuthComponent,
    ContentPagesComponent,
    NotFoundComponent,
    DeleteFormConfirmComponent,
    UpdatePhoneNumberComponent,
    ConfirmEmailModalComponent,
    RemoveProfileBlockedMessageModalComponent,
    RemoveProfileConfirmationModalComponent,
    RemoveProfileSuccessMessageModalComponent,
    RemoveProfileRedirectToModalComponent,
    RemoveProfileWarningModalComponent,
    ChecksNotFoundComponent,
    TaxStatusComponent,
    QrCodeModalComponent,
    IconCardComponent,
    PassportEditComponent,
    ChequesSendConfirmationComponent,
    ConfirmPassModalComponent,
    ScanModalComponent,
    ScanPassportComponent,
    ScanCardComponent,
    CustomMessageModalComponent,
    UploadModalComponent,
    WalletScannerComponent,
    ScanReceiptComponent,
    DeleteReceiptModalComponent,
    DeleteMsgModalComponent,
    DraftModalComponent,
    FooterComponent,
    ContactsComponent,
    ComponentWrapperComponent,
    ContentPagesFormComponent,
    FormErrorComponent,
    CheckpointIconComponent,
    CardPaymentTypeComponent,
    WidgetSettingsComponent,
    WidgetPreviewFrameComponent,
    DeleteShopModalComponent,
    CopyTextAreaComponent,
    ShopInformationComponent,
    GenerationShopScriptComponent,
    WidgetSuccessAddComponent,
    CreateEditWidgetModalComponent,
    AnalyticFilterComponent,
    AnalyticTableComponent,
    IntergationCodeComponent,
    ContentPageSelfConnectionComponent,
    FileUploaderComponent,
    AgreementViewerComponent,
    ExciseProductsFilterComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule,
    AngularEditorModule,
    DirectivesModule,
    NgbTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    DigitOnlyModule,
    QRCodeModule,
    IconSpriteModule,
    IconSpriteModule.forRoot({ path: 'assets/sprites/sprite.svg' }),
    NgxMaskModule.forRoot(),
    WebcamModule,
    AngularSvgIconModule.forRoot(),
    NgxColorsModule,
    WidgetModule,
    PdfViewerModule,
    NgOptimizedImage,
  ],
  exports: [
    QRCodeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BreadcrumbsComponent,
    NewBreadcrumbsComponent,
    PreloaderComponent,
    NgxSkeletonLoaderModule,
    NoResaltsComponent,
    NgxPaginationModule,
    CaptchaComponent,
    QrCodeModalComponent,
    DeleteFormConfirmComponent,
    AccountSettingsEditComponent,
    IconCardComponent,
    FormsModule,
    ReactiveFormsModule,
    SafeStylePipe,
    UnderscoreReplacePipe,
    NumberFormatPipe,
    FileSizePipe,
    MobileMenuComponent,
    CheckAuthComponent,
    ContentPagesComponent,
    NotFoundComponent,
    AngularEditorModule,
    ChecksNotFoundComponent,
    DirectivesModule,
    TaxStatusComponent,
    NgbModule,
    HttpClientModule,
    NgSelectModule,
    DigitOnlyModule,
    PassportEditComponent,
    IconSpriteModule,
    NgxMaskModule,
    ScanModalComponent,
    ScanPassportComponent,
    ScanCardComponent,
    CustomMessageModalComponent,
    UploadModalComponent,
    WalletScannerComponent,
    DeleteReceiptModalComponent,
    DeleteMsgModalComponent,
    DraftModalComponent,
    FooterComponent,
    ContactsComponent,
    ComponentWrapperComponent,
    ContentPagesFormComponent,
    FormErrorComponent,
    CheckpointIconComponent,
    CardPaymentTypeComponent,
    WidgetSettingsComponent,
    DeleteShopModalComponent,
    CopyTextAreaComponent,
    ShopInformationComponent,
    GenerationShopScriptComponent,
    WidgetSuccessAddComponent,
    CreateEditWidgetModalComponent,
    AnalyticFilterComponent,
    AnalyticTableComponent,
    ContentPageSelfConnectionComponent,
    FileUploaderComponent,
    AgreementViewerComponent,
    ExciseProductsFilterComponent,
  ],
  providers: [
    MaskPipe,
    ScanningService,
    QrParserService,
    WidgetSharedService,
    OrganizationService,
  ],
})
export class SharedModuleModule {}
