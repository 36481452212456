export const environment = {
  production: true,
  auth: {
    loginUrl: 'https://digitaxfree.ru/auth/oauth/authorize',
    redirectUri: 'https://digitaxfree.ru/authenticate/',
    clientId: 'portal-frontend',
    scope: 'read',
    oidc: false,
    logoutUrl: 'https://digitaxfree.ru/auth/logout',
    baseApiUrl: 'https://digitaxfree.ru/auth'
  },
  basePortalApiUrl: 'https://digitaxfree.ru/portal',
  baseAccountingApiUrl: 'https://digitaxfree.ru/accounting',
  websocket: 'wss://accounting-service.digitaxfree.ru/ws/notification',
  baseReportingApiUrl: 'https://digitaxfree.ru/reporting',
  minioHost: 'https://mediaserver.digitaxfree.ru',
  yandexMetrikaOn: true,
  // widget
  widgetLogService: 'https://digitaxfree.ru/widget',
  widgetStoreService: 'https://digitaxfree.ru/widget',
  widgetPortalService: 'https://digitaxfree.ru/portal',
  widgetIntegrationService: 'https://digitaxfree.ru/widget-integration',
  widgetAuthService: 'https://digitaxfree.ru/auth',
  widgetInitialScriptPath: 'https://taxfree-media-demo.wavea.cc/media/taxfree-widget/taxfree-widget.js',
  codeRetentionPeriod: 24,
};
