import { Injectable } from '@angular/core';
import { ASC, DESC, TAX_FREE_CHEQUES_SORT_ID } from 'src/app/core/constants';
import { TaxFreeChequesSort } from '../models/TaxFreeCheque';
import { isNil } from 'lodash';
/**
 * Сервис для хранения сортировки секов в ЛК Покупателя
 */
@Injectable({
  providedIn: 'root'
})
export class TaxFreeChequesSortService {
  /**
   * Сохранение сортировки
   *
   * @description если в хранилище отсутсвует сортировка, просто проставляем значение и порядок по умолчанию
   * Если данные есть, и тип сортировки совпадает с параметром type, то меняем порядок сортировки:
   * с DESC на ASC,
   * Если был порядок ACS - сбрасываем паарметр сортировки.
   * Если поле сортировки не совпадает с type, то  сохраняем поле и проставляем порядок по умолчанию DESC
   * @param type поле сортировки
   */
  public changeSort(type: string) {
    const sort = this.getStoredSort();
    if (isNil(sort.sortType) || sort.sortType !== type) {
      sort.sortOrder = DESC;
      sort.sortType = type;
      this.storeSort(sort);
      return;
    }
    switch (sort.sortOrder) {
      case DESC: {
        sort.sortOrder = ASC;
        break;
      }
      case ASC: {
        sort.sortOrder = null;
        sort.sortType = null;
        break;
      }
      default:
        break;
    }

    this.storeSort(sort);
  }

  /**
   * Получение значения сортировки из хранилища
   *
   * @returns значение сортировки
   */
  public getStoredSort(): TaxFreeChequesSort {
    const storedSort = localStorage.getItem(this.getSortIdentifier());

    if (storedSort) {
      const parsedStoredSort = JSON.parse(storedSort);
      return new TaxFreeChequesSort({
        sortOrder: parsedStoredSort.sortOrder
          ? parsedStoredSort.sortOrder
          : null,
        sortType: parsedStoredSort.sortType ? parsedStoredSort.sortType : null
      });
    }
    return new TaxFreeChequesSort();
  }

  /**
   * Получение идентификатора сортировки в хранилище
   *
   * @returns идентификатор сортировки в хранилище
   */
  private getSortIdentifier(): string {
    return TAX_FREE_CHEQUES_SORT_ID;
  }

  /**
   * Сохранение сортировки в локальное хранилище
   *
   * @param taxFreeChequesSort данные сортировки
   */
  private storeSort(taxFreeChequesSort: TaxFreeChequesSort): void {
    localStorage.setItem(
      this.getSortIdentifier(),
      JSON.stringify({
        sortOrder: taxFreeChequesSort.sortOrder,
        sortType: taxFreeChequesSort.sortType
      })
    );
  }
}
