<div class="not-found-wrapper">
  <div class="icon-wrapper">
    <svg-icon
      class="icon not-found d-flex flex-column justify-content-center align-items-center"
      src="/assets/icons/not_found.svg"
    ></svg-icon>
  </div>
  <div class="not-found-texts">
    <h4 i18n="@@not-found-page-title">Not Found</h4>
    <p class="uptitle">
      <span i18n="@@not-found-page-description">404 error. The page not found. Return to the</span><a href="/" i18n="@@not-found-page-link"> home page.</a>
    </p>
  </div>
</div>
