import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  AfterViewChecked,
  PLATFORM_ID,
  Inject
} from '@angular/core';
/**
 * Компонент политики хранения Cookie
 */
@Component({
  selector: 'app-cookie-policy-popup',
  templateUrl: './cookie-policy-popup.component.html',
  styleUrls: ['./cookie-policy-popup.component.scss']
})
export class CookiePolicyPopupComponent implements AfterViewChecked {
  /**
   * Признак отображения сообщения о политике Cookie
   *
   * @type {boolean}
   */
  isShowing = false;

  constructor(@Inject(PLATFORM_ID) private platform: any) {}

  /**
   * Хук полной инициализации View
   *
   * @description если сообщение о политике не было показано пользователю ни разу, то отображаем попап
   */
  ngAfterViewChecked() {
    if (isPlatformBrowser(this.platform)) {
      setTimeout(() => {
        if (localStorage.getItem('cookiePolicyPopupState') !== 'shown') {
          this.isShowing = true;
        }
      }, 1000);
    }
  }

  /**
   * Закрытие попапа с сообщением
   *
   * @description закрывает попап, сохраняет в хранилище признак, что сообщение было показано пользователю
   */
  closePopup(): void {
    this.isShowing = false;
    localStorage.setItem('cookiePolicyPopupState', 'shown');
  }
}
