<div>
  <div class="row">
    <div class="col-12">
      <div class="go-back-wrapper">
        <a (click)="goBack()">Вернуться назад</a>
      </div>
    </div>
    <div class="col-12">
      <h3
        *ngIf="tradingNetwork"
        class="fz-34 m-bottom-32 m-top-32 page-title font-weight-bold"
      >
        Интернет магазины
        {{ tradingNetwork.name }}
      </h3>
    </div>
  </div>

  <app-no-results
    *ngIf="activeShops?.length < 1 && !loading"
    [customTitle]="'Результаты отсутствуют'"
    [customText]="'Извините, интернет магазинов не найдено'"
  ></app-no-results>
  <div class="row" *ngIf="!loading">
    <div class="col-12">
      <div
        class="tax-details-table-wrapper table-wrapper"
        *ngIf="activeShops?.length > 0"
      >
        <table aria-describedby="shops" class="table table-striped custom-table">
          <thead>
            <tr>
              <th scope="col" class="wide-column">
                <span ngbTooltip="Адрес размещения виджета">Данные</span>
              </th>
              <th scope="col">
                <span ngbTooltip="Дата и время последнего запроса статуса виджета">Дата\Время<br/>(Виджет)</span>
              </th>
              <th scope="col" class="common-td">
                <span ngbTooltip="Наличие URL для запросов чеков, отвечает за глубокую интеграцию">URL для <br/>чеков</span>
              </th>
              <th scope="col" class="common-td">
                <span ngbTooltip="Дата и время последнего запроса статуса URL для чеков">Дата\Время<br/>(URL)</span>
              </th>
              <th scope="col" class="small-td">
                <span>Secret <br/>Key</span>
              </th>
              <th scope="col" class="small-td">
                <span>ShopID</span>
              </th>
              <th scope="col">
                <span ngbTooltip="Установленный временной период на запрос чеков при глубокой интеграции">Период <br/>отправки <br/>запросов</span>
              </th>
              <th scope="col">
                <span></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let shop of activeShops">
              <td>
                <span [ngbTooltip]="shop.url">{{ shop.url }}</span>
                <br/>
                <div class="d-flex flex-row align-items-center">
                  <div class="w-100 status" [ngClass]="getStatusClassname(shop)"> {{ getStatusLabel(shop) }} </div>
                </div>
              </td>
              <td>
                {{shop.widgetCheckTime? (shop.widgetCheckTime | date: 'dd.MM.yyyy HH:mm') : '—'}}
              </td>
              <td>
                <div class="d-flex flex-row align-items-center">
                  <div class="w-100 status" [ngClass]="getUrlClassname(shop)"> {{ getUrlLabel(shop) }} </div>
                </div>
              </td>
              <td>
                {{shop.chequeUrlCheckTime ? (shop.chequeUrlCheckTime | date: 'dd.MM.yyyy HH:mm') : '—'}}
              </td>
              <td>
                <span [ngbTooltip]="shop.secretKey">
                  {{ shop.secretKey }}
                </span>
              </td>
              <td>
                <span [ngbTooltip]="shop.id">
                  {{ shop.id }}
                </span>
              </td>
              <td>
                {{ getPeriodTimesLabel(shop.chequeRequestTime) }}
              </td>
              <td>
                <div class="row-buttons">
                  <button class="btn btn-icon"
                  [ngbTooltip]="'Настройка интернет магазина'"
                  (click)="createEditShop(shop)">
                    <svg-icon
                      class="icon"
                      [src]="'assets/icons/edit-table.svg'"
                    ></svg-icon>
                  </button>
                  <button
                    [ngbTooltip]="'Настройка стилей виджета'"
                    class="btn btn-icon btn-left-border"
                    (click)="redirectToSettings(shop.id)"
                  >
                    <svg-icon
                      class="icon"
                      [src]="'assets/icons/widget-settings.svg'"
                    ></svg-icon>
                  </button>
                  <button
                    [ngbTooltip]="'Удаление интернет магазина'"
                    class="btn btn-icon btn-left-border"
                    (click)="delete(shop)"
                  >
                    <svg-icon
                      class="icon"
                      [src]="'assets/icons/delete-table.svg'"
                    ></svg-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
