<div>
  <div class="text-center">
    <img [src]="checkCircle">
  </div>
  <div class="widget-info-block">
    <div id="widget-username" class="text-center widget-user-name">{{user?.passport?.name}}</div>
    <div localization-key="widget-login-message" id="widget-login-message" class="text-center widget-description">Информация о возможностях сервиса Tax Free c Digitax, направлена на Ваш
      Email</div>
  </div>
  <div id="widget-email" class="text-center widget-email">{{user?.contact?.emails[0]}}</div>
  <div class="widget-logout-block d-flex align-items-center justify-content-center">
    <div localization-key="widget-success-login" id="widget-success-login" class="widget-strong-description">Приятных покупок</div>
    <button (click)="logout()" class="widget-btn-with-icon"><img class="widget-btn-icon" [src]="logoutIcon"></button>
  </div>
  <div localization-key="widget-login-link" id="widget-login-info" class="text-center widget-secondary-description">
    <span>Подробная информация на сайте </span>
    <a href="https://digitaxfree.ru" target="_blank" class="widget-bold-link-10">Tax Free</a>
  </div>
</div>
