export const ngbDatepickerFormat = (date: Date) => {
  const dateObject = new Date(date);
  return {
    day: dateObject.getDate(),
    month: dateObject.getMonth(),
    year: dateObject.getFullYear()
  };
};

export const formatDate = (date: Date, template: string = 'd.m.y'): string => {
  let displayDate: string;
  let displayMonth: string;
  const d = new Date(date);
  const dd = d.getDate();
  displayDate = String(dd);
  if (dd < 10) {
    displayDate = '0' + dd;
  }

  const mm = d.getMonth() + 1;
  displayMonth = String(mm);
  if (mm < 10) {
    displayMonth = '0' + mm;
  }

  const displayFullYear = String(d.getFullYear());

  return template
    .replace('d', displayDate)
    .replace('m', displayMonth)
    .replace('y', displayFullYear);
};

export const formatTimeH24 = (
  date: Date,
  template: string = 'hh:mm:ss'
): string => {
  let displayHour: string;
  let displayMinute: string;
  let displaySeconds: string;

  const hh = date.getHours();
  displayHour = String(hh);
  if (hh < 10) {
    displayHour = '0' + hh;
  }

  const mm = date.getMinutes();
  displayMinute = String(mm);
  if (mm < 10) {
    displayMinute = '0' + mm;
  }

  const ss = date.getSeconds();
  displaySeconds = String(ss);
  if (ss < 10) {
    displaySeconds = '0' + ss;
  }

  return template
    .replace('hh', displayHour)
    .replace('mm', displayMinute)
    .replace('ss', displaySeconds);
};

const setMidnight = (date: Date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  return date;
};

export const getDataStartByPeriod = (period: string, date: Date) => {
  switch (period) {
    case 'FOR_ALL_TIME':
      return;
    case 'TODAY': {
      return setMidnight(date);
    }
    case 'THIS_WEEK': {
      return new Date(date.setDate(date.getDate() - 7));
    }
    case 'THIS_MONTH': {
      return new Date(date.setMonth(date.getMonth() - 1));
    }
  }
};

export const getDataEndByPeriod = (period: string, date: Date) => {
  if (period === 'FOR_ALL_TIME') {
    return;
  }
  return date;
};
