/* eslint-disable id-denylist */
import { ShopProduct } from './ShopProduct';

export class ShopData {
  shopId: string;
  tradingOrgName: string;
  shopName: string;
  login: string;
  order: {
    number: string;
    sum: number;
    quantity: number;
    commodities: ShopProduct[];
  };
  customer: {
    phone: string;
    name: string;
    surname: string;
    birthday: string;
    email: string;
    country: string;
    city: string;
  };
  paymentType: string;
  deliveryAddress: {
    full: string;
    country: string;
    city: string;
    street: string;
    house: string;
    appartment: string;
    office: string;
    region: string;
  };
  sessionId: string;
  locale: string;
  registerType: string;

  constructor() {
    this.shopId = '';
    this.login = '';
    this.order = {
      number: '',
      sum: 0,
      quantity: 0,
      commodities: []
    };
    this.customer = {
      phone: '',
      name: '',
      surname: '',
      birthday: '',
      email: '',
      city: '',
      country: ''
    };
    this.paymentType = null;
    this.deliveryAddress = {
      full: '',
      country: '',
      city: '',
      street: '',
      house: '',
      appartment: '',
      office: '',
      region: ''
    };
    this.locale = 'ru';
    this.registerType = '';
  }
}
