export class Partner {
  id: string;
  email: string;
  tradingNetworkId: string;
  userId: string;

  constructor(rawData?) {
    if (rawData) {
      Object.assign(this, rawData);
    }
  }
}
