import { Serializable } from './Serializable';

export class ModelManufacturer extends Serializable {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  lastUpdatedBy: string;
  active: boolean;
  name: string;
  kktInterface: string;

  constructor(json?: any) {
    super(json);
  }
}
