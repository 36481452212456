import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TradingNetwork, TradingNetworksSort } from '@app/core/models';
import { TradingInternetShop } from '@app/core/models/TradingInternetShop';
import { TradingNetworkService } from '@app/core/services';
import { DeleteShopModalComponent } from '@app/shared/components/delete-shop-modal/delete-shop-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { CreateEditWidgetModalComponent } from '@app/shared/components/create-edit-widget-modal/create-edit-widget-modal.component';

export const periodItems = [
  {
    label: 'Каждую минуту',
    value: 'MIN_1'
  },
  {
    label: 'Каждые 5 мин.',
    value: 'MIN_5'
  },
  {
    label: 'Каждые 10 мин.',
    value: 'MIN_10'
  },
  {
    label: 'Каждые 15 мин.',
    value: 'MIN_15'
  },
  {
    label: 'Каждые 30 мин.',
    value: 'MIN_30'
  },
  {
    label: 'Каждые 45 мин.',
    value: 'MIN_45'
  },
  {
    label: 'Каждые 60 мин.',
    value: 'MIN_60'
  }
];
@Component({
  selector: 'app-internet-shops',
  templateUrl: './internet-shops.component.html',
  styleUrls: ['./internet-shops.component.scss']
})
export class InternetShopsComponent implements OnInit {
  /**
   * Торговая сеть
   *
   * @type {TradingNetwork}
   */
  tradingNetwork: TradingNetwork;
  /**
   * Магазины
   *
   * @type {TradingInternetShop[]}
   */
  shops: TradingInternetShop[];
  /**
   * id трговой сети
   *
   * @type {string}
   */
  tradingNetworkId: string;
  /**
   * Признак процесса загрузки данных
   *
   * @type {boolean}
   */
  loading: boolean;
  /**
   * Сортировка
   *
   * @type {any}
   */
  sort: TradingNetworksSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tradingNetworkService: TradingNetworkService,
    private modalService: NgbModal
  ) {}

  /**
   * Получение списка неудалённых ИМ (с параметром active: true)
   */
  get activeShops(): TradingInternetShop[] {
    return this.shops?.filter(shop => shop.active);
  }

  /**
   * Хук инициализации
   */
  ngOnInit() {
    this.sort = new TradingNetworksSort();
    this.sort.sortOrder = 'ASC';
    this.sort.sortType = 'name';
    this.tradingNetworkId = this.route.snapshot.paramMap.get('id');
    this.obtainData();
  }

  /**
   * Обработчик изменения сортировки
   */
  sortShops(): void {
    this.sort.sortOrder = this.sort.sortOrder === 'ASC' ? 'DESC' : 'ASC';
    this.obtainData();
  }

  /**
   * Обработчик нажатия на кнопку назад
   */
  goBack() {
    this.router.navigate(['admin/shops']);
  }

  /**
   * Получение лейбла для периода запросов
   *
   * @param value числовое значение
   * @returns лейбл для периода запросов
   */
  getPeriodTimesLabel(value: string) {
    return periodItems.find(period => period.value === value)?.label || '';
  }
  /**
   * Удаление магазина
   *
   * @param shop магазин
   */
  delete(shop: TradingInternetShop) {
    const ref = this.modalService.open(DeleteShopModalComponent);
    ref.componentInstance.shop = shop;
    ref.componentInstance.isAdmin = true;
    ref.componentInstance.tradingNetworkId = this.tradingNetworkId;
    ref.componentInstance.saved.subscribe(val => {
      if (!val) {
        return;
      }
      this.obtainData();
    });
  }

  /**
   * Добавление / редактирование ИМ
   *
   * @param shop данные интернет магазина
   */
  createEditShop(shop?: TradingInternetShop) {
    const ref = this.modalService.open(CreateEditWidgetModalComponent, {
      windowClass: 'create-internet-shop'
    });
    ref.componentInstance.isAdmin = true;
    ref.componentInstance.tradingNetworkId = this.tradingNetworkId;
    if (shop) {
      ref.componentInstance.shopData = shop;
      ref.componentInstance.isEdit = true;
    }
    ref.componentInstance.updated.subscribe(val => {
      if (!val) {
        return;
      }
      this.obtainData();
    });
  }
  /**
   * Открытие настроек стилей виджета
   *
   * @param shopId id магазина
   */
  redirectToSettings(shopId: string) {
    this.router.navigate(['admin/widget-settings', shopId]);
  }
  /**
   * Получение названия класса для статуса подключения виджета
   *
   * @param config Данные виджета
   * @returns название класса для статуса подключения виджета
   */
  getStatusClassname(config: TradingInternetShop): string {
    if (config.isWidgetConnected && config.isUrlConnected) {
      return 'active';
    }
    return 'non-active';

  }

  /**
   * Получение человекочитаемого статуса подключения виджета
   *
   * @param config  Данные виджета
   * @returns человекочитаемый статус подключения виджета
   */
  getStatusLabel(config: TradingInternetShop): string {
    if (config.isWidgetConnected && config.isUrlConnected) {
      return 'Подключение активно';
    }
    let statusLabel = 'Подключение неактивно.';
    if (!config.isUrlConnected) {
      statusLabel += ' ИМ не отвечает';
    } else if (!config.isWidgetConnected) {
      statusLabel += ' Ошибка скрипта';
    }
    return statusLabel;

  }

  /**
   * Получение названия класса для статуса доступности URL для чеков
   *
   * @param config Данные виджета
   * @returns название класса для статуса доступности URL для чеков
   */
  getUrlClassname(config: TradingInternetShop): string {
    if (!config.chequeUrl) {
      return 'not-added';
    }
    return config.isChequeUrlConnected ? 'active' : 'non-active';

  }
  /**
   * Получение человекочитаемого статуса доступности URL для чеков
   *
   * @param config Данные виджета
   * @returns человекочитаемый статус доступности URL для чеков
   */
  getUrlLabel(config: TradingInternetShop): string {
    if (!config.chequeUrl) {
      return 'Не добавлен';
    }
    return config.isChequeUrlConnected ? 'Отвечает' : 'Не отвечает';

  }
  /**
   * Загрузка данных
   */
  private obtainData() {
    this.loading = true;
    forkJoin([
      this.tradingNetworkService.getTradingInternetShopById(
        this.tradingNetworkId,
        this.sort
      ),
      this.tradingNetworkService.getTradingNetworkById(this.tradingNetworkId)
    ]).subscribe(([res, res1]) => {
      this.shops = res;
      this.tradingNetwork = res1;
      this.loading = false;
    });
  }
}
